import React from 'react'
import { Link } from 'react-router-dom'
import CsLineIcons from '../cs-line-icons/CsLineIcons'

function CardState({title,number,icon,link=""}) {
    return (
        <Link to={link}>
            <div  className="p-3 text-center bg-white dark:bg-gray-700 dark:bg-opacity-30 rounded-md shadow-md ">
               
                <div className='rounded-full flex justify-center py-3 items-center'>
                    <CsLineIcons className='border-secondary border text-secondary bg-opacity-75 p-2 rounded-full' icon={icon} size={50}/>
                    
                </div>
                <div className="text-xl capitalize ">
                    {title} 
                </div>
                <div className="text-sm text-gray-600">
                    {number} {title}
                </div>
               
               
            </div>
        
        </Link>
     
    )
}

export default CardState
