import { useState ,useEffect} from 'react'
import axios from 'axios'
import config from '../config/config'
import crypto from 'crypto'

import fxp from "fast-xml-parser";


const sha1 =  (data) => {
    return  crypto.createHash("sha1").update(data, "binary").digest("hex");
}

const encrypt = (method,paramObj ) => {
    let queryStr=""
    if(paramObj){
        Object.keys(paramObj).forEach((key)=>{
            if(queryStr === ""){
                queryStr = `?${key}=${paramObj[key]}`;
            }else{
                queryStr += `&${key}=${paramObj[key]}`;
            }
        });
    }
    let url = config.serverUrl +  "api/" + method + queryStr;


    let encryptData =  url.split(config.app)[1].replace("?","")+config.secret;
 
    const checkum = sha1(encryptData)
    let newUrl;
    if(url.indexOf("?") > 0)
       newUrl= url + `&checksum=${checkum}`;
    else
        newUrl= url + `?checksum=${checkum}`;
    return newUrl
}


function LiveRoom() {

    // const url = encrypt('getMeetings')

    const [meetParams, setMeetParams] = useState({
        "name":"",
        "meetingID":"",
        "attendeePW":"",
        "moderatorPW":"",
        "muteOnStart":false
    })

    const createMeet = (e) => {
        e.preventDefault()
        let params ={
            "meetingID":meetParams.meetingID,
            "name":meetParams.name,
            "meetingLayout":0,
            "attendeePW":meetParams.attendeePW,
            "moderatorPW":meetParams.moderatorPW,
            "muteOnStart":meetParams.muteOnStart,
            "logoutURL":"http://localhost:3000/meet",
            "lockSettingsDisablePublicChat":true,
            "lockSettingsDisableNote":true
        } 
        console.log(params)

        // let params = {
        //     "meetingID":"Meet2",
        //     "name":"Meet2",
        //     "attendeePW":"etudiant",
        //     "moderatorPW":"prof",
        //     "welcome":"Aynnaka",
        //     "dialNumber":"0671300616",
        //     "maxParticipants":2,
        //     "logoutURL":"http://localhost:3000/meet",
        //     "muteOnStart":true,
        //     "lockSettingsDisablePublicChat":true,
        //     "lockSettingsDisableNote":true

        // }
        const url = encrypt('create',params)
        
        console.log(url)

        axios.get(url).then(res => {
            console.log(res.data)
           
        }).catch(error=>{
            console.log("MEET CREATION ERROR ",error)
        })
    }


    const endMeeting = (meet) =>  (event) =>{
     
        let params = {
            "meetingID":meet.meetingID,
            "password":"prof"
        }
        const url = encrypt('end',params)
        console.log("END MEETING")
        console.log(url)
        axios.get(url).then(res=> {
            console.log("Meeting end ")
        }).catch(err=>{
            console.log(err)
        })

        

    }

    const onJoinMeetStudent = (meet) => (event) => {
     
        let params = {
            "meetingID":meet.meetingID,
            "fullName":"Etudiant",
            "password":"etudiant"
        }
        const url = encrypt('join',params)
        axios.get(url).then(res=> {
            console.log(res)
            if (res.status === 200){
                let _url = res.config.url
                console.log(_url)
                window.open(url, '_blank')
            }else{
                alert("TRY later ")
            }
            
        }).catch(err=> {
            console.log(err)
        })
    }

    const onJoinMeetProf = (meet) => (event) => {

        console.log(meet.meetingID)
        let params = {
            "meetingID":meet.meetingID,
            "fullName":"simou007",
            "password":"prof"

        }
        const url = encrypt('join',params)
        // axios.get(url).then(res=> {
        //     console.log(res)
        //     if (res.status === 200){
        //         let _url = res.config.url
        //         console.log(_url)
        //         window.open(url, '_blank')
        //     }else{
        //         alert("TRY later ")
        //     }
            
        // }).catch(err=> {
        //     console.log("ERROR IN MEET JOIN PROF")
        //     console.log(err)
        // })

         window.open(url, '_blank');
        
        // axios.get(url).then(res=>{
        //     console.log(res.data)
        // }).catch(error=>{
        //     console.log(error)
        // })
        
    }

    // get meeting info
    useEffect(() => {
        // getMeetingInfo
        let url =  encrypt('getMeetingInfo',{meetingID:"meet-1"});
        axios.get(url).then(res=>{
            console.log("Info",res.data)
        }).catch(err=>{
            console.log(err)
        })
    }, [])
   
  
    const [meetings, setMeetings] = useState([])

    useEffect(() => {
        let url =  encrypt('getMeetings');
        axios.get(url).then(res=> {
            let  meetingInfos  =  fxp.parse(res.data);
            console.log(meetingInfos)
            if(meetingInfos.response.returncode !== "SUCCESS" || meetingInfos.response.messageKey === 'noMeetings'){
                console.log("No Meeting yet")
                setMeetings([])
            }
       
            let meetings =  meetingInfos.response.meetings.meeting;
            if( meetings !== undefined && !Array.isArray(meetings)){
                console.log("Meeting up",meetings)
                meetings = [meetings];
                setMeetings(meetings)
            }
            setMeetings(meetings)
        }).catch(err=>{
          console.log(err)
        })
     
    }, [])

    



    return (
        <div>
            <h1>All Meetings infos </h1>
            <div className="">
                {meetings && meetings.length > 0 ? meetings.map(meet => 
                    <div className="" key={meet.internalMeetingID}>
                        <div className="p-3  flex  items-center justify-between bg-secondary bg-opacity-10 " >
                           <div className="flex-grow ">
                                <div>
                                    {meet.meetingName} - {meet.meetingID} 
                                </div>
                                <div>
                                   {meet.running ?
                                    <span className="text-green-600" >  is Running </span>   :
                                    <span className="text-red-500 " >  Not Running </span>
                                    }
                                </div>
                                
                               
                           </div>
                           <div className="flex-none">
                                <button className="p-2 mx-1 bg-green-200 rounded-md" onClick={onJoinMeetProf(meet)}>
                                    JOIN AS PROF
                                </button>
                                <button className="p-2 mx-1 bg-green-200 rounded-md" onClick={onJoinMeetStudent(meet)}>
                                    JOIN AS STUDENT
                                </button>
                                <button className="p-2 mx-1 bg-green-200 rounded-md" onClick={endMeeting(meet)}>
                                    END MEET
                                </button>
                           </div>

                           <div>

                           </div>
                            
                            
                        </div>  
                        <div>
                            <div>
                                    Number of Student :  
                                        <span>
                                            {meet.participantCount}
                                        </span>
                                </div>
                                <div>
                                    { meet.attendees.attendee && meet.attendees.attendee.length >0 && meet.attendees.attendee.map(att => 
                                        <div>
                                            {att.fullName} - 
                                            {att.role}
                                        </div>
                                        )}
                                </div>
                        </div>         
                    </div>
                    ):
                    <div className="p-3">
                       <h1 className="text-2xl ">
                        No Meeting yet
                       </h1>
                    </div>
                }
                
                
            </div>

            <h2>
                Creation of New Meet
            </h2>

            <div className="w-1/3">
                <form action="post">
                    <div className="mt-2 bg-white w-full border ">
                        <input value={meetParams.name} onChange={(e) => setMeetParams({...meetParams,name:e.target.value})}  className="bg-transparent outline-none p-2" type="text" placeholder="Meet Name"  />
                    </div>
                    <div className="mt-2 bg-white w-full border ">
                        <input value={meetParams.meetingID} onChange={(e) => setMeetParams({...meetParams,meetingID:e.target.value})}  className="bg-transparent outline-none p-2" type="text" placeholder="Meet ID"  />
                    </div>
                    <div className="mt-2 bg-white w-full border ">
                        <input  value={meetParams.attendeePW} onChange={(e) => setMeetParams({...meetParams,attendeePW:e.target.value})}  className="bg-transparent outline-none p-2" type="text" placeholder="Student Password"  />
                    </div>
                    <div className="mt-2 bg-white w-full border ">
                        <input value={meetParams.moderatorPW} onChange={(e) => setMeetParams({...meetParams,moderatorPW:e.target.value})} className="bg-transparent outline-none p-2" type="text" placeholder="Prof Password" name="meetingID"  />
                    </div>
                    <div className="mt-2 w-full  ">

                        <div className="" onClick={()=>setMeetParams({...meetParams,muteOnStart:!meetParams.muteOnStart})}>
                            {
                                meetParams.muteOnStart ? 
                                <div className="py-2 bg-red-200">Mute on start</div>:
                                <div className="py-2 bg-green-300">Sound on start</div>

                            }
                            
                        </div>
                        {/* <input type="radio" onChange={(e) => setMeetParams({...meetParams,muteOnStart:!meetParams.muteOnStart})} className="m-2" value={meetParams.muteOnStart} checked={meetParams.muteOnStart===true}    />Mute On
                        <input type="radio" onChange={(e) => setMeetParams({...meetParams,muteOnStart:meetParams.muteOnStart})} className="m-2" value={meetParams.muteOnStart} checked={meetParams.muteOnStart===true}      /> Mute Off */}

                    </div>
                   

                    <button className="p-2 mt-5 w-32 bg-primary text-white" onClick={createMeet}>Create MEET</button>
                </form>

                
            </div>
        </div>
    )
}

export default LiveRoom
