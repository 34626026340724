import React from 'react'

function Loading() {
    return (
        <div>
            Loading ...
        </div>
    )
}

export default Loading

