import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { resetPasswordConfirm } from '../../redux/actions/authActions';

function ResetPasswordConfirmation() {
  const {token} = useParams()
  const [password, setPassword] = useState("");
  const dispatch = useDispatch()

  const {loading,data,error} = useSelector(state => state.resetPasswordConfirm)

  const confirmPassword = (e)=> {
    e.preventDefault()
    if (token !== ""){
        dispatch(resetPasswordConfirm(token,password))
    }
      // Axios.post('auth/password_reset/confirm/',{token,password}).then(res=> {
      //   console.log(res.data);
      //   if (res.data.status==="OK") {
          
      //     navigate('/login')
      //   }
        
          
      // }).catch(err=>{
      //   console.log(err);
      // })
  }
  return <div className="min-h-screen  flex items-center justify-center bg-primary p-4 md:p-16">
        <div className="p-3 w-96 bg-white rounded-md ">
          <h1 className='text-xl'>
            Reset Password Confirmation
          </h1>
          {loading ? 
                <div>
                    loading ...
                </div>:
                error ? 
                <div>
                    <div className="p-2 bg-red-400 rounded-md">
                        {error}
                    </div>
                </div>
                : 
                ""
            }
                    
          <div className='p-4 '>
            <form onSubmit={confirmPassword}>
              <div className='border bg-white rounded-md '>
                <input className='p-2 outline-none w-full' value={password} onChange={(e)=> setPassword(e.target.value)} type="password" placeholder='New Password' />
              </div>
              <div className='py-2'>
                <button className='p-2 text-white bg-primary rounded-md' type='submit'>Confirm Password</button>
              </div>
            </form>
          </div>
      </div>
  </div>;
}

export default ResetPasswordConfirmation;
