
import { toast } from "react-toastify"
import Axios from "../Axios"
import { ActionTypes } from "../constants/action-types"
import { FAIL_DELETE_FILE, FAIL_LIST_COURSE_FILE, REQUEST_DELETE_FILE, REQUEST_LIST_COURSE_FILE, SUCCESS_DELETE_FILE, SUCCESS_LIST_COURSE_FILE } from "../constants/fileConstants"

// export const createFile  = (event) => async (dispatch) =>{
//     dispatch({type:REQUEST_CREATE_EVENT,payload:[]})
//     try {
//         const {data} = await  Axios.post(`/event/`,event)
//         console.log(data)
//         dispatch({type:SUCCESS_CREATE_EVENT,payload:data})
//     } catch (error) {
//         dispatch({
//             type:FAIL_CREATE_EVENT,
//             payload:error.response && error.response.data.message
//                 ? error.response.data.message
//                 : error.message
//         })
//     }
// }


export const deleteFile  = (fileId) => async (dispatch,getState) =>{ 
    try {
         dispatch({type:REQUEST_DELETE_FILE,payload:[]})
        const {data} = await  Axios.delete(`/file/${fileId}/delete`)
        dispatch({type:SUCCESS_DELETE_FILE,payload:data})
        const {courseData} = getState()
        courseData.files=courseData.data.files.filter((file)=>file.id!==fileId)
        dispatch({type:ActionTypes.SUCCESS_DETAIL_CAT_COURSE,payload:courseData})

        toast.success("File Has been deleted")
    } catch (error) {
        dispatch({
            type:FAIL_DELETE_FILE,
            payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
        toast.warn("Error !")

    }
}

export const listFilesByCourse = (idCourse) => async (dispatch) => {
    dispatch({type:REQUEST_LIST_COURSE_FILE,payload:[]})    
    try {
        const {data} =await  Axios.get(`/course/${idCourse}/files`)
        dispatch({type:SUCCESS_LIST_COURSE_FILE,payload:data.data.files})
        
    } catch (error) {
        dispatch({
            type:FAIL_LIST_COURSE_FILE,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        })
    }
}
