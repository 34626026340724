import React from 'react'
import CsLineIcons from '../cs-line-icons/CsLineIcons'
import { usePlanActivated } from '../hooks/isPlanActivated'
import Role from './Role'

function Add({setModalOpen,modalOpen}) {
    const isPlanActive = usePlanActivated()
  return (
    <Role type={'teacher'}> 
        <div className="p-3 fixed z-10 bottom-10 " >
            <button onClick={()=> setModalOpen(!modalOpen)} className={`p-2 rounded-full bg-primary ${isPlanActive ? '':'bg-opacity-25'} text-white`} disabled={!isPlanActive}>
                <CsLineIcons className='text-secondary' icon="plus" size={35} />    
            </button> 
        </div>
    </Role>
  )
}

export default Add
