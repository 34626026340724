import { ActionTypes } from "../constants/action-types";
import { FAIL_CREATE_EVENT, REQUEST_CREATE_EVENT, SUCCESS_CREATE_EVENT ,
    FAIL_DELETE_EVENT, REQUEST_DELETE_EVENT, SUCCESS_DELETE_EVENT, REQUEST_LIST_COURSE_EVENT, SUCCESS_LIST_COURSE_EVENT, FAIL_LIST_COURSE_EVENT 
} from "../constants/eventConstants";

const initialState = {
    loading: false,
    event: [],
    error:""
}
  
export const listEventReducer = (state=initialState,{type,payload}) => {
    switch (type) {
        case  ActionTypes.REQUEST_LIST_EVENT:
            return {
                loading:true,
                event:[]
            };
        case  ActionTypes.SUCCESS_LIST_EVENT:
            return {
                loading:false,
                event:payload
            };

        case ActionTypes.FAIL_LIST_EVENT:
            return {
                loading:false,
                error:payload
            };
        default:
            return state;
    } 
}

export const listCourseEventReducer = (state={},{type,payload}) => {
    switch (type) {
        case  REQUEST_LIST_COURSE_EVENT:
            return {
                loading:true,
            };
        case  SUCCESS_LIST_COURSE_EVENT:
            return {
                loading:false,
                events:payload
            };

        case FAIL_LIST_COURSE_EVENT:
            return {
                loading:false,
                error:payload
            };
        default:
            return state;
    } 
}


export const createEventReducer = (state={
        loading: false,
        event:{} ,
        error:""
    },{type,payload}) => {
    switch (type) {
        case  REQUEST_CREATE_EVENT:
            return {
                loading:true,
                event:[]
            };
        case  SUCCESS_CREATE_EVENT:
            return {
                loading:false,
                event:payload
            };

        case FAIL_CREATE_EVENT:
            return {
                loading:false,
                error:payload
            };
        default:
            return state;
    } 
}


export const deleteEventReducer = (state={loading: false,sucess:false ,error:""},{type,payload}) => {
    switch (type) {
        case  REQUEST_DELETE_EVENT:
            return {
                ...state,
                loading:true,
            };
        case  SUCCESS_DELETE_EVENT:
            return {
                ...state,
                loading:false,
                sucess:payload
            };

        case FAIL_DELETE_EVENT:
            return {
                ...state,
                loading:false,
                error:payload
            };
        default:
            return state;
    } 
}

