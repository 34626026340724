import { FAIL_CREATE_ANNOUNCE, FAIL_DELETE_ANNOUNCE, FAIL_LIST_COURSE_ANNOUNCE, REQUEST_CREATE_ANNOUNCE, REQUEST_DELETE_ANNOUNCE, REQUEST_LIST_COURSE_ANNOUNCE, SUCCESS_CREATE_ANNOUNCE, SUCCESS_DELETE_ANNOUNCE, SUCCESS_LIST_COURSE_ANNOUNCE } from "../constants/announceConstants";

export const createAnnounceReducer = (state={
        loading: false,
        success:false ,
        data:{},
        error:""
    },{type,payload}) => {
switch (type) {
    case  REQUEST_CREATE_ANNOUNCE:
        return {
            loading:true,
            success:false
        };
    case  SUCCESS_CREATE_ANNOUNCE:
        return {
            loading:false,
            data:payload,
            success:true
        };

    case FAIL_CREATE_ANNOUNCE:
        return {
            loading:false,
            error:payload,
            success:false
        };
    default:
        return state;
} 
}


export const deleteAnnounceReducer = (state={loading: false,success:false ,error:""},{type,payload}) => {
    switch (type) {
    case  REQUEST_DELETE_ANNOUNCE:
        return {
            ...state,
            loading:true,
        };
    case  SUCCESS_DELETE_ANNOUNCE:
        return {
            ...state,
            loading:false,
            success:true
        };

    case FAIL_DELETE_ANNOUNCE:
        return {
            ...state,
            success:false,
            loading:false,
            error:payload
        };
    default:
        return state;
} 
}

export const listCourseAnnounceReducer = (state={},{type,payload}) => {
    switch (type) {
        case  REQUEST_LIST_COURSE_ANNOUNCE:
            return {
                loading:true,
            };
        case  SUCCESS_LIST_COURSE_ANNOUNCE:
            return {
                loading:false,
                announces:payload
            };

        case FAIL_LIST_COURSE_ANNOUNCE:
            return {
                loading:false,
                error:payload
            };
        default:
            return state;
    } 
}
