import {ActionsTypesAuth} from "../constants/authConstants";

// const initialState = {
//     loading:false,
//     userInfo:{},
//     error:null,
//   }
const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const initialState = {
    loading:false,
    user: userInfoFromStorage,
    isLoggedIn:userInfoFromStorage!==null ? true:false
}

export const statusServerReducer = (state={},{type,payload}) => {
    switch (type) {
        case ActionsTypesAuth.REQUEST_SERVER_CONNECTION:
            return {
                loading:true
            }
        case ActionsTypesAuth.SUCCESS_SERVER_CONNECTION:
            return {
                loading:false,
                status:payload
            }
        case ActionsTypesAuth.FAIL_SERVER_CONNECTION:
            return {
                loading:false,
                error:payload
            }
    
        default:
            return state;
    }
}

export const loginReducer = (state=initialState,{type,payload}) => {
    switch (type) {
        case  ActionsTypesAuth.REQUEST_LOGIN_USER:
            return {
                loading:true,
            };

        case  ActionsTypesAuth.SUCCESS_LOGIN_USER:
            return {
                user:payload,
            };
        
        case ActionsTypesAuth.FAIL_LOGIN_USER:
            return { loading: false, error: payload }

        case ActionsTypesAuth.LOGOUT:
            return {};
        default:
            return state;
    } 
}


export const registerReducer = (state={
        user:false,
        error:null,
        loading:false
    },{type,payload}) => {
    switch (type) {
        case  ActionsTypesAuth.REQUEST_REGISTER_USER:
            return {
                loading:true,
            };
        case  ActionsTypesAuth.SUCCESS_REGISTER_USER:
            return {
                loading:false,
                user:payload,
            };

        case ActionsTypesAuth.FAIL_REGISTER_USER:
            return {
                loading:false,
                error:payload
            };
        
        default:
            return state;
    } 
}


export const getUserReducer = (state={},{type,payload}) =>{
    switch (type) {
        case ActionsTypesAuth.REQUEST_GET_USER:
            return {
                loading:true
            }
        
        case ActionsTypesAuth.SUCCESS_GET_USER:
            return {
                loading:false,
                user:payload,
            }
            
        case ActionsTypesAuth.FAIL_GET_USER:
            return {
                loading:false,
                error:payload
            }
        default:
            return state;
    }
}

export const updateUserReducer = (state={},{type,payload}) =>{
    switch (type) {
        case ActionsTypesAuth.REQUEST_UPDATE_USER:
            return {
                loading:true
            }
        
        case ActionsTypesAuth.SUCCESS_UPDATE_USER:
            return {
                loading:false,
                user:payload,
            }
            
        case ActionsTypesAuth.FAIL_UPDATE_USER:
            return {
                loading:false,
                error:payload
            }
        default:
            return state;
    }
}

export const disableUserReducer = (state={},{type,payload}) =>{
    switch (type) {
        case ActionsTypesAuth.REQUEST_DISABLE_USER:
            return {
                loading:true
            }
        
        case ActionsTypesAuth.SUCCESS_DISABLE_USER:
            return {
                loading:false,
                user:payload
            }
            
        case ActionsTypesAuth.FAIL_DISABLE_USER:
            return {
                loading:false,
                error:payload
            }
        default:
            return state;
    }
}


export const resetPasswordConfirmReducer = (state={},{type,payload}) =>{
    switch (type) {
        case ActionsTypesAuth.REQUEST_RESET_PASSWORD_CONFIRM:
            return {
                loading:true,
                error:"",
                user:{}
            }
        
        case ActionsTypesAuth.SUCCESS_RESET_PASSWORD_CONFIRM:
            return {
                loading:false,
                user:payload,
            }
            
        case ActionsTypesAuth.FAIL_RESET_PASSWORD_CONFIRM:
            return {
                
                loading:false,
                error:payload
            }
        default:
            return state;
    }
}

export const resetPasswordReducer = (state={},{type,payload}) =>{
    switch (type) {
        case ActionsTypesAuth.REQUEST_RESET_PASSWORD:
            return {
                loading:true,
                error:"",
                user:{}
            }
        
        case ActionsTypesAuth.SUCCESS_RESET_PASSWORD:
            return {
                loading:false,
                user:payload
            }
            
        case ActionsTypesAuth.FAIL_RESET_PASSWORD:
            return {
                loading:false,
                error:payload
            }
        default:
            return state;
    }
}

export const updatePasswordReducer = (state={},{type,payload}) =>{
    switch (type) {
        case ActionsTypesAuth.REQUEST_UPDATE_PASSWORD:
            return {
                loading:true
            }
        
        case ActionsTypesAuth.SUCCESS_UPDATE_PASSWORD:
            return {
                loading:false,
                user:payload
            }
            
        case ActionsTypesAuth.FAIL_UPDATE_PASSWORD:
            return {
                loading:false,
                error:payload
            }
        default:
            return state;
    }
}
