import axios from "axios";

const defaultOptions = {
  baseURL:'/api/v1/',
  headers: {
    'Content-Type': 'application/json',
  },
};

const instance = axios.create(defaultOptions);

instance.interceptors.request.use(function (config) {
  const token = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).token:"";
  config.headers.Authorization =  token ? `Token ${token}` : '';
  return config;
});


export default instance;