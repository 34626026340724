export const validate = (values) => {
    let errors = {}
    if (!values.title){
        errors["title"]="Event title is required "
    }
    if (!values.subtitle){
        errors["subtitle"]="Event subtitle is required "
    }

    // if (new Date(values.date_event).toString() === 'Invalid Date'){
      
    //     errors["date_event"]="Invalid Date"
    // }
    // if (!values.heure_event){
    //      errors["heure_event"]="heur must be selected "
    // }
   
    return errors;

}