import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import CsLineIcons from '../cs-line-icons/CsLineIcons';

function BillingDashboard() {
  return <>
      
        <div className="min-h-screen">
            <div className="h-16">
                <Header background={'bg-primary text-white'} />
            </div>
            <div className="max-w-screen-xl mx-auto p-4 ">
                <Link to={'/'}>
                    <div className='pb-4 flex items-center'>
                        <CsLineIcons icon='arrow-left'/>
                        <div className='px-3'> back home </div>
                    </div>
                </Link>
                <div>
                    <h1 className='text-md font-meduim'>
                        Billing dashboard 
                    </h1>
                </div>
               

                <div className='py-4 md:flex '>

                    <div className='md:w-2/3 p-2'>

                        <div className='bg-white rounded-md p-3'>
                            <h1 className='text-xl'>
                                <b>Payment Method  </b>
                            </h1>
                            
                            <div className='my-3  md:flex'>
                                <div className='md:w-1/2 flex items-center justify-between border py-1 rounded-md px-2 '>
                                    <div>
                                        <div>
                                            Mohamed Boussakssou
                                        </div>
                                        <div>
                                            **** **** **** 7852
                                        </div>
                                    </div>
                                    
                                    <div className='text-primary'>
                                        Edit
                                    </div>
                                </div>
                                
                                
                            </div>

                            

                            <div className=''>
                               <button className='p-2 border rounded-md border-primary text-primary'>
                                    Add new Payment method
                               </button>
                            </div>


                        </div>
                        
                        
                        <div className='bg-white mt-2 rounded-md p-3'>
                            <h1 className='text-xl'>
                                <b>Payments </b>
                            </h1>
                            <div className='pt-4'>
                            <table class="table-auto w-full">
                                <thead>
                                    <tr className='text-left'>
                                        <th>Bill No</th>
                                        <th>Date </th>
                                        <th>Cost</th>
                                        <th>No of Students</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='bg-gray-50 '>
                                        <td className='p-2'>#EC23101</td>
                                        <td>12-12-2022</td>
                                        <td>500</td>
                                        <td>10</td>
                                    </tr>
                                    <tr className=' '>
                                        <td className='p-2'>#EC23101</td>
                                        <td>12-12-2022</td>
                                        <td>500</td>
                                        <td>10</td>
                                    </tr>
                                    <tr className='bg-gray-50 '>
                                        <td className='p-2'>#EC23101</td>
                                        <td>12-12-2022</td>
                                        <td>500</td>
                                        <td>10</td>
                                    </tr>
                                   
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                    <div className='md:w-1/3 p-2'>
                        <div className=' bg-white rounded-md p-3'>
                            <div className='flex justify-between items-center'>
                                <h1 className='text-xl'>
                                    <b>Invoices </b>
                                </h1>
                                <button className='p-1 border rounded-md'>
                                    View all
                                </button>
                            </div>
                            <div className='py-1'>
                                <div className='p-2 flex justify-between items-center '>
                                <div>
                                    <div>March, 01, 2022</div>
                                    <div className='text-xs text-gray-600'>#MS-415646</div>
                                </div>
                                <div className='flex '>
                                    <div className='px-3'>
                                        500 dh
                                    </div>
                                    <div className='px-3 text-secondary'>
                                        Pdf
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className='py-1'>
                                <div className='p-2 flex justify-between items-center '>
                                <div>
                                    <div>March, 02, 2022</div>
                                    <div className='text-xs text-gray-600'>#MS-415646</div>
                                </div>
                                <div className='flex '>
                                    <div className='px-3'>
                                        500 dh
                                    </div>
                                    <div className='px-3 text-secondary'>
                                        Pdf
                                    </div>
                                </div>
                                </div>
                            </div>

                        </div>
                    </div>
                   
                </div>
            
            </div>
        </div>      
  </>;
}

export default BillingDashboard;
