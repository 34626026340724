import React from 'react'
import { useSelector } from 'react-redux'
import Loading from './Loading'

function Role({type,children}) {

    const  {user,loading,error} = useSelector(state => state.auth)

    return (
            
                loading? 
                <Loading /> :
                error ?
                    <div> {error}</div>
                : user && user.type===type &&
                        <div>
                                {children}
                        </div>
                        
    
    )
}

export default Role
