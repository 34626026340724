import React from 'react'
import { Link } from 'react-router-dom'

const SideBarProfile = ({avatar,firstname,lastname}) => {
    return (
        <div className='p-3 bg-white dark:bg-gray-600 dark:text-gray-50 rounded-md'>
                           
          
            <img src={avatar} className='mx-auto w-20 h-20 bg-gray-100 rounded-full' alt="" />   
            
            <div className='py-3 text-center'>
                <div>
                    <h1 className='text-xl'>{firstname}  {lastname} </h1>
                </div>
                
                <div className='pt-3'>
                    <Link to={''} className='text-gray-700 hover:text-primary'>Edit infos</Link>
                </div>
                <div className='pt-3'>
                    <Link to={'change-password'} className='text-gray-700 hover:text-primary'>Change password</Link>
                </div>
                <div className='pt-3'>
                    <Link to={'delete-account'} className='text-gray-700 hover:text-primary'>Delete account</Link>
                </div>

            </div>
            
        </div>
    )
}


export default SideBarProfile
