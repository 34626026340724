import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link} from 'react-router-dom'
import Header from '../../components/Header'
import useForm from '../../hooks/useForm'

import { register } from '../../redux/actions/authActions'
import validate from '../../validation/RegisterFormValidationRules'

function RegisterStudent() {
    const dispatch = useDispatch()
    const {
        loading,
        user,
        error   
    } = useSelector(state => state.register)

    const handleRegister = () => {
        values.type="student"
        dispatch(register(values)) 
    }

    const  {
        handleChange,
        handleSubmit,
        errors,
        values,
    } = useForm(handleRegister,validate)

    const { t } = useTranslation();

    return (
        <>
            <Header />
            <div style={{backgroundColor:"#FFF0CE"}} className=" min-h-screen flex justify-center items-center  p-4 md:p-16">
                <div className="p-3 md:flex items-center justify-center">
                    <div className='md:flex-wrap  w-1/2 hidden md:block mx-auto p-8'>
                        <h1 className='text-3xl leading-normal font-medium'>
                            Ayskill For Students
                        </h1>
                        
                        <p className='leading-normal text-xl pt-3'>
                            In a fun and light way, you can teach distance learning with the best and easiest tools, an experience that has proven to improve your academic performance.
                        </p>
                    

                    </div>
                
                    <div  className="flex-none mx-auto max-w-md shadow-md bg-white  rounded-md p-8  ">
                        <div className='text-xl font-bold pb-2'>
                            Sign Up 
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="py-2">
                                <div className={`border ${errors.username ?"border-red-500" :""} border-gray-300 rounded-full`}>
                                    <input  value={values.username|| ''} onChange={handleChange} type="text" name="username" id="username" placeholder={t("username")} className="p-3 outline-none  w-full bg-transparent " />
                                </div>
                                <div className='text-red-600'>
                                    {error && error.username && error.username[0]}
                                    {errors.username}
                                </div>
                                
                            </div>
                            <div className="py-2">
                                {/* <label className='required' htmlFor="email">{t("email")}</label> */}
                                <div className={`border ${errors.email ? 'border-red-500':''} border-gray-300 rounded-full`}>
                                    <input id="email" value={values.email || ''} onChange={handleChange} type="text" name="email"  placeholder={t("email")} className="p-3 outline-none  w-full bg-transparent " />
                                </div>
                                <div className='text-red-600'>
                                    {error && error.email &&  error.email[0]}
                                    {errors.email}
                                </div>
                            </div>
                            <div className='grid grid-cols-2 gap-2'>
                                <div>
                                    {/* <label className='required' htmlFor="username">{t("firstname")}</label> */}
                                    <div className={`border  ${errors.first_name ? 'border-red-500':''} border-gray-300 rounded-full`}>
                                        <input value={values.first_name || ''} onChange={handleChange} type="text" name="first_name" id="firstname" placeholder={t("firstname")} className="p-3 outline-none  w-full bg-transparent " />
                                    </div>
                                    <div className='text-red-600'>
                                        {error && error.first_name &&  error.first_name[0]}
                                        {errors.first_name}
                                    </div>
                                </div> 
                                <div>
                                    {/* <label className='required' htmlFor="username">{t("last_name")}</label> */}
                                    <div className={`border ${errors.last_name ? 'border-red-500':''}  border-gray-300 rounded-full`}>
                                        <input value={values.last_name || ''} onChange={handleChange} type="text" name="last_name" id="last_name" placeholder={t("last_name")} className="p-3 outline-none focus:outline-none  w-full bg-transparent " />
                                    </div>
                                    <div className='text-red-600'>
                                        {error && error.last_name &&  error.last_name[0]}
                                        {errors.last_name}
                                    </div>
                                </div>
  
                                
                            </div>
                            <div className="py-2" >
                                {/* <label className='required' htmlFor="password">{t("password")}</label> */}
                                <div className={`border ${errors.password ? 'border-red-500':''} border-gray-300 rounded-full`}>
                                    <input type="password" placeholder={t("password")} value={values.password|| ''} onChange={handleChange} name="password" id="password" className="p-3 outline-none  w-full bg-transparent " />
                                </div>
        
                                
                            </div>
                            <div className="pb-2" >
                                {/* <label className='required' htmlFor="password">{t("Confirm Password")}</label> */}
                                <div className={`border ${errors.password ? "border-red-600":""}  border-gray-300 rounded-full`}>
                                    <input type="password" value={values.password2|| ''} onChange={handleChange}  id="confirm-password" name='password2' placeholder={t("Confirm Password")}className="p-3 outline-none  w-full bg-transparent " />
                                </div>
                            </div>

                            <div className='text-red-600'>
                                {error && error.password && error.password[0]}
                                {errors.password}
                            </div>
    
                            <div className="py-2 flex justify-center">
                                <button  className={`p-2 bg-primary rounded-full px-8 text-white ${loading ? "opacity-40":""} `} disabled={loading}>
                                {loading ? 'loading...' :t('register') }
                                </button>
                            </div>
                        </form>
                        <div className="py-2 flex justify-center">
                            <Link className="text-blue-800" to="/login">{t('login')}</Link>, {t('if you have already an account')}  
                        </div>

                        <div className='pt-4 text-center'>
                            <div className='text-xs opacity-60'>
                                By signing up you agree our <span className='text-primary'>Terms of use</span> and <span className='text-primary' >privacy policy</span>

                            </div>
                        </div>
    
    
                    </div>
    
                    
                </div>
            </div>
        </>
    )
}

export default RegisterStudent
