import { toast } from "react-toastify"
import Axios from "../Axios"
import { ActionTypes } from "../constants/action-types"
import { FAIL_CREATE_EVENT, FAIL_DELETE_EVENT, FAIL_DETAIL_EVENT, FAIL_EDIT_EVENT,
     FAIL_LIST_COURSE_EVENT, FAIL_LIST_EVENT, REQUEST_CREATE_EVENT, REQUEST_DELETE_EVENT, 
     REQUEST_DETAIL_EVENT, REQUEST_EDIT_EVENT, REQUEST_LIST_COURSE_EVENT, REQUEST_LIST_EVENT, 
     SUCCESS_CREATE_EVENT, SUCCESS_DELETE_EVENT, SUCCESS_DETAIL_EVENT, SUCCESS_EDIT_EVENT, 
     SUCCESS_LIST_COURSE_EVENT, SUCCESS_LIST_EVENT } from "../constants/eventConstants"
import { detailCourseByCat } from "./courseActions"


export const listEvents = () => async (dispatch) => {
    dispatch({type:REQUEST_LIST_EVENT,payload:[]})
        
    try {
        const {data} =await  Axios.get('/event/')
        // console.log(data)
        dispatch({type:SUCCESS_LIST_EVENT,payload:data})
    } catch (error) {
        dispatch({type:FAIL_LIST_EVENT,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        })
    }
}

export const listEventsByCourse = (idCourse) => async (dispatch) => {
    dispatch({type:REQUEST_LIST_COURSE_EVENT,payload:[]})    
    try {
        const {data} =await  Axios.get(`/course/${idCourse}/events`)
        dispatch({type:SUCCESS_LIST_COURSE_EVENT,payload:data.data})
        
    } catch (error) {
        dispatch({
            type:FAIL_LIST_COURSE_EVENT,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        })
    }
}


export const detailEvent = (id) => async (dispatch) => {
    dispatch({type:REQUEST_DETAIL_EVENT,payload:[]})
        
    try {
        const {data} =await Axios.get(`/event/${id}`)
        console.log(data)
        dispatch({type:SUCCESS_DETAIL_EVENT,payload:data})
        
    } catch (error) {
        dispatch({type:FAIL_DETAIL_EVENT,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        })
    }
}

export const editEvent = (event,eventId) => async (dispatch) =>{
    try {
        dispatch({type:REQUEST_EDIT_EVENT,payload:{}})
        const {data} = await Axios.put(`event/${eventId}/`,event);
        console.log("event data ",data)
        dispatch({type:SUCCESS_EDIT_EVENT,payload:data})

        dispatch(detailCourseByCat(event.course,"events"))

        toast.success("Event has been Edited");


    } catch (error) {
        dispatch({
            type:FAIL_EDIT_EVENT,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })

        toast.warn("Error");

    }
}

export const createEvent  = (event) => async (dispatch) =>{
    
    try {
        dispatch({type:REQUEST_CREATE_EVENT,payload:[]})
        const {data} = await  Axios.post(`/event/`,event)
        dispatch({type:SUCCESS_CREATE_EVENT,payload:data})
        dispatch(detailCourseByCat(event.course,"events"))
        toast.success("Event has been Created");
    } catch (error) {
        dispatch({
            type:FAIL_CREATE_EVENT,
            payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
        toast.warn("Event Creation Error");

    }
}

export const deleteEvent  = (eventId) => async (dispatch,getState) =>{
   
    try {
         dispatch({type:REQUEST_DELETE_EVENT,payload:[]})
        const {data} = await  Axios.delete(`/event/${eventId}/delete`)
        dispatch({type:SUCCESS_DELETE_EVENT,payload:data})
        const {courseData} = getState()
        courseData["events"]=courseData.data.events.filter((ev)=>ev.id!==eventId)
        dispatch({type:ActionTypes.SUCCESS_DETAIL_CAT_COURSE,payload:courseData})
        toast.success("Event has been Deleted");

    } catch (error) {
        dispatch({
            type:FAIL_DELETE_EVENT,
            payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
        toast.warn("Error ! ");

    }
}