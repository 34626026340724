export const REQUEST_CREATE_FILE ="REQUEST_CREATE_FILE"
export const SUCCESS_CREATE_FILE ="SUCCESS_CREATE_FILE"
export const FAIL_CREATE_FILE ="FAIL_CREATE_FILE"

export const REQUEST_LIST_FILE ="REQUEST_LIST_FILE"
export const SUCCESS_LIST_FILE ="SUCCESS_LIST_FILE"
export const FAIL_LIST_FILE ="FAIL_LIST_FILE"

export const REQUEST_DELETE_FILE ="REQUEST_DELETE_FILE"
export const SUCCESS_DELETE_FILE ="SUCCESS_DELETE_FILE"
export const FAIL_DELETE_FILE ="FAIL_DELETE_FILE"

export const REQUEST_LIST_COURSE_FILE ="REQUEST_LIST_COURSE_FILE"
export const SUCCESS_LIST_COURSE_FILE ="SUCCESS_LIST_COURSE_FILE"
export const FAIL_LIST_COURSE_FILE ="FAIL_LIST_COURSE_FILE"
