import { FAIL_CREATE_FILE, FAIL_DELETE_FILE, FAIL_LIST_COURSE_FILE, REQUEST_CREATE_FILE, REQUEST_DELETE_FILE, REQUEST_LIST_COURSE_FILE, SUCCESS_CREATE_FILE, SUCCESS_DELETE_FILE, SUCCESS_LIST_COURSE_FILE } from "../constants/fileConstants";

export const deleteFileReducer = (state={loading: false,sucess:false ,error:""},{type,payload}) => {
    switch (type) {
        case  REQUEST_DELETE_FILE:
            return {
                ...state,
                loading:true,
            };
        case  SUCCESS_DELETE_FILE:
            return {
                ...state,
                loading:false,
                sucess:payload
            };

        case FAIL_DELETE_FILE:
            return {
                ...state,
                loading:false,
                error:payload
            };
        default:
            return state;
    } 
}


export const createFileReducer = (state={loading: false,sucess:false ,error:""},{type,payload}) => {
    switch (type) {
        case  REQUEST_CREATE_FILE:
            return {
                ...state,
                loading:true,
            };
        case  SUCCESS_CREATE_FILE:
            return {
                ...state,
                loading:false,
                sucess:payload
            };

        case FAIL_CREATE_FILE:
            return {
                ...state,
                loading:false,
                error:payload
            };
        default:
            return state;
    } 
}

export const listCourseFilesReducer = (state={},{type,payload}) => {
    switch (type) {
        case  REQUEST_LIST_COURSE_FILE:
            return {
                loading:true,
            };
        case  SUCCESS_LIST_COURSE_FILE:
            return {
                loading:false,
                files:payload
            };

        case FAIL_LIST_COURSE_FILE:
            return {
                loading:false,
                error:payload
            };
        default:
            return state;
    } 
}


