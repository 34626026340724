
export const ActionsTypesNotification = {
    
    REQUEST_NOTIFICATION_COURSE :"REQUEST_NOTIFICATION_COURSE",
    SUCCESS_NOTIFICATION_COURSE :"SUCCESS_NOTIFICATION_COURSE",
    FAIL_NOTIFICATION_COURSE :"FAIL_NOTIFICATION_COURSE",

    REQUEST_DELETE_NOTIFICATION :"REQUEST_DELETE_NOTIFICATION",
    SUCCESS_DELETE_NOTIFICATION :"SUCCESS_DELETE_NOTIFICATION",
    FAIL_DELETE_NOTIFICATION :"FAIL_DELETE_NOTIFICATION",
}
