import React, { useState } from 'react'
import Modal from '../components/Modal'
import ListEvents from '../components/ListEvents'
import AddEditEvent from '../components/AddEditEvent'
import Add from '../components/Add'


function CourseEvents() {
    
    const [modalOpen, setModalOpen] = useState(false)

    return (
        <>
        <div>
            <div  className=" ">
                <h1 className="text-2xl font-semibold">
                    Events
                </h1>
                <div className="px-4 py-2 ">
                    <ListEvents  />
                </div>
            
            </div>
            <Add modalOpen={modalOpen} setModalOpen={setModalOpen} />
           
           
  
        </div>

        <Modal title={"Add New event"} modalOpen={modalOpen} setModalOpen={setModalOpen}  >
           <AddEditEvent isEditMode={false}  setModalOpen={setModalOpen} />
        </Modal>

        </>
   
    )
}

export default CourseEvents
