import isValidEmail from "../utils/isValidEmail";

export default function validate(values) {
    let errors = {};
    
    if (!values.email) {
        errors.email = 'Email is required';
      } 
    
    if (!isValidEmail(values.email)){
        errors.email = 'Please enter a valid email ';
      }
    
    return errors;
  };