export default function validate(values) {
    let errors = {};
    
    if (!values.title) {
        errors.title = 'Title  is required';
    } 
    if (!values.description) {
        errors.description = 'Description is required';
    }

    if (!values.image  ) {
        errors.image = 'image is required';
    }

    if (values.image?.size >3e6){
        errors.image = 'Please upload a file smaller than 3 MB';
    }

    return errors;
  };