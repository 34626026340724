import { ActionsTypesNotification } from "../constants/notificationConstants";

export const listNotificationsReducer = (state={},{type,payload}) => {
    switch (type) {
        case  ActionsTypesNotification.REQUEST_NOTIFICATION_COURSE:
            return {
                loading:true,
            };
        case  ActionsTypesNotification.SUCCESS_NOTIFICATION_COURSE:
            return {
                loading:false,
                notifications:payload
            };

        case  ActionsTypesNotification.FAIL_NOTIFICATION_COURSE:
            return {
                loading:false,
                error:payload
            };
        default:
            return state;
    } 
}
