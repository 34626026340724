import React, { useEffect,useState } from 'react';
import App from './App';
import  Axios  from "../src/redux/Axios";
import Loading from './components/Loading';
import Error500 from './Pages/errors/Error500';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { logout, statusServer } from './redux/actions/authActions';

function BaseApp() {
    
    const dispatch = useDispatch()
    const {status,loading,error} = useSelector(state=>state.status)
   
    useEffect(() => {
        dispatch(statusServer())
    }, [dispatch])

    useEffect(() => {
      if (error ==="Invalid token."){
          dispatch(logout())
      }
      
    }, [error])
    

  return( 
    <div>
        {
          loading ? 
              <Loading />
          :
          status===200?
              <App />
          :""

        }
    </div>
  );
}

export default BaseApp;
