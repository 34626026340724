import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { detailCourseByCat } from '../redux/actions/courseActions'
import Loading from './Loading'
import Event from '../components/Event'
import NoEventSvg from '../assets/svgs/no-event.svg'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

function ListEvents() {

    const {id} = useParams()

    const {data,loading} = useSelector(state => state.courseData)
    
    const {t }= useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(detailCourseByCat(id,"events"))
    }, [dispatch,id])

    return (
        <div>
            {
                loading ? 
                    <Loading />
                :
                data.events && data.events.length >0 ?
                
                data.events.map(
                    ev => <Event key={ev.id} courseEvent={ev}  /> 
                    )   :
                <div>
                    <div className='pt-20  flex flex-col items-center justify-center  mt-4 '>
                        
                        <div>
                            <img src={NoEventSvg} /> 
                        </div>
                        <div className='text-xl '>
                            0 {t('events')}
                        </div>
                    </div>
                </div>

            }
        </div>
    )
}

export default ListEvents