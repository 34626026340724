import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CsLineIcons from '../cs-line-icons/CsLineIcons';
import { listCourse } from '../redux/actions/courseActions';
import Course from './Course';

function ListCourses() {

    const dispatch = useDispatch()
    
    const {data,error} = useSelector(state => state.coursesList)
    
    useEffect(() => {
        dispatch(listCourse())
    }, [dispatch])

    const sliderRef = useRef(null)

    const onClickNext = ()=>{
        sliderRef.current.scrollLeft +=100
    }

    const onClickPrev = ()=>{
        sliderRef.current.scrollLeft -=100
    }

    return <div>
                <div>
                    {
                    error ?
                        <div> {error}</div>
                    :data?.courses?.length >0?
                    <div>
                        <div ref={sliderRef} className='flex overflow-x-scroll pb-3 hide-scroll-bar'>
                            <div className="flex flex-nowrap  ">
                                { 
                                    data?.courses && data.courses.map(course => 
                                        <Course key={course.id} course={course} />) 
                                }
                            </div>
                        </div>
                        { data?.courses?.length > 1 &&
                        <div className='flex justify-end'>
                            <button onClick={onClickPrev} className='p-2 mx-2 bg-primary text-white rounded-md'>
                                <CsLineIcons icon='chevron-left' />
                            </button>
                            <button onClick={onClickNext} className='p-2 mx-2 bg-primary text-white rounded-md'>
                                <CsLineIcons icon='chevron-right' />
                            </button>

                        </div>
                        }
                    </div>
            
                : 
                <div className='py-3 flex flex-col items-center justify-center' >

                    <img src={require('../assets/svgs/no_course.svg').default} alt="" />
                    
                    <div className='pt-4'>
                         No Courses yet 
                        
                    </div>

                    
                </div>
                }
            </div>
        </div>;
}

export default ListCourses;
