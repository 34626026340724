
export const ActionsTypesCourse = {
    REQUEST_EDIT_COURSE :"REQUEST_EDIT_COURSE",
    SUCCESS_EDIT_COURSE :"SUCCESS_EDIT_COURSE",
    FAIL_EDIT_COURSE :"FAIL_EDIT_COURSE",

    // LEACE COURSE 
    REQUEST_LEAVE_COURSE :"REQUEST_LEAVE_COURSE",
    SUCCESS_LEAVE_COURSE :"SUCCESS_LEAVE_COURSE",
    FAIL_LEAVE_COURSE :"FAIL_LEAVE_COURSE",

    REQUEST_STATS_COURSE :"REQUEST_STATS_COURSE",
    SUCCESS_STATS_COURSE :"SUCCESS_STATS_COURSE",
    FAIL_STATS_COURSE :"FAIL_STATS_COURSE",

    // // LEACE COURSE 
    // REQUEST_LEAVE_COURSE :"REQUEST_LEAVE_COURSE",
    // SUCCESS_LEAVE_COURSE :"SUCCESS_LEAVE_COURSE",
    // FAIL_LEAVE_COURSE :"FAIL_LEAVE_COURSE",
}
