import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { detailCourseByCat } from '../redux/actions/courseActions'

import { useTranslation } from 'react-i18next'
import ListStudents from '../components/ListStudents'

function CourseStudents() {
  
    return (
        <>
             <div  className="">
                <h1 className="text-2xl font-semibold">
                     Students
                </h1>
                <ListStudents />
            </div>
        </>
    )
}

export default CourseStudents
