export const ActionTypes = {
   REQUEST_LOGIN_USER :"REQUEST_LOGIN_USER",
   SUCCESS_LOGIN_USER :"SUCCESS_LOGIN_USER",
   FAIL_LOGIN_USER :"FAIL_LOGIN_USER",

   LOGOUT :"LOGOUT",

   // COURSE CONSTANTS ACTIONS TYPE

   REQUEST_LIST_COURSE :"REQUEST_LIST_COURSE",
   SUCCESS_LIST_COURSE :"SUCCESS_LIST_COURSE",
   FAIL_LIST_COURSE :"FAIL_LIST_COURSE",

   REQUEST_DETAIL_COURSE :"REQUEST_DETAIL_COURSE",
   SUCCESS_DETAIL_COURSE :"SUCCESS_DETAIL_COURSE",
   FAIL_DETAIL_COURSE :"FAIL_DETAIL_COURSE",

   REQUEST_DETAIL_CAT_COURSE:"REQUEST_DETAIL_CAT_COURSE",
   SUCCESS_DETAIL_CAT_COURSE:"SUCCESS_DETAIL_CAT_COURSE",
   FAIL_DETAIL_CAT_COURSE:"FAIL_DETAIL_CAT_COURSE",

   // Add Course 
   REQUEST_CREATE_COURSE :"REQUEST_CREATE_COURSE",
   SUCCESS_CREATE_COURSE :"SUCCESS_CREATE_COURSE",
   FAIL_CREATE_COURSE :"FAIL_CREATE_COURSE",

   // Delete Course 
   REQUEST_DELETE_COURSE :"REQUEST_DELETE_COURSE",
   SUCCESS_DELETE_COURSE :"SUCCESS_DELETE_COURSE",
   FAIL_DELETE_COURSE :"FAIL_DELETE_COURSE",

   // Enroll to Course 
   REQUEST_ENROLL_COURSE :"REQUEST_ENROLL_COURSE",
   SUCCESS_ENROLL_COURSE :"SUCCESS_ENROLL_COURSE",
   FAIL_ENROLL_COURSE :"FAIL_ENROLL_COURSE",
   
   // CREATE ANNONCE 
   REQUEST_CREATE_ANNOUNCE :"REQUEST_CREATE_ANNOUNCE",
   SUCCESS_CREATE_ANNOUNCE :"SUCCESS_CREATE_ANNOUNCE",
   FAIL_CREATE_ANNOUNCE :"FAIL_CREATE_ANNOUNCE",


   // EVENT constants actions type


   REQUEST_LIST_EVENT :"REQUEST_LIST_EVENT",
   SUCCESS_LIST_EVENT :"SUCCESS_LIST_EVENT",
   FAIL_LIST_EVENT :"FAIL_LIST_EVENT",

   REQUEST_DETAIL_EVENT :"REQUEST_DETAIL_EVENT",
   SUCCESS_DETAIL_EVENT :"SUCCESS_DETAIL_EVENT",
   FAIL_DETAIL_EVENT :"FAIL_DETAIL_EVENT",

};