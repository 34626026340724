import { useState, useEffect } from 'react';
import Axios from '../redux/Axios'

export  function usePlanActivated() {

  const [isPlanActive, setIsPlanActive] = useState(false);

  useEffect(() => {
    Axios.get('/teacher/orderplan').then(res=>{
        
        setIsPlanActive(!res.data.data.detail.is_expired)
       
    })
   
  });

  return isPlanActive;
}