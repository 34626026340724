import React from 'react'

function Footer() {
    return (
        <footer className="footer mt-20 bg-dark text-gray-200  pt-1  ">
           
            <div className="container mx-auto px-6">
                <div className="mt-4 flex flex-col items-center">
                    <div className="sm:w-2/3 text-center py-6">
                        <p className="text-sm font-bold mb-2">
                            © {new Date().getFullYear()} by Aynnaka
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
