import React, {  useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link} from 'react-router-dom'
import CsLineIcons from '../cs-line-icons/CsLineIcons'
import { deleteCourse, leaveCourse, listCourse } from '../redux/actions/courseActions'
import AddEditCourse from './AddEditCourse'
import DropDown from './DropDown'
import Modal from './Modal'
import Role from './Role'

function Course({course}) {

    const dispatch = useDispatch()
    const [isConfimationOpen, setIsConfimationOpen] = useState(false);
    const [actionConfirmed, setActionConfirmed] = useState(false);
    const [isConfimationLeaveOpen, setIsConfimationLeaveOpen] = useState(false);
    const [actionLeaveConfirmed, setActionLeaveConfirmed] = useState(false);    
    

    const chooseEventAction = (op)=> ev=> {
        if (op.name==="Edit"){
            setModalOpen(true)
        }
        if (op.name==="Delete"){
            setIsConfimationOpen(true)
            setActionConfirmed(true)
        }
    }
 
    const [isOpen, setIsOpen] = useState(false)
    const toggling = () => setIsOpen(!isOpen);

    const options = [
        {
            name:"Edit",
            type:"teacher"
        },
        {
            name:"Delete",
            type:"teacher"
        }
    ]

    const handleLeaveCourse = () => {

        if (actionLeaveConfirmed){
            dispatch(leaveCourse(course?.id))
            dispatch(listCourse())
        }
        setIsConfimationLeaveOpen(false)
      
    }

    const [modalOpen, setModalOpen] = useState(false)

    const handleDeleteCourse = () => {
        if ( actionConfirmed ){
            dispatch(deleteCourse(course.id))
        }
    }
    return (
        
            <div className="bg-white w-72 md:w-96 mb-8 pb-4 mx-3 dark:bg-gray-700 dark:bg-opacity-60 relative rounded-xl shadow-md hover:shadow-xl hover:drop-shadow-xl ">
                
                    {/* top image */}
                    <Link to={`/course/${course.id}`}>
                        {/* {course.image ? <img src={''+course.image} alt={course.title}/> : <img alt='' className="rounded-t-md" src="https://www.freecodecamp.org/news/content/images/size/w2000/2021/07/reactcourse.png" /> }  */}
                    
                        <div className='h-60 rounded-t-xl bg-cover bg-no-repeat bg-center' style={{backgroundImage:course.image && `url(${course.image})`}} >

                        </div> 
                     </Link>
                    <div className="flex justify-between items-start">
                        <div className="p-3 ">
                            <h1 className="text-md py-2 font-semibold">{course.title}</h1>
                            <Role type={'student'}>
                                <div className="text-sm flex items-center  text-gray-400">
                                    Created by <div className='w-1 h-1 rounded-full bg-gray-400 mx-1'></div> {course.created_by}
                                </div>
                               
                            </Role>
                            <div className="  line-clamp-2 text-gray-800">
                                {course.description}
                            </div>
                            
                        </div>
                        <Role type={'teacher'}>
                            <div className="p-2 relative ">
                                <button onClick={toggling} className=" p-2 rounded-full ">
                                    <CsLineIcons icon="more-horizontal" />
                                </button>   
                                <DropDown options={options} onClickAction={chooseEventAction} type={"teacher"} setIsOpen={setIsOpen} isOpen={isOpen}  />
                            </div>
                        </Role>
                        <Role type={'student'}>
                            <div className="p-2  ">
                                <button onClick={()=> {setIsConfimationLeaveOpen(true);setActionLeaveConfirmed(true)}} className="p-2 bg-secondary rounded-md">
                                    Leave 
                                </button>
                            </div>
                        </Role>
                        
                       

                    </div>
                    

                    {/* Edit Course Form */}

                    <Modal  modalOpen={modalOpen} setModalOpen={setModalOpen}>
                       <AddEditCourse title={"Edit Course"} setIsOpen={setModalOpen} course={course} isEditMode={true} /> 
                        
                    </Modal>
                    {/* confirmation delete course */}

                    <Modal width={"w-64 text-center"}  title={"Are you sure you want to delete this course"} modalOpen={isConfimationOpen} setModalOpen={setIsConfimationOpen} >
                        <div >
                            <div className='py-3 '>
                                <p>By deleting this course , all your data will be lost , students engaged events ...</p>
                            </div>
                            
                            <button onClick={handleDeleteCourse} className='p-2 bg-primary  rounded-md w-32 text-white ' >
                                Yes i m sure  
                            </button>
                        </div>
                       
                    </Modal>
                    
                    {/* confirmation leave course */}
                    
                    <Modal width={"w-64 text-center"}  title={"Are you sure you want to leave this course"} modalOpen={isConfimationLeaveOpen} setModalOpen={setIsConfimationLeaveOpen} >
                        <div >
                            <div className='py-3 '>
                                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam, molestias expedita labore sint</p>
                            </div>
                            
                            <button onClick={handleLeaveCourse} className='p-2 bg-primary  rounded-md w-32 text-white ' >
                                Yes i m sure  
                            </button>
                        </div>
                       
                    </Modal>
                
            </div>
   
    )
}

export default Course
