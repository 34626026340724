// ANNONCE CREATE CONSTANTS
export const REQUEST_CREATE_ANNOUNCE ="REQUEST_CREATE_ANNOUNCE"
export const SUCCESS_CREATE_ANNOUNCE ="SUCCESS_CREATE_ANNOUNCE"
export const FAIL_CREATE_ANNOUNCE ="FAIL_CREATE_ANNOUNCE"

// ANNONCE CONSTANTS DELETE
export const REQUEST_DELETE_ANNOUNCE ="REQUEST_DELETE_ANNOUNCE"
export const SUCCESS_DELETE_ANNOUNCE ="SUCCESS_DELETE_ANNOUNCE"
export const FAIL_DELETE_ANNOUNCE ="FAIL_DELETE_ANNOUNCE"

// ANNONCE BY COURSE CONSTANTS 
export const REQUEST_LIST_COURSE_ANNOUNCE ="REQUEST_LIST_COURSE_ANNOUNCE"
export const SUCCESS_LIST_COURSE_ANNOUNCE ="SUCCESS_LIST_COURSE_ANNOUNCE"
export const FAIL_LIST_COURSE_ANNOUNCE ="FAIL_LIST_COURSE_ANNOUNCE"


// ANNONCE EDIT CONSTANTS
export const REQUEST_EDIT_ANNOUNCE ="REQUEST_EDIT_ANNOUNCE"
export const SUCCESS_EDIT_ANNOUNCE ="SUCCESS_EDIT_ANNOUNCE"
export const FAIL_EDIT_ANNOUNCE ="FAIL_EDIT_ANNOUNCE"
