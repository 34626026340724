import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import './App.css';
import './assets/css/animate.css'
import './i18n'

import Home from "./Pages/Home";
import Login from "./Pages/accounts/Login";
import LiveRoom from "./Pages/LiveRoom";

import CourseDetail from "./Pages/CourseDetail";
import CourseEvents from "./Pages/CourseEvents";
import CourseAnnonces from "./Pages/CourseAnnounces";
import CourseFiles from "./Pages/CourseFiles";
import CourseStudents from "./Pages/CourseStudents";
import { useSelector } from "react-redux";
import Welcome from "./Pages/Welcome";
import Account from "./Pages/accounts/Account";
import Pricing from "./Pages/Pricing";
import Payment from "./Pages/Payment";
import PaymentSuccess from "./Pages/PaymentSuccess";
import Layout from "./components/Layout";
// import { useEffect } from "react";
// import { getUser } from "./redux/actions/authActions";
// import { useDispatch } from "react-redux";
import VerifyEmail from "./Pages/accounts/VerifyEmail";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Error400 from "./Pages/errors/Error400";
import RegisterStudent from "./Pages/accounts/RegisterStudent";
import RegisterTeacher from "./Pages/accounts/RegisterTeacher";
import BillingDashboard from "./Pages/BillingDashboard";
import ResetPassword from "./Pages/accounts/ResetPassword";
import ChangePassword from "./Pages/accounts/ChangePassword";
import LayoutAccount from "./components/LayoutAccount";
import DeleteAccount from "./Pages/accounts/DeleteAccount";
import ResetPasswordConfirm from "./Pages/accounts/ResetPasswordConfirm";


function App() {

  const {user} = useSelector(state => state.auth)
  
  // const dispatch = useDispatch()
  
  // useEffect(() => {
  //   dispatch(getUser())
  // }, [dispatch])

  return (
    <>
      <ToastContainer autoClose={2000} />

      <Router>
        <Routes>
          {/* <Route path="/" element={ type==="admin" ?<AdminHome /> :<Login  />} /> */}
          <Route path="/" element={  user !==null  ? <Home  />:<Welcome  />} />
          <Route path="login" element={<Login />} />
          <Route path="payment" element={<Payment />} />
          <Route path="verify-email" element={<VerifyEmail />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="reset-password/confirm/:token" element={<ResetPasswordConfirm />} />
          
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="payment/success" element={<PaymentSuccess />} />
          
          {/* <Route path="account" element={<Account />} /> */}
          <Route path='account' element={ user !==null ? <LayoutAccount />:<Login />} >
            <Route index path="" element={<Account />} /> 
            <Route path="change-password" element={<ChangePassword />} /> 
            <Route path="delete-account" element={<DeleteAccount />} /> 
          </Route>

          <Route path="pricing" element={user !==null && user?.type==="teacher" ? <Pricing />:<Login />} />
          <Route path="billing-dashboard" element={user !==null && user?.type==="teacher" ? <BillingDashboard />:<Login />} />

          <Route path="register/student"  element={<RegisterStudent />} />
          <Route path="register/teacher"  element={<RegisterTeacher />} />

          {/* <Route path="register/:type"  element={<Register />} /> */}
          <Route path="course/:id" element={ user !==null? <Layout />:<Navigate to="/login" />}  > 
            <Route index element={ <CourseDetail />} />
            <Route path="events" element={<CourseEvents /> } />
            <Route path="announces" element={<CourseAnnonces />} />
            <Route path="files" element={<CourseFiles />} />    
            <Route path="students" element={<CourseStudents />} />        
          </Route>
          <Route path="meet" element={user !==null ? <LiveRoom />:<Navigate to="/login" />} />
          <Route path="*" element={<Error400 />} />

        </Routes>
      </Router>
    </>

  );
}

export default App;
