import axios from "axios"
import encrypt from "./EcryptUrl"
import fxp from "fast-xml-parser";

const xmlToJson = (xml)=>{
    const json = fxp.parse(xml).response
    return json;
}

const createMeeting = (meetingID,name,studentPW,teacherPW,maxParticpants,muteOnStart)=> {

    let params = {
        "meetingID":meetingID,
        "name":name,
        "attendeePW":studentPW,
        "moderatorPW":teacherPW,
        "welcome":"Aynnaka Meet",
        // "dialNumber":"0671300616",
        "maxParticipants":maxParticpants,
        "logoutURL":window.location.href,
        "muteOnStart":muteOnStart,
        
    }
    const url = encrypt('create',params)
    

    const promise  = axios.get(url)

    // using .then, create a new promise which extracts the data
    const dataPromise = promise.then((response) => response.data)


    return dataPromise
}

const joinMeeting = (fullName,meetingID,password,userID)=> {

    let params = {
        "fullName":fullName,
        "meetingID":meetingID,
        "password":password,
        "userID":userID,
    }
    const url = encrypt('join',params)

    // const dataPromise = promise.then((response) => response.data)
    
    // const res = await dataPromise;
    // console.log("data promise ",res)
    return url
}


const isMeetingRunning = (meetId)=> {
    const params = {
        "meetingID":meetId
    }

    const url = encrypt('isMeetingRunning',params)

    const promise  = axios.get(url)

    // using .then, create a new promise which extracts the data
    const dataPromise = promise.then((response) => response.data)

    // return it

    return dataPromise
}

const endMeeting = (meetingID,password) =>{
     
    let params = {
        "meetingID":meetingID,
        "password":password
    }
    const url = encrypt('end',params)

    const promise  = axios.get(url)

    // using .then, create a new promise which extracts the data
    const dataPromise = promise.then((response) => response.data)
    
    return dataPromise;

}

export {
    xmlToJson,
    createMeeting,
    joinMeeting,
    isMeetingRunning,
    endMeeting
}