import React from 'react';
import { Link } from 'react-router-dom';

function Error400() {
  return (<div>
      <div
        className="
            overflow-hidden

            max-w-screen-md
            min-h-screen
            mx-auto
            flex
            justify-center
            items-center
           
            
        "
        >
        <div className=" relative  ">
            <div className="flex flex-col items-center">

                <div className=' text-center '>
                    <img src="./logo.png" alt="" />
                    <p className='pt-8'>Oups ! You are lost</p>
                </div>
                <img src='/images/not_found.png' className='' />

                <div className='flex text-primary items-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                    </svg>
                    <Link to={'/'} className=" ml-3" >GO HOME </Link>
                </div>

          
            </div>
            </div>
        </div>
  </div>);
}

export default Error400;
