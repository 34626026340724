import React, { useEffect } from 'react'
import Student from './Student'
import StudentSvg from '../assets/svgs/student.svg'
import Loading from './Loading'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { detailCourseByCat } from '../redux/actions/courseActions'
import Message from './Message'


function ListStudents() {

    const {id} = useParams()

    const dispatch = useDispatch()

    const {t} = useTranslation()

    const {data,loading,error} = useSelector(state => state.courseData)
    
    useEffect(() => {
        dispatch(detailCourseByCat(id,"students"))
    }, [dispatch,id])

    return (
        <div className="p-2">
                    
            {error ? 
                <Message text={error} />
                :loading    
                ?   
                    <Loading />
                :
                data.students && data.students.length >0 ?
                
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
                {data.students &&  data.students.map(student => (
                    <Student key={student.id} student={student}/> 
                    ))
                }
                </div>
                
                :
                <div>
                        <div className='pt-20 flex flex-col items-center justify-center  mt-4 '>
                        <div>
                            <img src={StudentSvg} /> 
                        </div>
                        <div className='text-xl '>
                            0 {t("students")}
                        </div>
                    </div>
                </div>
                }
        
   </div>
  )
}

export default ListStudents