import React, {  useRef, useState } from 'react'
import useOnClickOutside from '../hooks/useOnClickOutside';



function DropDown({options,isOpen,setIsOpen,onClickAction,type}) {

    const ref = useRef();
    const [selectedOption, setSelectedOption] = useState(options[0]);

    const onOptionClicked = value => () => {
        setSelectedOption(value);
        setIsOpen(false);
      };


    useOnClickOutside(ref, () => setIsOpen(false));

    return (
        <div className={`${isOpen ? "":"hidden"}`}>
        
                <div ref={ref} className={`p-2 right-0 whitespace-nowrap shadow-2xl drop-shadow-2xl absolute z-10 text-gray-800 dark:text-white bg-white dark:bg-gray-700  rounded-md ${isOpen ? "":"hidden"}`}>
                    <ul className="px-3">
                    {options.map(option => (
                        option.type ===type?
                        <div key={Math.random()} onClick={onClickAction(option)}>
                            <li className="py-1 capitalize my-1 px-2 cursor-pointer rounded-md hover:bg-opacity-60  hover:bg-gray-300" onClick={onOptionClicked(option)} >
                                {option.name}
                            </li>
                        </div>
                        :""
                    ))}
                    </ul>
                </div>
        </div>
    )
}

export default DropDown
