import { FAIL_LIST_PLAN, REQUEST_LIST_PLAN, SUCCESS_LIST_PLAN } from "../constants/planConstants";

const initialState = {
    loading: false,
    data: {},
    error:""
}
  
export const listPlanReducer = (state=initialState,{type,payload}) => {
    switch (type) {
        case  REQUEST_LIST_PLAN:
            return {
                loading:true,
                data:{}
            };
        case  SUCCESS_LIST_PLAN:
            return {
                loading:false,
                data:payload
            };

        case FAIL_LIST_PLAN:
            return {
                loading:false,
                error:payload
            };
        default:
            return state;
    } 
}

