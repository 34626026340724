import React from 'react'
import { useSelector } from 'react-redux'

function WelcomeUser() {
    const {user} = useSelector(state => state.user)
    return (
        <div>
            <div className='hidden md:block italic text-xl font-bold'> 
                Hello, {user?.firstname} {user?.lastname}
            </div>
        </div>
    )
}

export default WelcomeUser
