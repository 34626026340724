import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import { upgradePlan } from '../redux/actions/planActions';

function PaymentSuccess() {
    const isSucceed = true;
    const dispatch = useDispatch()
    const idPlan = localStorage.getItem("planId")
    useEffect(() => {
       if(isSucceed){
          
       }
    }, [isSucceed])

    const updatePlan = () => {
        console.log(idPlan)
        dispatch(upgradePlan(idPlan))
    }
    return (
        <div className="min-h-screen">
        <div className="h-16">
            <Header />
        </div>
        <div className="max-width mx-auto p-4 ">
            
            <div className="p-6 bg-white rounded-md flex flex-col items-center">
                
                <div className="flex justify-center">
                    <div className="p-2 bg-green-800 text-white rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                    
                </div>
                <h1 className="text-xl py-3 font-bold">
                    Your payment has been processed successfully
                </h1>
                <br />
                <button onClick={updatePlan}>Upgrade Plan</button>
               
                <br />
                <div className="py-2  px-3">
                     <Link to="/" className="p-2 border-primary border-2 rounded-md">
                        Go to Courses   
                    </Link> 
                </div>
               
            </div>

            
        </div>
    </div>
    )
}

export default PaymentSuccess
