import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import File from '../components/File'
import Axios from '../redux/Axios'
import ProgressBar  from '../components/ProgressBar'
import Loading from '../components/Loading'
import Message from '../components/Message'
import Modal from '../components/Modal'
import { detailCourseByCat } from '../redux/actions/courseActions'
import NoFileSvg from '../assets/svgs/no-file.svg'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { usePlanActivated } from '../hooks/isPlanActivated'
import Add from '../components/Add'

const upload = (file,name,size,type,idCourse, onUploadProgress)=> {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("name", name);
    formData.append("size_file", size);
    formData.append("type_file", type);
    formData.append("course", idCourse);

    return Axios.post("/file/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
}

function CourseFiles() {

    const {id} = useParams()
    const [modalOpen, setModalOpen] = useState(false)

    const [selectedFile, setSelectedFile] = useState();
    const [name, setName] = useState("")
    const [progress, setProgress] = useState(0);

	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
	};


    const addNewFile = ()  => {
        upload(selectedFile,name,selectedFile.size,selectedFile.type,id,(event)=>{
            setProgress(Math.round((100 * event.loaded) / event.total))
        }).then(res=> {
                setModalOpen(false)
                setName("")
                setProgress(0)
                setSelectedFile("")
                dispatch(detailCourseByCat(id,"files"))

                toast.success("File created")
            
        }).catch(err=>{
            console.log(err)
            toast.success("Error")

        })
        
    }

    const dispatch = useDispatch()

    const refInputChoose = useRef(null)
    const {data,loading,error} = useSelector(state => state.courseData)

    useEffect(() => {
       dispatch(detailCourseByCat(id,"files"))
    }, [dispatch,id])


    const [filtredFiles, setFiltredFiles] = useState([])
    
    useEffect(() => {
        setFiltredFiles(data?.files)
    }, [data])
   
    const filterByType = (type) =>{
        type==="all" ?  setFiltredFiles(data?.files) :
            setFiltredFiles(data?.files.filter(item=>item.type_file.includes(type)))
    }

    const {t} = useTranslation()

    
    return (
        <>
            <div  className="">
                <div className="flex justify-between">
                    <h1 className="text-2xl capitalize font-semibold">
                        {t('files')}
                    </h1>
                </div>
              
                <div className="px-4 py-2 ">
                    {/* list of files */}
                    <div className='flex overflow-x-scroll hide-scroll-bar'>
                        <div className="flex pb-3 flex-nowrap ">
                            <div onClick={()=>filterByType('all')} className="bg-secondary text-white  mx-2 cursor-pointer hover:bg-gray-400 p-1 rounded-md px-4 ">
                                {t('all')} 
                            </div>
                            <div onClick={()=>filterByType('application/pdf')} className="bg-secondary text-white  mx-2 cursor-pointer hover:bg-gray-400 p-1 rounded-md px-4 ">
                                Pdf 
                            </div>
                            <div onClick={()=>filterByType('vnd')} className="bg-secondary text-white  mx-2 cursor-pointer hover:bg-gray-400 p-1 rounded-md px-4 ">
                                MS FILES
                            </div>
                            <div onClick={()=>filterByType('image')} className="bg-secondary text-white  mx-2 cursor-pointer hover:bg-gray-400 p-1 rounded-md px-4 ">
                                Image 
                            </div>
                        
                        </div>
                    </div>
                  
                
                {
                    error?
                        <Message text={error} />:
                    loading ?
                        <Loading />:
                    !loading && filtredFiles?.length > 0 
                    ?
                        filtredFiles.map(file => <File file={file} key={file.id} />)
                    :
                    <div className=''>
                        <div className='pt-20 flex flex-col items-center justify-center  mt-4 '>
                           
                            <div>
                                <img src={NoFileSvg} /> 
                            </div>
                            <div className='text-xl '>
                                0 {t('files')} 
                            </div>
                           
                        </div>
                    </div>
                }
                
                 
                </div>
                
            </div>
            
            <Add setModalOpen={setModalOpen} modalOpen={modalOpen} />
            
           


            <Modal  title={"add new file"} modalOpen={modalOpen} setModalOpen={setModalOpen}  >
        
                {progress >0 || progress===100  ?
                    <ProgressBar height={"10px"} bgColor={"grey"} barColor={"blue"} used={progress} total={100} />
                :
                <div>

                </div>
                }
                <div className="border rounded-md my-2">
                    <input className="p-2 w-full bg-transparent outline-none" type="text" value={name} onChange={(e)=> setName(e.target.value)} placeholder="Name file "/>
                </div>
            
                {selectedFile ?
                <div>
                    <div onClick={()=> refInputChoose.current.click()} className="flex flex-col text-green-600 items-center justify-center border border-green-400   h-24 rounded-md my-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        {selectedFile?.name}
                    </div>
                    
                </div>:
                <div onClick={()=> refInputChoose.current.click()} className="flex text-gray-200 items-center justify-center border border-blue-400 border-dashed  h-24 rounded-md my-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
                    </svg>
                </div>
                }
                <input ref={refInputChoose} className="p-2 w-full bg-transparent outline-none hidden" type="file" name="file" onChange={changeHandler} placeholder="Your file" />

                <div className=" my-2 text-center">
                    <button  onClick={addNewFile} className={`p-2 text-center rounded-md bg-primary ${loading?"bg-opacity-60":""} text-white`} disabled={loading} >
                        {t("save")}
                    </button>
                </div>

            </Modal>
        </>
    )
}

export default CourseFiles
