import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EnrollToCourse } from '../redux/actions/courseActions';
import Loading from './Loading';
import Message from './Message';
import Role from './Role';

function EnrollToCourseComp({setIsOpen}) {

    const [codeCourse, setCodeCourse] = useState("")
    const [messageError, setMessageError] = useState("");
    const enrollCourseState = useSelector(state => state.enrollCourse)
    
   
    const dispatch = useDispatch()

    const isValideCodeCourse = (code) =>{
        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        if (regexExp.test(code)) return true;
        else return false;
    }

    const handleEnrollToCourse = () => {
        if(isValideCodeCourse(codeCourse) ){
            dispatch(EnrollToCourse(codeCourse))
            setCodeCourse("")
            setIsOpen(false)
        }
             
        else   setMessageError("please enter a valid Course ID")
        
     }

    return <div>
            <Role type="student">
                <div>
                    <div className='text-xl font-bold pt-4'>
                        Enroll to New Course
                    </div>
                    <div>
                       
                        <div className="py-2 text-red-500">
                            {enrollCourseState && enrollCourseState.loading ? 
                                <Loading />:
                                enrollCourseState.error ?
                                <Message text={enrollCourseState.error} />:
                                ""
                            }
                        </div>

                        Copy the code you recieved from you instrector here  ! 
                        <div className="border rounded-md my-2">
                            <input value={codeCourse}  onChange={(e)=> setCodeCourse(e.target.value)} type="text" className="p-2 w-full bg-transparent outline-none" placeholder="Course Code"  name="" id="" />
                        </div>
                        <div className='text-red-600'>
                            {
                                messageError
                            }
                        </div>
                            
        
                        <div className=" my-2 text-center">
                            <button onClick={handleEnrollToCourse} className="p-2 text-center rounded-md bg-primary text-white" >
                                Enroll 
                            </button>
                        </div>
                    </div> 
                </div>
            </Role>
        </div>;
}

export default EnrollToCourseComp;
