import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CsLineIcons from '../cs-line-icons/CsLineIcons';
import DropDown from './DropDown';

function Language() {
    const  {i18n} = useTranslation()
    const [isOpen, setIsOpen] = useState(false);
    const options =[
        {type:"",name:"french"},
        {type:"",name:"english"},

    ]
    const changeLanguage = (lng) => {
        // i18n.changeLanguage(lng);
    };

    const onClickDropDownItem = (option)=>(e) =>{
       
        if(option.name==="french"){
            i18n.changeLanguage("fr")
        }else if (option.name==="english"){
            i18n.changeLanguage("en")

        }
    }

   
  return (
      <div className='relative'>
        <button onClick={() =>setIsOpen(!isOpen)} className="px-2  flex items-center">
            <span className='uppercase'>{i18n.language} </span>
            <CsLineIcons icon='chevron-bottom' size={14}/>
            
        </button>
        <DropDown onClickAction={onClickDropDownItem} type={""} options={options} setIsOpen={setIsOpen} isOpen={isOpen}  />
            
      </div>
  )


 ;
}

export default Language;
