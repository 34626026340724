import React , {useEffect,useState} from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import Axios from '../redux/Axios'

function Stats() {

    const [files, setFiles] = useState([])
    const [announces, setAnnounces] = useState([])
    const [events, setEvents] = useState([])

    useEffect(() => {
        
        Axios.get('stats').then(res=>{
            setFiles(res.data.data.files)
            setEvents(res.data.data.events)
            setAnnounces(res.data.data.annonces)
        })
    }, [])

    const dataMock = [
        { title: 'Events' , value: events?.length, color: 'rgba(113, 135, 125, 0.75)' },
        { title: 'Files', value: files?.length, color: 'rgba(27, 105, 179, 0.75)' },
        { title: 'Announces', value: announces?.length, color: 'rgba(255, 185, 35, 0.75)' },
    ]
  return <div className='bg-white shadow-md  flex flex-col  items-center py-4  px-3 rounded-md '>
            <div className='font-bold pb-2 text-xl text-center'>
                Stats
            </div>

            <div  className='flex justify-between items-center py-2'>
                <div className='md:p-3'>
                    <PieChart
                        style={{ height: '220px' }}
                        data={dataMock} 
                        lineWidth={30}
                        animate={true}
                        // paddingAngle={10}
                        background="#bfbfbf"
                        //label={({ dataEntry }) => dataEntry.value}
                        labelStyle={(index) => ({
                        fill: dataMock[index].color,
                            fontSize: '5px',
                        })}
                    
                    /> 
                </div>
                <div className='p-2'>
                    <div className='py-1 w-36'>
                        <div style={{backgroundColor:dataMock[0].color}} className={`flex justify-between bg-opacity-30 py-2 px-4 rounded-md`}>
                            <div className='pr-3' >
                                {dataMock[0].title}
                            </div>
                            <div className='text-md font-bold'>
                                {dataMock[0].value}
                            </div>
                        </div>
                    </div>
                    <div className='py-1'>
                        <div  style={{backgroundColor:dataMock[1].color}} className='flex justify-between bg-green-light bg-opacity-30 py-2 px-4 rounded-md'>
                            <div className='pr-3' >
                                {dataMock[1].title}
                            </div>
                            <div className='text-md font-bold'>
                                {dataMock[1].value}
                            </div>
                        </div>
                    </div>
                    <div className='py-1'>
                        <div  style={{backgroundColor:dataMock[2].color}} className='flex justify-between bg-green-light bg-opacity-30 py-2 px-4 rounded-md'>
                            <div className='pr-3' >
                                {dataMock[2].title}
                            </div>
                            <div className='text-md font-bold'>
                                {dataMock[2].value}
                            </div>
                        </div>
                    </div>
                  
                </div>
            </div>

        </div>;
}

export default Stats;
