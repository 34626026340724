import React from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useForm from '../hooks/useForm';
import { createAnnounce, editAnnonce } from '../redux/actions/announceActions';
import { validate } from '../validation/AnnounceFormValidationRuels';

function AddEditAnnounce({setModalOpen,isEditMode,announce}) {
    
    const {id} = useParams()
    
    const dispatch = useDispatch()
    
    function handleAddOrEditAnnounce() {
        values["course"] = id
        isEditMode ?
            dispatch(editAnnonce(values,announce.id))
        :
            dispatch(createAnnounce(values,id))
           
          setModalOpen(false)
      }
 
    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        } = useForm(handleAddOrEditAnnounce, validate);

    useEffect(() => {
        if (isEditMode) {
            values.title =announce.title;
            values.description =announce.description;

        }
    

    }, [isEditMode])
    

    return (
            <form onSubmit={handleSubmit} >
                <div className={` ${errors.title ? "border-red-500":""} border rounded-md my-2`}>
                    <input name='title' value={values.title || ''} onChange={handleChange} className="p-2 w-full bg-transparent outline-none" type="text" placeholder="Annonce Title " />
                </div>
                {errors.title && (
                    <p className="help text-red-500">{errors.title}</p>
                )}
                


                <div className={` ${errors.description ? "border-red-500":""} border rounded-md my-2`}>
                    <textarea name='description' value={values.description || ''}  onChange={handleChange} className="p-2 w-full bg-transparent outline-none" type="text" placeholder="Annonce Description " />
                </div> 
                {errors.description && (
                    <p className="help text-red-500">{errors.description}</p>
                )}
                
                <div className=" my-2 text-center">
                    <button type='submit' className="p-2 text-center rounded-md bg-primary text-white" >
                        Save Annonce
                    </button>
                </div>

            </form>
    )
}

export default AddEditAnnounce