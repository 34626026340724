import { FAIL_CREATE_ANNOUNCE, FAIL_DELETE_ANNOUNCE, FAIL_EDIT_ANNOUNCE, FAIL_LIST_COURSE_ANNOUNCE, REQUEST_CREATE_ANNOUNCE, REQUEST_DELETE_ANNOUNCE, REQUEST_EDIT_ANNOUNCE, REQUEST_LIST_COURSE_ANNOUNCE, SUCCESS_CREATE_ANNOUNCE, SUCCESS_DELETE_ANNOUNCE, SUCCESS_EDIT_ANNOUNCE, SUCCESS_LIST_COURSE_ANNOUNCE } from '../constants/announceConstants';
import Axios from '../Axios'
import { ActionTypes } from '../constants/action-types';
import { detailCourseByCat } from './courseActions';
import { toast } from 'react-toastify';

export const createAnnounce = (annonce,id) => async (dispatch) =>{
    try {
        dispatch({type:REQUEST_CREATE_ANNOUNCE,payload:{}})
        const {data} = await Axios.post("annonce/",annonce);
        dispatch({type:SUCCESS_CREATE_ANNOUNCE,payload:data})

        dispatch(detailCourseByCat(annonce.course,"annonces"))
        toast.success("Announce Created ");
    } catch (error) {
        dispatch({
            type:FAIL_CREATE_ANNOUNCE,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
        toast.warn("Announce not created ");
    }
}

export const editAnnonce = (annonce,announceId) => async (dispatch,getState) =>{
    try {
        dispatch({type:REQUEST_EDIT_ANNOUNCE,payload:{}})
        const {data} = await Axios.put(`annonce/${announceId}/`,annonce);
        dispatch({type:SUCCESS_EDIT_ANNOUNCE,payload:data})
        dispatch(detailCourseByCat(annonce.course,"annonces"))
        toast.success("Announce Updated");
    } catch (error) {
        dispatch({
            type:FAIL_EDIT_ANNOUNCE,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
        toast.warn("Announce Not Updated");


    }
}

export const deleteAnnonce = (announceId) => async (dispatch,getState) =>{
    try {
        dispatch({type:REQUEST_DELETE_ANNOUNCE,payload:{}})
        const {data} = await Axios.delete(`annonce/${announceId}/delete`);
        dispatch({type:SUCCESS_DELETE_ANNOUNCE,payload:data})
        const {courseData} = getState()
        courseData["annonces"]=courseData.data.annonces.filter((an)=>an.id!==announceId)
       
        dispatch({type:ActionTypes.SUCCESS_DETAIL_CAT_COURSE,payload:courseData})
        toast.success("Announce Deleted");
        

    } catch (error) {
        dispatch({
            type:FAIL_DELETE_ANNOUNCE,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
        toast.success("Error in Deleting announce, Please try again");

    }
}

export const listAnnouncesByCourse = (idCourse) => async (dispatch) => {
    dispatch({type:REQUEST_LIST_COURSE_ANNOUNCE,payload:[]})    
    try {
        const {data} =await  Axios.get(`/course/${idCourse}/annonces`)
        dispatch({type:SUCCESS_LIST_COURSE_ANNOUNCE,payload:data.data.annonces})
     
    } catch (error) {
        dispatch({
            type:FAIL_LIST_COURSE_ANNOUNCE,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        })
      
    }
}
