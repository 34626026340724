import React from 'react'
import Header from '../components/Header'
import CsLineIcons from '../cs-line-icons/CsLineIcons'
import girlImg from '../assets/images/girl.png'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
// import { Link } from 'react-router-dom'

const Card = ({icon,bgIcon,title,description}) => {
    return (
        <div className={`p-4 text-center shadow-md flex flex-col items-center rounded-md`}>
            <div className={`w-10 text-white h-10 flex items-center justify-center rounded-full ${bgIcon}`}>
               <CsLineIcons icon={icon} />
            </div>
            <div className='py-2'>
                <h1 className='text-xl '>{title}</h1>
            </div>
            <div>
                <p className='text-sm text-gray-700'>
                    {description}
                </p>
                
            </div>
        </div>
    )
}

const Article = ({title,src,description,orientation})=> {

    return (
        <div className={orientation==="vertical"? "":"flex"}>
            <div className='p-4'>
                <img src={src} alt="" />
            </div>
            <div className='p-4'>
                <div>
                    <h1 className='text-sm font-bold'>{title}</h1>
                </div>

                <div className='pt-2'>
                    <p className='text-gray-600 text-xs'>{description}</p>
                </div>
                
            </div>
            
        </div>
    )
}

function Welcome() {
    return (
        <>
            <div >
                <Header  />
            </div>
            <div style={{height:"80vh",backgroundImage:'url(./images/bgcover.png)'}} className="bg-custom1  overflow-hidden  relative ">
               <div style={{height:"80vh"}} className='flex items-center justify-center'>
                    <div className="max-w-screen-xl py-10 px-8  mx-auto">
                        <div className='flex  items-center justify-center '>
                            <div className='md:w-1/2 px-8'>
                                <h1 className='text-4xl  font-bold '>
                                        Studying Online is now much easier
                                </h1>
                                <p className='text-2xl pt-8'>
                                    Ayskill is an interesting platform that will teach you in more an interactive way
                                    </p>
                                    <div className='py-8'>
                                        <button className='p-2 bg-secondary text-white rounded-xl'>
                                            Join for free
                                        </button>
                                        
                                    </div>
                                    <div className='flex items-center'>
                                        <div className='bg-white p-2 rounded-full'>
                                            <CsLineIcons size={30} icon='play' />
                                        </div>
                                        <div className='px-4'>
                                            Watch how it works
                                        </div>
                                        
                                    </div>
                            </div>
                            <div style={{height:"80vh"}} className='md:w-2/3 md:flex  hidden relative'>
                                    <div className='absolute top-1/2 bg-white bg-opacity-80 backdrop-blur-2xl p-4 rounded-xl left-0'>
                                    <div className='flex items-center'>
                                            <CsLineIcons icon='video' className='text-primary' />
                                            <div className='px-2'>
                                                +250 Event
                                            </div>
                                    </div>
                                </div>
                                <div className='absolute top-1/3 z-30 bg-white bg-opacity-80 backdrop-blur-2xl p-4 rounded-xl right-7'>
                                    <div className='flex items-center'>
                                            <CsLineIcons icon='video' className='text-primary' />
                                            <div className='px-2'>
                                                +250 Events
                                            </div>
                                    </div>
                                </div>
                                <div className='absolute bottom-20 z-30 bg-white bg-opacity-80 backdrop-blur-2xl p-4 rounded-xl  left-20'>
                                        <div className='flex items-center'>
                                            <CsLineIcons icon='file' className='text-secondary' />
                                            <div className='px-2'>
                                                +250 Files
                                            </div>
                                    </div>
                                </div>

                                <div className='absolute top-2/3 z-30 bg-white bg-opacity-80 backdrop-blur-2xl p-4 rounded-xl  left-1/2'>
                                        <div className='flex items-center'>
                                            <CsLineIcons icon='file' className='text-primary' />
                                            <div className='px-2'>
                                                +250 Files
                                            </div>
                                    </div>
                                </div>

                                <div className=' '>
                                    <img src={girlImg} className="absolute  z-10 w-full bottom-0 right-0" alt="" />
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className='absolute bottom-0 w-full '>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                            <path fill="#f0f2f5" fill-opacity="1" d="M0,160L120,186.7C240,213,480,267,720,277.3C960,288,1200,256,1320,240L1440,224L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
                        </svg>
                    </div>
               </div>
          
             
            
            </div>
         

            {/* section : All-In-One Cloud Software. */}

            <div className='py-8 px-4 mx-auto max-w-screen-lg'>
                <div className='grid md:grid-cols-3 gap-3 md:gap-8'>
                    <Card bgIcon={"bg-primary"} icon={"user"} title={"Online Billing, Invoicing, & Contracts"} 
                        description={"Simple and secure control of your organization’s financial and legal transactions. Send customized invoices and contracts"} />
                    <Card bgIcon={"bg-secondary"}  icon={"calendar"} title={"Online Billing, Invoicing, & Contracts"} 
                        description={"Simple and secure control of your organization’s financial and legal transactions. Send customized invoices and contracts"} />
                    <Card bgIcon={"bg-green-light"} icon={"user"} title={"Online Billing, Invoicing, & Contracts"} 
                        description={"Simple and secure control of your organization’s financial and legal transactions. Send customized invoices and contracts"} />
                </div>
                
            </div>

            {/* section : What is AySkill */}

            <div className='py-8 mx-auto max-w-screen-md'>
                <div className='flex flex-col items-center text-center p-4'>
                    <div className='py-2'>
                        <h1 className='text-xl'>What is AySkill</h1>
                    </div>
                    <div>
                        <p className='text-gray-700 text-sm'>
                            AySkill is a platform that allows educators to create online classes whereby they can store the course materials online; manage assignments, quizzes and exams; monitor due dates; grade results and provide students with feedback all in one place.
                        </p>
                    </div>

                    <div className='pt-6'>
                        <div className='grid md:grid-cols-2 gap-4 text-white'>
                            <div style={{backgroundImage:'url(./images/for_teachers.png)'}} className='bg-cover bg-no-repeat bg-center flex flex-col items-center justify-center rounded-md h-44 w-64'>
                                <div className='text-xl uppercase'>
                                    FOR INSTRUCTORS
                                </div>
                                <div className='py-2'>
                                    <Link to={"/login"}>
                                   
                                        <button className='border text-xs border-white  p-2 rounded-full '>
                                            Start a class Today 
                                        </button>
                                    </Link>
                                     
                                </div>
                            </div>
                            <div  style={{backgroundImage:'url(./images/for_students.png)'}} className='bg-cover bg-no-repeat bg-center flex flex-col items-center justify-center rounded-md h-44 w-64'>
                                <div className='text-xl uppercase'>
                                    For Students
                                </div>
                                <div className='py-2'>
                                    <Link to={"/login"}>
                                        <button className='border-green-600 border text-green-600 p-2 rounded-full text-xs '>
                                            Enter Access code
                                        </button>
                                    </Link>
                                     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* section 5 */}
            {/* <div className='py-8 mx-auto max-w-screen-lg'>
                <div className=''>
                    <div className='grid  md:grid-cols-2  gap-4 '>
                        <div className='p-4 flex flex-col justify-center'>
                            <h1 className='text-3xl leading-normal py-3'>
                                <span className='text-primary text-opacity-90'> Everything you can do in a physical classroom, you </span><span className='text-secondary'> can do with AySkill  </span>
                            </h1>
                            <p className='text-gray-600'>
                                AySkill’s school management software helps traditional and online schools manage scheduling, attendance, payments and virtual classrooms all in one secure cloud-based system.
                            </p>
                            <div className='pt-8'>
                                <button className='border-b'>
                                    Learn more
                                </button>
                            </div>
                           
                        </div>
                        <div className='p-8 relative'>
                            <div className='bg-primary bg-opacity-70 top-8 z-10 w-16 h-16 absolute rounded-md'>
                            </div>
                            <div className='p-4  '>
                                <img src={require('../assets/images/physical-classroom.png').default} alt="" />
                            </div>
                            <div className='bg-secondary bg-opacity-70 bottom-8 right-5 z-10 w-16 h-16 absolute rounded-md'>
                            </div>
                        </div>
                    </div>
                    
                </div>
               
            </div> */}
            {/* section 6 */}
            <div className='py-8 mx-auto max-w-screen-lg'>
                <div className='text-center'>
                    <h1 className='text-2xl py-2'>
                        <span className='text-primary '>Our</span> <span className='text-secondary'>Features</span>

                    </h1>
                    <h2>
                        This very extraordinary feature, can make learning activities more efficient
                    </h2>
                </div>
                <div className='md:flex items-center justify-between py-8'>
                    <div className='md:w-1/2'>
                        <img src={require('../assets/images/whiteboard.png').default} alt="" /> 
                    </div>
                    <div className='md:w-1/2  py-8 px-16'>
                        <h1 className='text-2xl '>
                            <span className='text-secondary'>White Board</span> Fonctionality
                        </h1>
                        <div className='pt-6'>
                            <p className='text-gray-700'>
                            You can write draw and explain fully what you want, you can upload a pdf and image and you can write and draw ontop of it.
                            </p>
                        </div>
                       
                    </div>
                </div>


                {/* test email */}
                <div className='text-sm rounded-xl pr-2'>

                </div>

                <div className='py-8 md:flex items-center justify-center'>

                    <div className='md:w-1/2 py-8 px-16'>
                        <h1 className='text-2xl'>
                            <span className='text-secondary'>Organize</span> your stuffs
                        </h1>
                        <div className='pt-6'>
                            <p className='text-gray-700'>
                                You can upload, modify and delete all your documents, announcements and events.
                            </p>
                        </div>
                       
                    </div>
                    
                    <div className='md:w-1/2 p-8'>
                        <img src={require('../assets/images/organize_stuff.png').default} alt="" /> 
                    </div>
                </div>
                
                <div className='py-8 flex items-center justify-center'>
                    <div className='md:w-1/2 md:block hidden p-8'>
                        <img src={require('../assets/images/polls.png').default} alt="" /> 
                    </div>
                    <div className='md:w-1/2  py-8 px-16'>
                        <h1 className='text-2xl '>
                            <span className='text-secondary'>Realtime</span> Poll
                        </h1>
                        <div className='pt-6'>
                            <p className='text-gray-700'>
                                You can pop up quesitons during class to all students and see all the answers, and you can uplaod their answers to be visible.
                            </p>
                        </div>
                       
                    </div>
                    
                    
                </div>
                <div className='py-8 md:flex items-center justify-center'>
                   
                    <div className='md:w-1/2  py-8 px-16'>
                        <h1 className='text-2xl '>
                            <span className='text-secondary'>Breakout</span> Rooms
                        </h1>
                        <div className='pt-6'>
                            <p className='text-gray-700'>
                                During class you can divide the students into groups of how many people you want and then once they finish they can join back your main class.
                            </p>
                        </div>
                       
                    </div>
                    <div className='md:w-1/2  p-8'>
                        <img src={require('../assets/images/breakout_rooms.png').default} alt="" /> 
                    </div>
                    
                    
                </div>
                <div className='text-center'>
                    <button className='p-3 border rounded-full text-secondary border-secondary'>
                        See more features
                    </button>
                </div>
          

            </div>
            
            {/* Reviews */}
            <div className='py-8 mx-auto max-w-screen-lg'>
                <div className='text-center'>
                    <h1 className='text-2xl py-2'>
                        What They Say ?
                    </h1>
                    <div className='py-8 max-w-md mx-auto'>
                        <div className='p-3 rounded-md shadow-md'>
                            <div>
                                <p>
                                    "Thank you so much for your help. It's exactly what I've been looking for. You won't regret it. It really saves me time and effort. Skilline is exactly what our business has been lacking."
                                </p>
                            </div>
                       
                            <div className='pt-3 flex justify-between'>
                                <div className='text-gray-400'>
                                    Gloria Rose
                                </div>
                                <div>
                                    <span className='text-secondary'>☆ ☆ ☆ ☆ </span>☆
                                </div>
                            </div>
                            
                        </div>

                        <div className='flex py-3 justify-between '>
                            <div>
                                <CsLineIcons icon='arrow-left' />
                            </div>
                            <div>
                                <CsLineIcons icon='arrow-right' />
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>

            {/* section 8 */}

            <div className='py-8 mx-auto max-w-screen-lg'>
                <div className='text-center pb-8'>
                    <h1 className='text-2xl py-2'>
                        <span className='text-primary '>Lastest News and Resources</span> 

                    </h1>
                    <h2 className='text-sm opacity-60'>
                        See the developments that have occurred to Ayskills in the world
                    </h2>
                </div>
                <div>
                    {/* articles  */}
                    <div className='grid md:grid-cols-2 gap-4'>
                        <Article orientation={"vertical"} src={require('../assets/images/article-1.png').default} title={"Class adds $30 million to its balance sheet for a Zoom-friendly edtech solution"} 
                            description={"Class, launched less than a year ago by Blackboard co-founder Michael Chasen, integrates exclusively..."} />
                        <div>
                            <Article src={require('../assets/images/article-2.png').default} 
                                title={"Class Technologies Inc. Closes $30 Million Series A Financing to Meet High Demand"} 
                                description={"Class Technologies Inc., the company that created Class,..."} />
                            <Article src={require('../assets/images/article-4.png').default} 
                                title={"Zoom was never created to be a consumer product. Nonetheless, the..."} 
                                description={"Class, launched less than a year ago by Blackboard co-founder Michael Chasen, integrates exclusively..."} />
                        </div>
                    </div>
                    
                </div>
            </div>

            <Footer />
           
        </>
    )
}

export default Welcome
