import { ActionsTypesNotification } from "../constants/notificationConstants";
import Axios from '../Axios'

export const listNotifications = () => async (dispatch) =>  {
    try {
        dispatch({type:ActionsTypesNotification.REQUEST_NOTIFICATION_COURSE})
        const {data} = await Axios.get('user-notifications')
        dispatch({type:ActionsTypesNotification.SUCCESS_NOTIFICATION_COURSE,payload:data.notifications})
    } catch (error) {
        dispatch({type:ActionsTypesNotification.FAIL_NOTIFICATION_COURSE,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        })
    }
   
}


export const deleteNotification = (id) => async (dispatch) =>  {
    try {
        dispatch({type:ActionsTypesNotification.REQUEST_DELETE_NOTIFICATION})
        const {data} = await Axios.delete(`notifications/${id}/`)
        dispatch({type:ActionsTypesNotification.SUCCESS_DELETE_NOTIFICATION,payload:data.notifications})
        dispatch(listNotifications())
    } catch (error) {
        dispatch({type:ActionsTypesNotification.FAIL_DELETE_NOTIFICATION,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        })
    }
   
}

