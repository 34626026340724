import React ,{ useEffect, useState} from 'react'
import { Link, Outlet ,useLocation, useNavigate, useParams} from 'react-router-dom'
import CsLineIcons from '../cs-line-icons/CsLineIcons'

import SideBar from './SideBar';
import Notifications from './Notifications'
import {useDispatch, useSelector} from 'react-redux'
import ImageProfile from './ImageProfile';
import DropDown from './DropDown';
import { logout } from '../redux/actions/authActions';
import WelcomeUser from './WelcomeUser';
import Language from './Language';
import { useTranslation } from 'react-i18next';
import { detailCourse } from '../redux/actions/courseActions';

function Layout() {
    const {id} = useParams() 
    // const { theme, setTheme } = useContext(ThemeContext);
    const {pathname} = useLocation()
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const {user} = useSelector(state => state.auth)
    
    const {course} = useSelector(state => state.course)

    
    const dispatch = useDispatch()
    const navigate = useNavigate()
 
    const toggling = () => setIsMenuOpen(!isOpen);
    const options =[
        {
            name:"Your Account",
            type:""
        },
        {
            name:"Your Account",
            type:"student"
        },
        {
            name:"Your Account",
            type:"teacher"
        },
        {
            name:"Billing Dashboard",
            type:"teacher"

        },
        {
            name:"logout",
            type:"teacher"

        },
        {
            name:"logout",
            type:"student"

        }
    ]

    const onClickDropDownItem = (op) => (ev) =>{
        if( op.name==="logout"){
            dispatch(logout())
        }
        else{
            if(op.name==="Your Account"){
                navigate("/account")
            }

        }
    }

    const [isOpen, setIsOpen] = useState(false)
    const [isSideBarLocked, setIsSideBarLocked] = useState(false)
    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width <= 768;
    const {t} = useTranslation()

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
      
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
       
    }, [width]);

    useEffect(() => {
        dispatch(detailCourse(id))
    }, [dispatch])
    
    

    return (
        <div className="min-h-screen ">
             
            <div className="flex  z-10 "  >
                <div className="fixed">
                    <SideBar isOpen={isOpen} isSideBarLocked={isSideBarLocked} setIsSideBarLocked={setIsSideBarLocked} setIsOpen={setIsOpen} />          
                </div>
                
                <div  className={`flex-grow min-h-screen overflow-y-scroll hide-scroll-bar ${isMobile ?"": isSideBarLocked  ?"ml-24":"ml-60" } `}>
                    
                    <div className={`flex  p-2 justify-between items-center  rounded-b-xl ${isMobile && !isOpen ? 'bg-primary ':''}   `}>
                            <div  className='md:flex hidden '>
                                <WelcomeUser />
                            </div>
                            
                            <div onClick={()=>setIsOpen(true) } className="flex items-center md:hidden px-4  ">
                            
                                <CsLineIcons icon="menu" size={32}/>
                                
                                <div>
                                    <img  src='/logo_white.png' className='ml-3 rounded-full w-20'/>
                                </div>
                            </div>  
                          

                    
                        <div className="flex items-center justify-center">
                            <Notifications />
                            <Language /> 
                           
                            <div className="relative flex items-center px-2 ">
                                <button onClick={toggling} className='relative'>
                                    <ImageProfile  />
                                    <DropDown onClickAction={onClickDropDownItem} type={user && user.type} options={options} setIsOpen={setIsMenuOpen} isOpen={isMenuOpen}  />
                                </button>
                            </div>  
                        </div>
                    
                    </div>
                    <div className="p-2 flex border-b text-xl capitalize  dark:border-gray-600  items-center">
                        <Link to={"/"}>{t('dashboard')}</Link>
                        <CsLineIcons icon='chevron-right' size={20} />    
                        <Link to={"/"+pathname.split('/')[1]+'/'+pathname.split('/')[2]}>
                        <div className="px-2" >
                                {/* {t(pathname.split('/')[1])} */}
                                {course?.title}
                            </div> 
                        </Link>
                    
                        <CsLineIcons icon='chevron-right' size={20} />
                        <Link to={pathname}>
                            <div className="px-2">
                                {t( pathname.split('/')[3] )}
                            </div>
                        </Link>

                    </div> 
                
                    <div className="max-width  mx-auto p-2">
                       <Outlet />  
                    </div>
                     
                </div>
            
            </div>
  
    </div>
        
    )
}

export default Layout
