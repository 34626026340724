// ANNONCE CONSTANTS
export const REQUEST_CREATE_EVENT ="REQUEST_CREATE_EVENT"
export const SUCCESS_CREATE_EVENT ="SUCCESS_CREATE_EVENT"
export const FAIL_CREATE_EVENT ="FAIL_CREATE_EVENT"

export const REQUEST_LIST_COURSE_EVENT ="REQUEST_LIST_COURSE_EVENT"
export const SUCCESS_LIST_COURSE_EVENT ="SUCCESS_LIST_COURSE_EVENT"
export const FAIL_LIST_COURSE_EVENT ="FAIL_LIST_COURSE_EVENT"

export const REQUEST_DETAIL_EVENT ="REQUEST_DETAIL_EVENT"
export const SUCCESS_DETAIL_EVENT ="SUCCESS_DETAIL_EVENT"
export const FAIL_DETAIL_EVENT ="FAIL_DETAIL_EVENT"

export const REQUEST_EDIT_EVENT ="REQUEST_EDIT_EVENT"
export const SUCCESS_EDIT_EVENT ="SUCCESS_EDIT_EVENT"
export const FAIL_EDIT_EVENT ="FAIL_EDIT_EVENT"

export const REQUEST_LIST_EVENT ="REQUEST_LIST_EVENT"
export const SUCCESS_LIST_EVENT ="SUCCESS_LIST_EVENT"
export const FAIL_LIST_EVENT ="FAIL_LIST_EVENT"

export const REQUEST_DELETE_EVENT ="REQUEST_DELETE_EVENT"
export const SUCCESS_DELETE_EVENT ="SUCCESS_DELETE_EVENT"
export const FAIL_DELETE_EVENT ="FAIL_DELETE_EVENT"
