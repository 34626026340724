import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from '../../hooks/useForm';
import { resetPassword } from '../../redux/actions/authActions';
import validate from '../../validation/ResetPasswordFormValidation';

function ResetPassword2() {
  const dispatch = useDispatch()
  
  const {loading,error}  = useSelector(state=> state.resetPassword)

  const handleResetPassword = ()=> {
      console.log(values)
      dispatch(resetPassword(values))
  }

  const  {
    handleChange,
    handleSubmit,
    errors,
    values,
  } = useForm(handleResetPassword,validate)


 
  return <>
          <div className="min-h-screen  flex items-center justify-center bg-primary p-4 md:p-16">
          <div className="p-3 w-96 ">
              

          <div  className=" bg-white p-4 rounded-md">
              <h1 className="text-xl  font-semibold capitalize">Reset password</h1>
              
            
              <div className='py-3'>
                Enter your account email 
              </div>
             
              <form onSubmit={handleSubmit}>
                 
                <div className={`border ${errors.email ? 'border-red-500':''} rounded-md bg-white`}>
                  <input name='email' value={values.email || ''} onChange={handleChange} 
                    type="text" className='p-2 outline-none w-full bg-transparent '  placeholder='Enter your email' />
                </div>
                <div className='text-red-600'>
                     {errors.email}
                  </div>
                <div className='py-2'>
                  <button  className={`p-2 bg-primary rounded-md px-8 text-white ${loading ? "opacity-40":""} `} disabled={loading}>
                    {loading ? 'loading...':'Send Email'}
                  </button>

                </div>
              </form> 
            </div>
          
          </div>
        </div>
     
    </>;
}

export default ResetPassword2;
