import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Announce from '../components/Announce'
import Modal from '../components/Modal'
import Role from '../components/Role'
import CsLineIcons from '../cs-line-icons/CsLineIcons'
import {  detailCourseByCat } from '../redux/actions/courseActions'
import  AnnounceSvg  from '../assets/svgs/announce.svg'
import Loading from '../components/Loading'
import Message from '../components/Message'
import { useTranslation } from 'react-i18next'
import AddEditAnnounce from '../components/AddEditAnnounce'
import Add from '../components/Add'

function CourseAnnonces() {

  
    const {id} = useParams()

    const [modalOpen, setModalOpen] = useState(false)

    const dispatch = useDispatch()

    const {data,loading,error} = useSelector(state => state.courseData)

    useEffect(() => {
       dispatch(detailCourseByCat(id,"annonces"))
    }, [dispatch,id])
    
  
    const {t}  =useTranslation()

    return (
        <>
            <div  className="flex-1 p-2 mx-auto max-width">
                <h1 className="text-2xl font-semibold">
                    Announces
                </h1>
                <div className="px-4 py-2 ">
                   
                    {
                        error?
                            <Message text={error} />:
                        loading ?
                            <Loading/>:
                        data?.annonces && data.annonces.length >0 ?
                            data.annonces.map(
                                announce => (
                                    <Announce key={announce.id} announce={announce} /> 
                                )
                            )
                        :
                        <div  className=''>
                            <div className='pt-20  flex flex-col justify-center items-center  mt-4 '>
                              <div>
                                <img src={AnnounceSvg} /> 
                              </div>
                              <div className='text-xl '>
                                 0 {t('announces')}
                              </div>
                            </div>
                           
                        </div>
                        
                    }
                
                </div>


            </div>
            <Add modalOpen={modalOpen} setModalOpen={setModalOpen} />


            <Modal title={"Add new announce"} modalOpen={modalOpen} setModalOpen={setModalOpen}  >
                <AddEditAnnounce isEditMode={false} setModalOpen={setModalOpen} /> 
               
            </Modal>

        </>
    )
}

export default CourseAnnonces
