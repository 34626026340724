import React, { useState } from 'react'
import Header from '../components/Header';
import Modal from '../components/Modal';
import CalendarComp from '../components/Calendar';
import Footer from '../components/Footer';
import Role from '../components/Role';
import StatsPlan from '../components/StatsPlan';
import { useTranslation } from 'react-i18next';
import Stats from '../components/Stats';
import EnrollToCourseComp from '../components/EnrollToCourse';
import ListCourses from '../components/ListCourses';
import AddEditCourse from '../components/AddEditCourse';


function Home() {

    const [isOpen, setIsOpen] = useState(false)
  
    const openModal = () => {
        setIsOpen(!isOpen)
    }
 
    const {t} = useTranslation()

    return (
        
        <div className="min-h-screen" >
            <Header background={'bg-primary text-white'} />
            <div  className="p-2  pt-16 max-w-screen-xl mx-auto ">
                
                <div className="py-4">
                    <h2 className='text-xl font-bold'>{t('dashboard')}</h2>

                </div>
                {/* welcome */}
                <div className="grid md:grid-cols-2 gap-3">

                        <div className="img w-full rounded-md">
                         
                        </div>
                        <CalendarComp  /> 
                </div>

                {/* Stats */}
                 <div className='py-4'>
                    <Role type={"teacher"}>
                        <div className='grid md:grid-cols-2 gap-3'>
                           
                            <StatsPlan />
                            <Stats />

                        </div>
                    </Role>
                </div> 
                <div className="py-8 ">
                    {/*   Courses  */}
                        <div className="flex items-center justify-between  py-3">
                            
                            <div className='font-bold text-xl text-center'>
                                {t('my courses')}
                            </div>

                            <Role type={"student"}> 
                                <div>
                                    <button onClick={openModal} className="p-2 bg-primary rounded-md text-white">
                                        {t('enroll to course')}
                                        
                                    </button> 
                                </div>
                            </Role>
                            <Role type={"teacher"}>
                                <div >
                                    <button onClick={openModal} className="p-2 bg-primary rounded-md text-white">
                                        
                                        {t('add new course')}
                                        
                                    </button>
                                </div>
                            </Role>
                        </div>
                        <ListCourses /> 
                       
                </div>

                    
                </div>

                <Modal  modalOpen={isOpen} setModalOpen={setIsOpen} >
                        <AddEditCourse isEditMode={false} title={"Add New Course"} setIsOpen={setIsOpen} />
                        <EnrollToCourseComp setIsOpen={setIsOpen} />
                </Modal>
               
                <Footer />
               
            </div>
            
    )
   
    
}

export default Home



