import React, { useRef,useEffect ,useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector,useDispatch } from 'react-redux'
import CsLineIcons from '../cs-line-icons/CsLineIcons'
import useOnClickOutside from '../hooks/useOnClickOutside'
import { deleteNotification, listNotifications } from '../redux/actions/notificationsActions'
import Loading from './Loading'
import Message from './Message'
import Axios from '../redux/Axios'


const NotificationItem = ({notification}) => {
    const dispatch =useDispatch()

    
    const handleDeleteNotification = (idNot) =>(e)=> {
        dispatch(deleteNotification(idNot))
        
    }

    const handleReadNotification = () => {
        console.log(notification.is_read)
        if (!notification.is_read){
            Axios.put(`notifications/${notification.id}/`,{title:notification.title ,message:notification.message,course:notification.course,is_read:true}).then(res=> {
                console.log(res.data)
            }).catch(error=> {
                console.log(error)
            })

        }
    }

    return (
        <div onMouseOver={handleReadNotification} className={` ${notification.is_read ? "bg-white":"bg-gray-300 bg-opacity-30"} text-sm   p-2 relative rounded-md my-1` }>
            <div className='pr-3'>
                {notification.message}
            </div>
            <div className='text-gray-600'>
                {notification.time}
            </div>
            <div className='absolute w-6 h-6 flex items-center justify-center bg-gray-100 rounded-full right-0 hover:text-red-600  top-6'>
                <button onClick={handleDeleteNotification(notification.id)}>
                    <CsLineIcons size={14} icon='trash'  />
                </button>
            </div>
            
        </div>
      
    )
}

function NotificationsList({error,loading,notifications,isOpen,setIsOpen}) {
    
    const ref = useRef()
    useOnClickOutside(ref,()=> setIsOpen(false))

    const {t} = useTranslation()



    return (
        <div >
            <div style={{maxHeight:"260px"}} ref={ref} className={`p-2 left-1/2 transform -translate-x-2/3 md:right-0  overflow-y-scroll  shadow-2xl z-10 w-80  drop-shadow-2xl absolute text-gray-800 dark:text-white bg-white dark:bg-gray-700  rounded-md ${isOpen ? "block":"hidden"}`}>
                {/* <div onClick={()=> setIsOpen(false)} className="absolute right-2 top-2">
                     <CsLineIcons icon="close" />
                </div> */}
                <div style={{maxWidth:"300px"}} className="p-2 ">
                   
                    {loading ? 
                        <Loading />:
                        error ? 
                        <Message text={error} /> :
                        notifications?.length >0 ?
                            notifications?.map(notification=><NotificationItem  key={notification.id} notification={notification}/>)
                            :
                        <div>
                            0 {t('notifications')}
                        </div>
                        }
            

                </div>
            </div>
        </div>
    )
}


function Notifications() {
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const dispatch = useDispatch()
    const {notifications,loading,error}  = useSelector(state=>state.notifications)

    useEffect(() => {
        dispatch(listNotifications())
    }, [dispatch])
    
    const handleCLickNotificationIcon = ()=>{
        setIsNotificationOpen(!isNotificationOpen)
        
    }
    
    return (
        <div className="relative px-2">
                {/* Notification icon */}
            <button className='flex' onClick={handleCLickNotificationIcon} >
                <CsLineIcons icon="bell" /> 
                {notifications?.filter(not=> not.is_read===false).length >0?
                <span className="inline-flex  absolute -right-1 -top-2 items-center justify-center w-5 h-5  text-xs font-bold leading-none text-red-100 bg-red-600  rounded-full">
                     {notifications?.filter(not=> not.is_read===false).length <9? notifications?.filter(not=> not.is_read===false).length: "+9"}</span>
                :''}
            </button>
            <NotificationsList loading={loading} error={error} notifications={notifications} isOpen={isNotificationOpen} setIsOpen={setIsNotificationOpen} />

        </div>
  );
}


export default Notifications
