import React from 'react';
import CsLineIcons from '../cs-line-icons/CsLineIcons';


const Select = ({name,label,value,onChange,placeholder,options}) => {
  return(
      <div className="py-1">
          <label htmlFor={name}> {label} </label>
          <div className='relative border'>
              <select className="p-2 w-full appearance-none outline-none bg-transparent"
                  name={name}
                  value={value}
                  onChange={onChange}
                  >
                  <option  disabled>{placeholder}</option>
                    {options.map((option,id) => {
                      return (
                        <option
                          key={id}
                          value={option}
                          label={option} >
                            {option}
                        </option>
                      );
                    })}
                
              </select>
              <div className='absolute z-30 top-2 right-1'>
                    <CsLineIcons icon='chevron-bottom' className='mx-2' />
                </div>
            
    
          </div>
       
    </div>)
}

export default Select;
