import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { disableAccount } from '../../redux/actions/authActions';

const rasions = [
    "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
]

function DeleteAccount() {

    const dispatch = useDispatch()
    const [raison, setRaison] = useState(null)
    const handleDisableAccount = () => {
        alert("clicked")
        if (raison)
            dispatch(disableAccount())
        
    }

    
    return <div>
        <h1 className='text-xl font-medium'>
            Delete your account
        </h1>
        <p className='py-3'>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat nobis aliquam distinctio est labore non recusandae rerum? Odio voluptates iure ea dolorum eos fugiat recusandae rem impedit, ipsum provident iusto.
        </p>

        <div className='py-3'>
            Please Help us to improve our plateform
        </div> 

        <div className='py-3'>
            why do you want to disable your account ?
        </div>
        <div>
            {rasions.map((raison,id)=> <div className='py-2'>
                    <input value={raison} onChange={(e)=>setRaison(e.target.value)} name='raison' type="radio" className='p-2 ' key={id} /> {raison}
                </div>
            
               
            )}
        </div>
        <div className='py-3'>
            <button onClick={handleDisableAccount} disabled={raison?false:true} className={`p-2 ${raison ? " text-white":"bg-opacity-25"} bg-red-600 rounded-md  `}>Delete your account </button>
        </div>
        
    </div>;
}

export default DeleteAccount;
