import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { updatePassword } from '../../redux/actions/authActions';

function ChangePassword() {

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const dispatch = useDispatch()
    
    const {loading,error,data} = useSelector(state => state.updatePassword)

    console.log(loading,error,data);
    const handleChangePassword = (e) => {
        e.preventDefault()

        // const form = {
        //     "oldPassword":oldPassword,
        //     "newPassword":newPassword,
        //     "newPassword2":confirmNewPassword
        // }
        const form = new FormData()
        form.append("oldPassword",oldPassword)
        form.append("newPassword",newPassword)
        form.append("newPassword2",confirmNewPassword)

        dispatch(updatePassword(form))
        
    }

    return <>
            <div >
                
               <h1 className='text-md font-bold '>
                    Change password
               </h1>
               <div >
                    {loading ? 
                        <div>
                            loading ...
                        </div>:
                        error ? 
                        <div>
                            <div className="p-2 bg-red-400 rounded-md">
                                {error}
                            </div>
                           
                        </div>
                        : 
                        ""
                    }
               </div>
               <div className='py-2'>
                   <form onSubmit={handleChangePassword} method="post">
                       <Input label={"Old Password"} type={"password"} placeholder={"****"} value={oldPassword} onChange={(e)=> setOldPassword(e.target.value)}/>
                       <Input label={"New Password"} type={"password"} placeholder={"****"} value={newPassword} onChange={(e)=> setNewPassword(e.target.value)}/>
                       <Input label={"Confirm new Password"} type={"password"} placeholder={"****"} value={confirmNewPassword} onChange={(e)=> setConfirmNewPassword(e.target.value)}/>
                       <div className='flex items-center justify-center'>
                        <button type='submit'  className={`p-2 bg-primary rounded-md px-8 text-white ${loading ? "opacity-40":""} `} disabled={loading}>
                            Change Password
                        </button>
                            
                       </div>
                       
                   </form>
               </div>
            </div>
        </>;
}

export default ChangePassword;
