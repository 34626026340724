import Axios  from "../redux/Axios";
import { useState } from "react";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import DropDown from "./DropDown";
import { useParams } from "react-router";
import { BASE_URL } from "../config/environnement";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { kickOfStudentCourse } from "../redux/actions/courseActions";

const Student = ({student}) => {
    const {id} = useParams()
    const [isOpen, setIsOpen] = useState(false)

    const options =[
        {
            name:"Kick Off",
            type:"teacher"
        }
    
    ]
    const dispatch = useDispatch()

    const onChooseAction = (op) => (ev)=>{
      
        if(op.name==="Kick Off"){
            //  Axios.post(`/teacher/course/${id}/${student.id}/kick_student`).then(res=>{
            //      console.log(res);
            //  }).catch(err=>{
            //      console.log(err);
            //  })
            dispatch(kickOfStudentCourse(id,student.id))
        }
    }

    return (
        <div className="rounded-md text-center bg-white dark:bg-gray-700 shadow-lg hover:shadow-xl dark:bg-opacity-60 p-3">
            <div className="w-full ">
                <div className="">
                    <div style={{backgroundImage:`url(${BASE_URL}${student.profil_pic})`}} className="bg-cover mx-auto border rounded-full  bg-center w-20 h-20">
                    </div>
                </div>
                <div className="pt-3">
                    <h1 className="text-md font-bold">
                        {student.firstname } { student.lastname}
                    </h1>
                    
               
                    <div className="text-gray-600 text-md">
                        <div>
                            <a href="mailto:email@email.com">
                                {student.email}
                            </a>  
                        </div>
                      
                       
                        <div className="text-xs pt-1">
                            Joined : 12-12-2022
                        </div>
                      
                    </div>
                    
                </div>
                <div className="flex pt-2  justify-end items-center">
                    <div className="relative">
                        <button className="  p-2" onClick={()=> setIsOpen(true)}>
                            <CsLineIcons icon="more-horizontal" />
                        </button>
                        <DropDown isOpen={isOpen} onClickAction={onChooseAction} type="teacher" setIsOpen={setIsOpen} options={options} />
                    </div>
                </div>

            </div>
        </div>
        
    )
}

export default Student;