import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { BASE_URL } from '../config/environnement'
import CsLineIcons from '../cs-line-icons/CsLineIcons'
import { deleteFile } from '../redux/actions/fileActions'
import DropDown from './DropDown'
import Role from './Role'

const downloadFile = (file,name) => {
    fetch(file)
        .then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = name;
                a.click();
            });
    });
}

function File({file}) {
    const [isOpen, setIsOpen] = useState(false)

    const options = [
        {
            name:"Delete",
            type:"teacher"
        },
        {
            name:"View",
            type:"teacher"
        },
        {
            name:"View",
            type:"student"
        },
        {
            name:"Download",
            type:"student"
        }
    ]
    const toggling = () => setIsOpen(!isOpen)
    
    const dispatch = useDispatch()

 

    const chooseEventAction = (op)=> (ev) => {
        
        if(op.name ==="Delete"){
            dispatch(deleteFile(file.id))

        }
        if(op.name ==="View"){
            window.open(`${file.file}`)
        }
        if(op.name ==="Download"){
            downloadFile(`${file.file}`,file.name)
        }

    }



    return (
        <div className="bg-white dark:bg-gray-700 dark:bg-opacity-60 my-2 w-full flex justify-between rounded-md shadow-md p-3">
            <div className="flex w-full">
                <div className="flex w-20" >
                   <CsLineIcons className='text-secondary' icon="file" size={60} />
                </div>
                
                <div className="p-3 w-full">
                    <h1 className="ppy-2 flex-grow w-full text-xl">{file.name}</h1>
                    <div className='py-1'>
                        {file.type_file} - {file.size_file}Mo
                    </div>
                    
                </div>
            </div>
            <Role type={'teacher'}>
                <div className="relative">
                    <button onClick={toggling} className=" p-2 rounded-full hover:bg-gray-100">
                        <CsLineIcons icon="more-horizontal" />
                    </button>  
                    <DropDown options={options} onClickAction={chooseEventAction} type={"teacher"} setIsOpen={setIsOpen} isOpen={isOpen}  />
                
                </div>
            </Role>
            <Role type={'student'}>
                <div className="relative">
                    <button onClick={toggling} className=" p-2 rounded-full hover:bg-gray-100">
                        <CsLineIcons icon="more-horizontal" />
                    </button>  
                    <DropDown options={options} onClickAction={chooseEventAction} type={"student"} setIsOpen={setIsOpen} isOpen={isOpen}  />
                
                </div>
            </Role>

        </div>
    )
}

export default File
