import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import CsLineIcons from '../cs-line-icons/CsLineIcons'
import { deleteAnnonce } from '../redux/actions/announceActions'
import AddEditAnnounce from './AddEditAnnounce'
import DropDown from './DropDown'
import Modal from './Modal'
import Role from './Role'

function Announce({announce}) {

    const [isOpen, setIsOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)

    const options = [
        {
            name:"Delete",
            type:"teacher"
        },
        {
            name:"Edit",
            type:"teacher"
        }
    ]
    const toggling = () => setIsOpen(!isOpen)
    
    const dispatch = useDispatch()

    const chooseEventAction = (op)=> (ev) => {
        
        if(op.name ==="Delete"){
            dispatch(deleteAnnonce(announce.id));
            
        }else if (op.name==="Edit"){

            setIsEditModalOpen(true)
        }

    }

    return (
       
        <div  className="text-gray-800 dark:text-white bg-white   dark:bg-gray-700 dark:bg-opacity-60 my-2 flex w-full justify-between rounded-md shadow-md p-3">
        <div className="flex w-full">
            <div className="w-20 p-2 flex-none ">
                <CsLineIcons icon="notification" className='text-secondary' size={55} />
            </div>
            <div className="flex-grow w-full">
                <h1 className="py-2 text-xl capitalize">{announce.title}</h1>
                <div className="pb-2 capitalize">
                    {announce.description}
                </div>
                <div className="text-sm text-gray-600 text-right">
                    Created At : {announce.createdAt}
                </div>
            </div>
          
        </div>
        <Role type={'teacher'}>
            <div className="relative">
                <button onClick={toggling} className={` ${isOpen?"bg-primary bg-opacity-40":""} hover:bg-primary hover:bg-opacity-40 p-2 rounded-xl `}>
                    <CsLineIcons icon="more-horizontal" />
                </button>  
                <DropDown options={options} onClickAction={chooseEventAction} type={"teacher"} setIsOpen={setIsOpen} isOpen={isOpen}  />
            </div> 
        </Role>
        <Modal title={"Edit Announce"} modalOpen={isEditModalOpen} setModalOpen={setIsEditModalOpen} >
            <AddEditAnnounce setModalOpen={setIsEditModalOpen} isEditMode={true} announce={announce} />
            {/* <form onSubmit={handleSubmit} >
                    <div className={`${errors.title ? "border-red-600":""} border rounded-md my-2`}>
                        <input name='title' value={values.title || ''} onChange={handleChange} className="p-2 w-full bg-transparent outline-none" type="text" placeholder="Annonce Title " />
                    </div>
                    {errors.title && (
                        <p className="help text-red-500">{errors.title}</p>
                    )}
                    
                    <div className={`${errors.description ? "border-red-600":""} border rounded-md my-2`}>
                        <textarea name='description' value={values.description || ""}  onChange={handleChange} className="p-2 w-full bg-transparent outline-none" type="text" placeholder="Annonce Description " />
                    </div> 
                    {errors.description && (
                        <p className="help text-red-500">{errors.description}</p>
                    )}
                    
                    <div className=" my-2 text-center">
                        <button type='submit' className="p-2 text-center rounded-md bg-primary text-white" >
                            Edit Annonce
                        </button>
                    </div>

                </form> */}
               
       
            
          
            
        </Modal>

    </div>
    )
}

export default Announce
