import React, { useEffect, useState } from 'react'
import DropDown from './DropDown'
import { useSelector } from 'react-redux'
import { deleteEvent } from '../redux/actions/eventActions'
import { useDispatch } from 'react-redux'
import { createMeeting,xmlToJson,joinMeeting,isMeetingRunning } from '../utils/meet'
import CsLineIcons from '../cs-line-icons/CsLineIcons'
import Modal from './Modal'
import Role from './Role'
import AddEditEvent from './AddEditEvent'

function Event({courseEvent}) {
    
    const [isOpen, setIsOpen] = useState(false)

    const [isOnline, setIsOnline] = useState(false)
    const [openEditEvent, setOpenEditEvent] = useState(false)

    const {user } = useSelector(state => state.auth)
    const [refreshMeet, setRefreshMeet] = useState(Math.random())
   
    const options = [
        {
            name:"Edit",
            type:"teacher"
        },
        {
            name:"Delete",
            type:"teacher"
        }
    ]
    const toggling = () => setIsOpen(!isOpen)
    const [isMeetCreated, setIsMeetCreated] = useState(false)
   
    const onCreateMeeting = (eventInfo) => async (event) => {
      
        const isRunning = await isMeetingRunning(eventInfo.meet_id)
        const response = xmlToJson(isRunning)
     
        if(response.returncode ==="SUCCESS" && response.running ===false) {
            console.log("prepare creation")
            const meetCreationRe =await createMeeting(eventInfo.meet_id,eventInfo.title,"student","teacher","10","http://localhost:3000","true")
            const response  = xmlToJson(meetCreationRe)
            console.log(response)
            if (response.returncode ==="SUCCESS" ){
                setIsMeetCreated(true)
            }else {
                
            }
        }else if (response.returncode ==="SUCCESS" && response.running ===true){
            setIsOnline(true)
            
        } 
        
       
    }

    const JoinMeet = (meetId)=> async (event)=>{
        let _url =''
        user.type ==="teacher"?
            _url =  joinMeeting(user && user.user && user.user.username,meetId,"teacher",user?.user?.id)
        :user.type ==="student"?
            _url =  joinMeeting(user && user.user && user.user.username,meetId,"student",user?.user?.id):
            _url =''
        if (_url !=='')
            window.open(_url, '_blank')
        
    }
    
    const dispatch = useDispatch()
    
    const chooseEventAction = (op)=> (ev) => {
        if(op.name ==="Edit"){
            setOpenEditEvent(true)
        }
        if(op.name ==="Delete"){
            // delete event 
            dispatch(deleteEvent(courseEvent.id))

        }
    }
    useEffect(() => {
        
        const init =async ()=>{
            const isRunning = await isMeetingRunning(courseEvent.meet_id)
            const response = xmlToJson(isRunning)
            console.log(response);
            if (response.returncode ==="SUCCESS" && response.running ===true){
                setIsOnline(true)
                 console.log("is running ",isOnline);

                
            }else{
                setIsOnline(false)
                console.log("is running ",isOnline);

            }
        }
        init();
        const timeoutRefreshMeet  = setTimeout(() => {
            setRefreshMeet(Math.random())
        },1000);

        return () => {
            clearTimeout(timeoutRefreshMeet);
        }

    }, [refreshMeet])

    return (
            <div  className="bg-white dark:bg-gray-700 dark:bg-opacity-60 my-2 flex w-full justify-between rounded-md shadow-md p-3">
                <div className="flex w-full">
                    <div className="w-24 text-center flex-none ">
                        {isOnline ? 
                        <div className='text-secondary p-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
                            </svg>
                        </div>
                        : 
                        <div className='text-secondary p-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414" />
                            </svg>
                        </div>
                        }
                    </div>
                    <div className="flex-grow w-full">
                        <h1 className="py-2 text-xl capitalize">{courseEvent.title} </h1>
                        <div className="pb-2 capitalize">
                            {courseEvent.subtitle}
                        </div>
                        <div className="text-md text-gray-600">
                            {courseEvent.date_event} | {courseEvent.heure_event?.substring(0, 5)}
                        </div>
                        <div className="pt-2 flex justify-end">
                           <Role type={"teacher"}>
                               
                                { !isOnline && !isMeetCreated ?
                                    <div className="font-bold">
                                        <button className="flex bg-secondary p-2 rounded-md text-black flex-end" onClick={onCreateMeeting(courseEvent)}>
                                            START MEET
                                        </button>
                                    </div>:
                                     <div className="font-bold uppercase">
                                        <button className="flex  bg-green-light p-2 rounded-md text-white flex-end" onClick={JoinMeet(courseEvent.meet_id)}>
                                            JOIN MEET
                                        </button>
                                    </div>
        
                                }
                           </Role>

                           <Role type={"student"}>
                               { isOnline ? 
                                 <button  className="flex text-white bg-green-light p-2 rounded-md  flex-end"  onClick={JoinMeet(courseEvent.meet_id)}>
                                    <div className="font-bold uppercase">
                                         join meet 
                                     </div>
                                 </button>
                                 :""}

                           </Role>
                         
                          
                        </div>
                    </div>
                    
                </div>
                <Role type={"teacher"}>
                    <div className="relative">
                        <button id={courseEvent.id} onClick={toggling}  className="p-2 rounded-full hover:bg-gray-100">
                            <CsLineIcons icon="more-horizontal" />
                        </button>  
                        <DropDown options={options} onClickAction={chooseEventAction} type={"teacher"} setIsOpen={setIsOpen} isOpen={isOpen}  />
                        
                    </div>
                </Role>
                <Modal title={"Edit Event"} modalOpen={openEditEvent} setModalOpen={setOpenEditEvent} >
                    
                    <AddEditEvent isEditMode={true} courseEvent={courseEvent} setModalOpen={setOpenEditEvent} />


                    {/* <div>
                        <h1 className="font-bold py-2">EDIT EVENT </h1>
                    </div>
                    <div className="p-2">
                        <div className="border rounded-md my-2">
                            <input value={eventData.title} onChange={(e) =>setEventData({...eventData,title:e.target.value})} className="p-2 w-full bg-transparent outline-none" type="text" placeholder="Event Title " />
                        </div>
                        <div className="border rounded-md my-2">
                            <input value={eventData.subtitle} onChange={(e) =>setEventData({...eventData,subtitle:e.target.value})} className="p-2 w-full bg-transparent outline-none" type="text" placeholder="Event Title " />
                        </div>
                        
                        <div className="border flex items-center rounded-md my-2">
                           
                            <input value={eventData.date_event} onChange={(e) =>setEventData({...eventData,date_event:e.target.value})} className="p-2 w-full bg-transparent outline-none" type="date" placeholder="Event Title " />
                            {isBrowserSafari ? 
                                <div className=' px-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>
                                </div>:
                                ""
                            }
                        </div>
                        <div className="border flex items-center rounded-md my-2">
                            <input value={eventData.heure_event} onChange={(e) =>setEventData({...eventData,heure_event:e.target.value})} className="p-2 w-full bg-transparent outline-none" type="time" placeholder="Event Title " />
                            {isBrowserSafari || isDeviceMobile ? 
                                <div className=' px-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>:
                             

                                ""
                            }
                        </div> 

                    </div>
                    <div className="my-2">
                        <button className="p-2 rounded-md bg-primary text-white" onClick={editFormEvent}>Edit </button>
                    </div> */}

                    
                </Modal>
            </div>
    )
}

export default Event
