import React from 'react';

function Input({label,type,value,placeholder,onChange}) {
  return <>
        {label !=="" ? 
            <label >
                {label}
            </label>:
            ''
            }
            <div className="border rounded-md my-2">
                <input type={type} value={value} 
                    onChange={onChange} className="p-2 w-full bg-transparent outline-none"  placeholder={placeholder} />
            </div>
  </>;
}

Input.defaultProps = {
    type:"text"
}
export default Input;
