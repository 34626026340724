import React from 'react'
import { Link } from 'react-router-dom'

function VerifyEmail() {
    return (
        <div>
            <div>
                Please Verify your email and Login
            </div>
           
            <Link to={'/verify-email'} >Login</Link> 
        </div>
    )
}

export default VerifyEmail
