import React, { useRef } from 'react'
import CsLineIcons from '../cs-line-icons/CsLineIcons'
import useOnClickOutside from '../hooks/useOnClickOutside'



function Modal({modalOpen,width,title,setModalOpen,...props}) {
    const ref = useRef(null)
    
    useOnClickOutside(ref,()=> setModalOpen(false))

    return (
        
        <div  className={`h-full z-20 fixed left-0 w-full top-0 bg-opacity-20 bg-gray-500 dark:bg-gray-800 dark:bg-opacity-40 ${modalOpen? "block":"hidden"}`}>
            <div style={{minWidth:"320px"}} ref={ref} className={ `${width} absolute-center  text-gray-800 dark:text-white bg-white dark:bg-gray-700  shadow-md p-3 rounded-md`}>
                <div onClick={()=> setModalOpen(false)} className="absolute right-2 top-2">
                   <CsLineIcons icon="close" />
                </div>
                <div className='text-xl font-bold pt-4'>
                    {title}
                </div>
                <div>
                    {props.children}
                </div>
            </div>
        </div>
       
    )
}

export default Modal
