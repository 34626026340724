export const validate = (values) => {
    let errors = {}
    if (!values.title){
        errors["title"]="Annnounce title is required "
    }
    if (!values.description){
        errors["description"]="Description title is required "
    }

    return errors;

}