// ANNONCE CONSTANTS

export const ActionsTypesAuth = {
    REQUEST_LOGIN_USER :"REQUEST_LOGIN_USER",
    SUCCESS_LOGIN_USER :"SUCCESS_LOGIN_USER",
    FAIL_LOGIN_USER :"FAIL_LOGIN_USER",
    REQUEST_REGISTER_USER:"REQUEST_REGISTER_USER",
    SUCCESS_REGISTER_USER :"SUCCESS_REGISTER_USER",
    FAIL_REGISTER_USER :"FAIL_REGISTER_USER",
    REQUEST_GET_USER :"REQUEST_GET_USER",
    SUCCESS_GET_USER :"SUCCESS_GET_USER",
    FAIL_GET_USER :"FAIL_GET_USER",
    REQUEST_UPDATE_USER :"REQUEST_UPDATE_USER",
    SUCCESS_UPDATE_USER :"SUCCESS_UPDATE_USER",
    FAIL_UPDATE_USER :"FAIL_UPDATE_USER",
    REQUEST_UPDATE_PASSWORD :"REQUEST_UPDATE_PASSWORD",
    SUCCESS_UPDATE_PASSWORD :"SUCCESS_UPDATE_PASSWORD",
    FAIL_UPDATE_PASSWORD :"FAIL_UPDATE_PASSWORD",
    REQUEST_RESET_PASSWORD_CONFIRM :"REQUEST_RESET_PASSWORD_CONFIRM",
    REQUEST_RESET_PASSWORD :"REQUEST_RESET_PASSWORD",
    SUCCESS_RESET_PASSWORD :"SUCCESS_RESET_PASSWORD",
    FAIL_RESET_PASSWORD :"FAIL_RESET_PASSWORD",
    SUCCESS_RESET_PASSWORD_CONFIRM :"SUCCESS_RESET_PASSWORD_CONFIRM",
    FAIL_RESET_PASSWORD_CONFIRM :"FAIL_RESET_PASSWORD_CONFIRM",
    REQUEST_DISABLE_USER :"REQUEST_DISABLE_USER",
    SUCCESS_DISABLE_USER :"SUCCESS_DISABLE_USER",
    FAIL_DISABLE_USER :"FAIL_DISABLE_USER",
    LOGOUT:"LOGOUT",

    REQUEST_SERVER_CONNECTION:"REQUEST_SERVER_CONNECTION",
    FAIL_SERVER_CONNECTION:"FAIL_SERVER_CONNECTION",
    SUCCESS_SERVER_CONNECTION:"SUCCESS_SERVER_CONNECTION",


} 
