import React ,{ useEffect } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { BASE_URL } from '../config/environnement'
import { getUser } from "../redux/actions/authActions";

function ImageProfile() {

    const {user,error,loading} = useSelector(state => state.user)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getUser())
    }, [dispatch]);
    

    return (
        <>
            {error ? 
                "--"
                :loading ?
                "...":
                <div style={{backgroundImage:`url(${user?.avatar})`}} className='w-10 h-10 bg-secondary bg-opacity-60 border border-white  p-2 cursor-pointer rounded-full bg-center bg-cover'>
                    {/* <img width={50} height={50} className='rounded-full' src="https://d2az3zd39o5d63.cloudfront.net/linkedin-profile-picture-squinch.jpg" alt="" /> */}
                </div>
            }
          

        </>
    )
}

export default ImageProfile
