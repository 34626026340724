import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import Header from '../components/Header'
import Loading from '../components/Loading'
import Message from '../components/Message'
import { listPlans } from '../redux/actions/planActions'
import Axios from '../redux/Axios'

const Plan = ({plan}) =>{
    const navigate = useNavigate()
    
    const upgradePlan = (planId)=> (ev)=>{
        console.log(planId)
        localStorage.setItem("planId",planId)
        navigate("/payment");
        
        // plan/<str:id>/updateplan
    }

    return (
        <div key={plan.id} className="md:w-1/3 w-full p-2">
        <div className="bg-white p-2 rounded-md">
            <div className="font-semibold text-xl p-2">
                {plan.name}
            </div>
            <hr />
            <div className="py-4">
                <ul>
                    <li className='py-2'>
                        <span className='text-3xl font-bold '>{plan.price}</span> DH
                    </li>
                    <li className="p-1">
                        Unlimuted Hours 
                    </li>
                    <li className="p-1">
                        {plan.max_participants} STUDENTS
                    </li>
                    
                </ul>
            </div>
            <div className="py-2">
                {plan.default ? 
                <div className="border-2 border-primary text-center rounded-md w-full p-2">
                    SELECTED
                </div>
                : 
                <button onClick={upgradePlan(plan.id)} className="border-2 bg-primary text-white rounded-md w-full p-2">UPGRADE</button>
                }
                
            </div>
        </div>
    
    </div>
    )
}

function Pricing() {
    const dispatch = useDispatch()
    const listPlanState = useSelector(state => state.listPlan)

    const {data,loading,error} = listPlanState

    useEffect(() => {
        dispatch(listPlans())
    }, [dispatch])

    return (
        <div className="min-h-screen">
            <div className="h-16">
                <Header />
            </div>
            <div className="max-width mx-auto px-4 ">
                <div className="flex py-5 justify-evenly w-full">
                    <div className="bg-primary text-white cursor-pointer p-2 rounded-md">
                        PERSONNEL
                    </div>
                    <div className="bg-white cursor-pointer p-2 rounded-md">
                        ORGANISATION
                    </div>
                </div>
                <div className="max-width mx-auto ">

                    {loading ?
                        <Loading />:
                    error ?
                        <Message text={error} />:
                        <div className="flex w-full">
                            {data.results && data.results.map((plan,id) =><Plan key={id} plan={plan} />)}
                        </div>
                    }
                    
                    {/* <div className="flex w-full">
                        <div className="md:w-1/3 w-full p-2">
                            <div className="bg-white p-2 rounded-md">
                                <div className="font-semibold text-xl p-2">
                                    FREE PLAN
                                </div>
                                <hr />
                                <div className="py-4">
                                    <ul>
                                        <li className="p-1">
                                            1 HOUR
                                        </li>
                                        <li className="p-1">
                                            5 STUDENTS
                                        </li>
                                        
                                    </ul>
                                </div>
                                <div className="py-2">
                                    <button onClick={()=>alert("Kooora")} className="border-2 border-primary rounded-md  p-2">SELECTED</button>
                                </div>
                            </div>
                        
                        </div>
                        <div className="md:w-1/3 w-full p-2">
                            <div className="bg-white p-2 rounded-md">
                                <div className="font-semibold text-xl p-2">
                                    STARTED PLAN
                                </div>
                                <hr />
                                <div className="py-4">
                                    <ul >
                                        <li className="p-1">
                                            20 STUDENTS 
                                        </li>
                                        <li className="p-1">
                                            10 HOURS
                                        </li>
                                    </ul>
                                </div>
                                <div className="py-2">
                                    <button onClick={()=>alert("Kooora")} className="bg-primary rounded-md text-white p-2">CHOOSE</button>
                                </div>
                            </div>

                        </div>
                        <div className="md:w-1/3 w-full p-2">
                            <div className="bg-white p-2 rounded-md">
                                <div className="font-semibold text-xl p-2">
                                    PAY AS YOU GO
                                </div>
                                <hr />
                                <div className="py-4">
                                    <ul >
                                        <li className="p-1">
                                            UNLIMETED STUDENTS 
                                        </li>
                                        <li className="p-1">
                                            UNLIMETED HOURS
                                        </li>
                                        <li className="p-1">
                                            RECORINGS OPTIONS
                                        </li>
                                        <li className="p-1">
                                            UP TO 50 GO
                                        </li>
                                    </ul>
                                </div>
                                <div className="py-2">
                                    <button onClick={()=>alert("Kooora")} className="bg-primary rounded-md text-white p-2">
                                        CHOOSE
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div> */}
                    
                </div>

                <div className="p-2 mt-5">
                   
                    <div className="bg-white rounded-md">
                        <h1 className="text-xl p-2">FEATURES </h1>
                        <hr />
                        <div className="p-2">
                            <h1 className="text-md font-semibold">FREE PLAN</h1>
                            <ul className="p-2">
                                <li>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                </li>
                            </ul>
                            
                        </div>

                        <div className="p-2">
                            <h1 className="text-md font-semibold">STARTED PLAN</h1>
                            <ul className="p-2">
                                <li>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                </li>
                                <li>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                </li>
                            </ul>
                            
                        </div>

                        <div className="p-2">
                            <h1 className="text-md font-semibold">PAY AS YOU GO</h1>
                            <ul className="p-2">
                                <li>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                </li>
                                <li>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                </li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
            
            </div>
          

        </div>
    )
}

export default Pricing
