export const validate = (values) => {
    let errors = {}
    if (!values.firstname){
        errors["firstname"]="First name is required "
    }
    if (!values.lastname){
        errors["lastname"]="last name is required "
    }

    if (!values.lastname){
        errors["lastname"]="last name is required "
    }

    return errors;

}