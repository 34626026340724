import React from 'react'

function Message({text}) {
    return (
        <div>
            {text} 
        </div>
    )
}

export default Message
