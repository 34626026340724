import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CsLineIcons from '../cs-line-icons/CsLineIcons';
import useForm from '../hooks/useForm';
import { createCourse, editCourse } from '../redux/actions/courseActions';
import toFormData from '../utils/toFormData';
import validate from '../validation/courseFormValidationRules';
import Role from './Role';

function AddEditCourse({setIsOpen,title,isEditMode,course}) {

    const {user} = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const handleAddEditCourse = (e) => {
        values.teacher= user?.user?.id
        console.log(values)
        const data = toFormData(values)

        isEditMode ?
            dispatch(editCourse(data,course.id))
        :
            dispatch(createCourse(data))
        // setValues({})
        setIsOpen(false)
    }

    const { 
            errors,
            values,
            handleChange,
            setValues,
            handleImageChange,
            handleSubmit
        }   =  useForm(handleAddEditCourse,validate)

    
    useEffect(() => {
        if (isEditMode) {
            values.title =course.title;
            values.description =course.description;
           
        }
    }, [isEditMode])

    // if (courseDataForm.image !== course?.image)  {
    //     formCourseData.append('image',courseDataForm.image); 
       
    // }

    return <div>
            <Role type={'teacher'}>
                    
                <div className='text-xl font-bold pt-4'>
                    {title}
                </div>
                <div className='p-2'>
                    <form onSubmit={handleSubmit} >
                        <div className={` ${errors.title ? "border-red-600":""} border rounded-md my-2`}>
                            <input  value={values.title || ''} onChange={handleChange} type="text" className="p-2 w-full bg-transparent outline-none" placeholder="Title"  name="title" id="" />
                        </div>
                        <span style={{ color: "red" }}>{errors["title"]}</span>
                        <div className={` ${errors.description ? "border-red-600":""} border rounded-md my-2`}>
                            <textarea  value={values.description || ''} onChange={handleChange} type="text" className="p-2 w-full bg-transparent outline-none" placeholder="Description"  name="description" id="" />
                        </div>
                        <span style={{ color: "red" }}>{errors.description}</span>   
                        <div className={ ` border cursor-pointer  ${errors.description ? "border-red-600":""}  ${!values.image ?"border-dashed":"border-green-400" } rounded-md my-2"`}>
                            <label htmlFor="image">
                                <input id="image" name="image" type="file" onChange={handleImageChange} className="p-2 w-full bg-transparent outline-none" placeholder="Image"   />
                                {/* {!values.image ?
                                    <div className='p-2 flex items-center justify-between text-gray-400'>
                                        <div>
                                                choose file 
                                        </div>
                                        <div>
                                                <CsLineIcons icon='image' />
                                        </div>
                                    </div>:
                                    <div className='p-2 flex items-center justify-between text-green-400'>
                                        
                                        <div>
                                            {values.image?.name}
                                        </div>
                                        <div>
                                            <CsLineIcons icon='check' />
                                        </div>
                                    </div>
                                } */}
                                
                            </label>
                        </div>
                        <span style={{ color: "red" }}>{errors.image}</span>   
                        <div className='flex pt-2 items-center justify-center '>
                            <button className={`rounded-md px-4 py-2 bg-primary text-white `} type='submit'>Save </button>
                        </div>
                        
                    </form>

                </div>
            </Role>
        </div>;
}

export default AddEditCourse;
