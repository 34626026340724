import config from '../config/config'
import crypto from 'crypto'

const sha1 =  (data) => {
    return  crypto.createHash("sha1").update(data, "binary").digest("hex");
}

const encrypt = (method,paramObj ) => {
    let queryStr=""
   
    if(paramObj){
        Object.keys(paramObj).forEach((key)=>{
           
            if(queryStr === ""){
                queryStr = `?${key}=${paramObj[key]}`;
            }else{
                queryStr += `&${key}=${encodeURIComponent(paramObj[key])?.replace(/%20/g, '+').replace(/[!'()]/g, escape).replace(/\*/g, "%2A")}`
                // queryStr += `&${key}=${paramObj[key]?.replace(/ /g,"-")}`;
            }
        });
    }
    let url = config.serverUrl +  "api/" + method + queryStr;

    let encryptData =  url.split(config.app)[1].replace("?","")+config.secret;
 
    const checkum = sha1(encryptData)
    let newUrl;
    if(url.indexOf("?") > 0)
       newUrl= url + `&checksum=${checkum}`;
    else
        newUrl= url + `?checksum=${checkum}`;
    return newUrl
}


export default encrypt;