import React, { useEffect ,useRef,useState} from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import CsLineIcons from '../cs-line-icons/CsLineIcons'
import useOnClickOutside from '../hooks/useOnClickOutside'
import SideBarItem from './SideBarItem'


function SideBar({isOpen,setIsOpen,isMobile,isSideBarLocked,setIsSideBarLocked}) {
    const {user} = useSelector(state => state.auth)
    
    const sideBarRef = useRef()
   

    useOnClickOutside(sideBarRef,()=> isMobile && setIsOpen(false))

    const lockSideBar = () => {
        setIsSideBarLocked(!isSideBarLocked)
        localStorage.setItem("islocked",!isSideBarLocked)
        if(isOpen){
            setIsOpen(false)
        }
    }
   
    



    useEffect(() => {
        setIsSideBarLocked(JSON.parse(localStorage.getItem("islocked")))
    }, [])

    return (
        <div ref={sideBarRef} className={`text-white md:flex 
                        ${!isSideBarLocked ? "w-60 translate-x-full":"w-24 translate-x-0  "}
                        px-3 pb-5 rounded-tr-3xl rounded-br-3xl  
                         flex-col overflow-y-hidden z-20 justify-between  
                        bg-primary min-h-screen 
                        ${isOpen ? 'w-60 absolute md:relative flex':'hidden'}
                        `} >
            <div className="flex overflow-hidden pt-2 items-center px-4 ">
                <div className='px-2' onClick={lockSideBar}>
                    <CsLineIcons icon={isMobile ?"menu":isSideBarLocked?"menu-right":"menu-left"} size={32}/>
                </div>
                <img  src='/logo_white.png' className='ml-3 w-20'/>
            </div>

            <div >
                <Link to=""> 
                    <SideBarItem label="course detail" icon="online-class" />
                </Link>
        
                <Link to="events">
                    <SideBarItem label="events" icon="calendar" />
                </Link>
                
                <Link to="files">
                    <SideBarItem label="files" icon="file" />
                </Link>
            
                <Link to="announces">
                    <SideBarItem label="announces" icon="notification" />
                </Link>
                
                {user && user.type === "teacher" ? 
                <Link to="students">
                    <SideBarItem label="students" icon="user"/>
                </Link>
                    :
                    ""
                }
            </div>
            <div>
                
            </div>
          
          
            

            
        </div>
    )
}

export default SideBar
