import { toast } from "react-toastify"
import Axios from "../Axios"
import { ActionTypes } from "../constants/action-types"
import { ActionsTypesCourse } from "../constants/courseConstants"

export const listCourse = () => async (dispatch) => {
    
    dispatch({type:ActionTypes.REQUEST_LIST_COURSE,payload:[]})
        
    try {
        
        const {data} =await Axios.get('/courses')

        dispatch({type:ActionTypes.SUCCESS_LIST_COURSE,payload:data.data})
    } catch (error) {
        dispatch({type:ActionTypes.FAIL_LIST_COURSE,payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        })
    }
}

export const detailCourse = (id) => async (dispatch) => {
    try {
        dispatch({type:ActionTypes.REQUEST_DETAIL_COURSE,payload:[]})
        const {data} = await  Axios.get(`/course/${id}/`)
        dispatch({type:ActionTypes.SUCCESS_DETAIL_COURSE,payload:data})
    
    } catch (error) {
        dispatch({type:ActionTypes.FAIL_DETAIL_COURSE,payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        })
    }
}


export const detailCourseByCat = (id,category) => async (dispatch) => {
    
    dispatch({type:ActionTypes.REQUEST_DETAIL_CAT_COURSE,payload:[]})    
    try {
        const {data} =await  Axios.get(`/course/${id}/${category}`)
        dispatch({type:ActionTypes.SUCCESS_DETAIL_CAT_COURSE,payload:data.data})
        
    } catch (error) {
        dispatch({
            type:ActionTypes.FAIL_DETAIL_CAT_COURSE,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        })
    }
}

// Prof Add new Course
export const createCourse = (course) => async (dispatch) => {
  
    dispatch({type:ActionTypes.REQUEST_CREATE_COURSE,payload:[]})    
    try {
        // const config = {     
        //     headers: { 'content-type': 'multipart/form-data' }
        // }
        const { data } = await Axios.post(`/course/`,course)
        dispatch({type:ActionTypes.SUCCESS_CREATE_COURSE,payload:data})
        dispatch(listCourse())
        toast.success("Course has been Created");

    } catch (error) {
        dispatch({
            type:ActionTypes.FAIL_CREATE_COURSE,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
        toast.warn("Error in creating Course");

    }
    

}

// remove course by id

export const deleteCourse = (courseId) => async (dispatch,getState) => {
  
    dispatch({type:ActionTypes.REQUEST_DELETE_COURSE,payload:[]})    
    try {
        const { data } = await Axios.delete(`/course/${courseId}/delete`)
        dispatch({type:ActionTypes.SUCCESS_DELETE_COURSE,payload:data})
        dispatch(listCourse())
        toast.success("Course has been deleted");
     
    } catch (error) {
        dispatch({
            type:ActionTypes.FAIL_DELETE_COURSE,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
        toast.warning("Error in deleting your Course");

    }
    

}

// EDIT COURSE 
export const editCourse = (course,id) => async (dispatch) => {
  
    try {
        dispatch({type:ActionsTypesCourse.REQUEST_EDIT_COURSE,payload:[]})    
        const { data } = await Axios.put(`course/${id}/`,course)
        dispatch({type:ActionsTypesCourse.SUCCESS_EDIT_COURSE,payload:data})
        toast.success("Your Course has been edited")
        dispatch(listCourse())
    } catch (error) {
        dispatch({
            type:ActionsTypesCourse.FAIL_EDIT_COURSE,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
        toast.warn("Error in editing course")

    }
    

}

// enroll to the course

export const EnrollToCourse = (courseId) => async (dispatch) => {
  
    try {
        dispatch({type:ActionTypes.REQUEST_ENROLL_COURSE,payload:[]})    
        const { data } = await Axios.post(`/student/enroll`,{id_course:courseId})
        dispatch({type:ActionTypes.SUCCESS_ENROLL_COURSE,payload:data})
        dispatch(listCourse())
        toast.success("You are joinned New course");

    } catch (error) {
        dispatch({
            type:ActionTypes.FAIL_ENROLL_COURSE,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
        toast.warn(" Please try again ");

    }
    

}

// Student leave course 

export const leaveCourse = (courseId) => async (dispatch) => {
    try {
        dispatch({type:ActionsTypesCourse.REQUEST_LEAVE_COURSE,payload:[]})
        const {data} =  Axios.post(`/student/course/${courseId}/exit_enroll`);
        dispatch({type:ActionsTypesCourse.SUCCESS_LEAVE_COURSE,payload:data})
        await dispatch(listCourse())
        toast.success("You left This Course ")
        
    } catch (error) {
        dispatch({
            type:ActionsTypesCourse.FAIL_LEAVE_COURSE,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
        toast.warn("Error ! Please try again")

    }
}

export const kickOfStudentCourse = (courseId,studentId) => async (dispatch) => {
    try {
        
        // dispatch({type:ActionsTypesCourse.REQUEST_LEAVE_COURSE,payload:[]})
        const {data} =  Axios.post(`/teacher/course/${courseId}/${studentId}/kick_student`);
        // dispatch({type:ActionsTypesCourse.SUCCESS_LEAVE_COURSE,payload:data})
        toast.success("A student has been banned")
    } catch (error) {
        dispatch({
            type:ActionsTypesCourse.FAIL_LEAVE_COURSE,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
        toast.warn("Error ! Please try again")

    }
}

// stats 

export const statCourses = () => async (dispatch) => {
    try {
        dispatch({type:ActionsTypesCourse.REQUEST_STATS_COURSE})
        const {data} = await Axios.get('stats');
        dispatch({type:ActionsTypesCourse.SUCCESS_STATS_COURSE,payload:data.data})
    } catch (error) {
        dispatch({
            type:ActionsTypesCourse.FAIL_STATS_COURSE,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })

    }
}
