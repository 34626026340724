import isValidEmail from "../utils/isValidEmail";

export default function validate(values) {
    let errors = {};

    if (!values.first_name) {
        errors.first_name = 'Firstname is required';
      } 
    if (!values.last_name) {
        errors.last_name = 'Lastname is required';
    } 
    if (!values.username) {
      errors.username = 'Username is required';
    } 
    if (!values.password) {
      errors.password = 'Password is required';
    } 
    if (values.password !== values.password2) {
      errors.password = 'Passwords not equal';
    } 
    
    if (!values.email) {
        errors.email = 'Email is required';
      } 
    
    if (!isValidEmail(values.email)){
        errors.email = 'Please enter a valid email ';
      }
    
    return errors;
  };