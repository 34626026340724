import React, { useEffect, useState } from 'react'
import CsLineIcons from '../cs-line-icons/CsLineIcons'
import Axios from '../redux/Axios'
import {useNavigate } from "react-router"
import { PieChart } from 'react-minimal-pie-chart'

// function timeConvert(hours) {
//     // var num = n;
//     // var hours = (num / 60);
//     var rhours = Math.floor(hours);
//     var minutes = (hours - rhours) * 60;
//     var rminutes = Math.round(minutes);
//     return rhours >0 ?rhours + " H " + rminutes + " min" :rminutes + " min" ;
// }

function StatsPlan() {
    
    const [studentsIn, setStudentsIn] = useState(0)
    const [studentsLeft, setStudentsLeft] = useState(0)
    const [daysLeft, setDaysLeft] = useState(0)


    // get order plan infos 
    useEffect(() => {
        Axios.get('/teacher/orderplan').then(res=>{
            // setPlan(res.data.data.plan)
            // setHoursleft(res.data.data.hoursleft)
            setDaysLeft(res.data.data.detail.days_left)
            // console.log(res.data.data.detail.days_left)
            // console.log(res.data.data.detail.is_expired)
            // setHoursPassed(res.data.data.detail.hours_passed)
            setStudentsIn(res.data.data.detail.participants_in)
            setStudentsLeft(res.data.data.student_left)
        })
       
    }, [])
    const navigate = useNavigate()
    const UpgradePlan = () => {
        navigate("/pricing")
    }

    return (
            <div className='bg-white shadow-md  flex flex-col  items-center   py-4  px-3 rounded-md'>
                
                <div>
                    <h1 className='text-xl font-bold '> Students</h1>
                </div>
                <div className='flex justify-between items-center'>
                    <div className='md:p-3'>
                        <PieChart
                            style={{ height: '220px' }}
                            data={[{ value: studentsIn, color: 'rgba(88,171,92,0.96)' }]}
                            totalValue={(studentsLeft+studentsIn)}
                            lineWidth={30}
                            animate={true}
                            label={({ dataEntry }) => Math.floor(dataEntry.value/(studentsLeft+studentsIn)*100)+"%"}
                            labelStyle={{
                                fontSize: '18px',
                                fontFamily: 'sans-serif',
                                fill: '#E38627',
                            }}
                            background="#bfbfbf"
                            
                            labelPosition={0}
                            />
                    </div>
                    <div className='p-2 text-md'>
                        <div className='py-1'>
                            <div className='flex justify-between bg-green-light bg-opacity-30 py-2 px-4 rounded-md'>
                                <div className='pr-3' >
                                    Students Left
                                </div>
                                <div className='text-md font-bold'>
                                    {studentsLeft}
                                </div>
                            </div>
                        </div>

                        <div className='py-1'>
                            <div className='flex justify-between bg-red-700 bg-opacity-30 py-2 px-4 rounded-md'>
                                <div className='pr-3'>
                                    Students Enrolled
                                </div>
                                <div className='text-md font-bold'>
                                    {studentsIn}
                                </div>
                            </div>
                        </div>
                        <div className='py-1'>
                            <div className='flex justify-between bg-primary bg-opacity-30 py-2 px-4 rounded-md'>
                                <div className='pr-3'>
                                    Total Students
                                </div>
                                <div className='text-md font-bold'>
                                    {studentsLeft+studentsIn}
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    
                </div>
                <div className='flex py-3'>
                    <div className='p-2 flex items-center  rounded-md bg-secondary text-white'>
                        <div className='px-2'>
                                <CsLineIcons icon='wallet' />    
                        </div>
                        
                        <div>
                            Free plan (- {daysLeft} days)
                        </div>
                    </div>
                    <div className=' mx-3 '>
                        <button onClick={UpgradePlan} className='p-2 border flex items-center  border-primary text-primary rounded-md'>
                            <CsLineIcons size={20} className='bg-primary  mr-2 rounded-full text-white' icon='plus' />  
                            <div>
                                Add more students
                            </div>
                        </button>
                        
                    </div>
                </div>
            
            </div>
 
    )
}

export default StatsPlan
