import React ,{useEffect, useState} from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { createEvent, editEvent } from '../redux/actions/eventActions'
import useForm from '../hooks/useForm'
import { validate } from '../validation/EventFormValidation'
import { useParams } from 'react-router-dom'
import ReactDatePicker from 'react-datepicker'
import "../assets/scss/datepicker.scss";
import CsLineIcons from '../cs-line-icons/CsLineIcons'
import { addDays, subDays } from 'date-fns'

function AddEditEvent({setModalOpen,isEditMode,courseEvent}) {

    let { id } = useParams();

    const dispatch = useDispatch()
    const [startDate, setStartDate] = useState(new Date());
  
    const [hour, setHour] = useState(new Date().getHours());
    const [min, setMin] = useState(new Date().getMinutes());
    const hours = ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","00"]
    const mins = ["00","05","10","15","20","25","30","35","40","45","50","55"]


    const handleAddOrEditEvent = () =>{
        values.course = id
        values.date_event=new Date(startDate).toISOString().split('T')[0]
        values.heure_event= hour+':'+min+":00"        
        console.log(values)
        
        isEditMode ?
            dispatch(editEvent(values,courseEvent.id))
            :
            dispatch(createEvent(values))

        setModalOpen(false)

    }
    const {
        errors,
        values,
        handleChange,
        handleSubmit
    } = useForm(handleAddOrEditEvent,validate)

    const {loading,error} = useSelector(state => state.createEvent)

    useEffect(() => {
        if (isEditMode){
            values.title = courseEvent.title
            values.subtitle = courseEvent.subtitle
            values.date_event = courseEvent.date_event
        }
     
    }, [isEditMode])


    

  return (
        <div>
            <form onSubmit={handleSubmit} >
                <div className={`border ${errors.title  ?"border-red-600" :"" }  rounded-md my-2`}>
                    <input name='title' value={values.title || ''} onChange={handleChange} 
                        className="p-2 w-full bg-transparent outline-none" 
                        type="text" placeholder="Event Title " />
                </div>
                <span className="text-red-600">  {errors.title}</span>

                <div className={`border ${errors.subtitle  ?"border-red-600" :"" }  rounded-md my-2`}>
                    <input name='subtitle' value={values.subtitle || ''} onChange={handleChange} 
                        className="p-2 w-full bg-transparent outline-none" 
                        type="text" placeholder="Event subtitle " />
                </div>
                <span className="text-red-600">  {errors.subtitle}</span>

                {/* <div className={`border ${errors.date_event  ?"border-red-600" :"" }  rounded-md my-2`}>
                    <input  name="date_event" value={values.date_event || new Date()} onChange={handleChange} 
                        className="p-2 w-full bg-transparent outline-none" 
                        type="date"  placeholder="Event Date event " />
                    
                </div>
                <span className="text-red-600">  {errors.date_event}</span> */}
                <div className={`border ${errors.date_event  ?"border-red-600" :"" } flex items-center justify-between  rounded-md my-2`}>
                    <ReactDatePicker 
                        minDate={new Date()}
                    // excludeDateIntervals={[{start: subDays(new Date(), 5), end: addDays(new Date(), 5) }]} dateFormat="yyyy/MM/dd" 
                        className='p-2 w-full bg-transparent outline-none' selected={startDate} onChange={(date) => setStartDate(date)} />
                    <div className='px-3'>
                        <CsLineIcons icon='calendar' size={20} />
                    </div>
                </div>
                
                 <div className={`border flex items-center justify-between ${errors.heure_event  ?"border-red-600" :"" }  rounded-md my-2`}>
                    <div className='flex items-center'>
                        <select className='p-2 mx-1 outline-none appearance-none bg-transparent' value={hour} onChange={(e)=> setHour(e.target.value)}  name="hour" id="hour">
                            {hours.map((h,i) => <option key={i} value={h}>{h}</option> )}
                        </select>
                        <div className=''>
                            :
                        </div>
                        <select value={min} onChange={(e)=> setMin(e.target.value)} className='p-2 outline-none appearance-none bg-transparent' name="min" id="min">
                            {mins.map((m,i) => <option key={i} value={m}>{m}</option> )}

                        </select>
                    </div>
                    <div className='px-3'>
                        <CsLineIcons icon='clock'  size={20}/>
                    </div>
                        {/* <input  id='heure_event' name='heure_event' value={values.heure_event || ""} onChange={handleChange} 
                            className="p-2 w-full bg-transparent outline-none" 
                            type="time" placeholder="Event Date event "  />
                             */}
                </div> 


                <span className="text-red-600">  {errors.heure_event}</span>
                
                
                <div className=" my-2 text-center">
                    <button className={`p-2 text-center rounded-md bg-primary ${loading?"bg-opacity-60":""} text-white`} disabled={loading} >
                        Save Event
                    </button>
                </div>
            
            </form>
        </div>
  )
}

export default AddEditEvent
