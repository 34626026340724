import { FAIL_LIST_PLAN,REQUEST_UPGRADE_PLAN,SUCCESS_UPGRADE_PLAN,FAIL_UPGRADE_PLAN,REQUEST_LIST_PLAN, SUCCESS_LIST_PLAN } from "../constants/planConstants";
import Axios from '../Axios'

export const listPlans = () =>async(dispatch) => {
    try {
        dispatch({type:REQUEST_LIST_PLAN,payload:[]})
        const {data} = await Axios.get('/plan')
        console.log(data)
        dispatch({type:SUCCESS_LIST_PLAN,payload:data})

    } catch (error) {
        dispatch({type:FAIL_LIST_PLAN,
                payload:
                    error.response && 
                    error.response.data.message
                    ? error.response.data.message
                    : error.message
        })
    }
}

export const upgradePlan = (idplan) => async(dispatch)=>{
    try {
        dispatch({type:REQUEST_UPGRADE_PLAN,payload:[]})
        const {data} = await Axios.post(`/plan/${idplan}/updateplan`)
    
        dispatch({type:SUCCESS_UPGRADE_PLAN,payload:data})

    } catch (error) {
        dispatch({type:FAIL_UPGRADE_PLAN,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        })
    }
} 