import React, { useEffect, useState } from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { Link, useNavigate} from 'react-router-dom'
import { logout } from '../redux/actions/authActions';
import DropDown from './DropDown';
import ImageProfile from './ImageProfile';
import Language from './Language';
import Notifications from './Notifications';
import {ReactComponent as Logo} from '../assets/svgs/logo.svg';


  
function Header({background}) {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isOpenOptionsLogin, setIsOpenOptionsLogin] = useState(false)

    const {user} = useSelector(state => state.auth)


    const optionsLogin = [
        {
            name:"AS TEACHER",
            type:""
        },
        {
            name:"AS STUDENT",
            type:""
        },
    ]
    const options =[
        {
            name:"Your Account",
            type:""
        },
        {
            name:"Your Account",
            type:"student"
        },
        {
            name:"Your Account",
            type:"teacher"
        },
        {
            name:"Billing Dashboard",
            type:"teacher"

        },
        {
            name:"logout",
            type:"teacher"

        },
        {
            name:"logout",
            type:"student"

        }
    ]

    const onClickDropDownItem = (op) => (ev) =>{
        if( op.name==="logout"){
            dispatch(logout())
            // navigate("/login")
        }
        else{
            if(op.name==="Your Account"){
                navigate("/account")
            }

        } 
        if (op.name==="Billing Dashboard") {
            navigate("/billing-dashboard")
        }
    }

    const chooseLoginOption =  (op) => (ev)=>{
        if( op.name==="AS STUDENT"){
            navigate("/register/student")
        }
        else{
            if(op.name==="AS TEACHER"){
                navigate("/register/teacher")

            }

        }
        
    }

    const toggling = () => setIsOpen(!isOpen);
    const [isSticked, setIsSticked] = useState(false)

    const togglingLogin = () => setIsOpenOptionsLogin(!isOpenOptionsLogin);

    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });
    const isSticky = (e) => {
        const scrollTop = window.scrollY;
        
        scrollTop >= 250 ? setIsSticked(true) : setIsSticked(false);
    }
 
    return (
        <>
          <div className={`flex fixed z-30 items-center p-2 justify-between ${isSticked && !background ? "bg-white shadow-sm":""} ${background ? "bg-white":""}  w-full rounded-b-xl `} >
                <div className="flex  px-3 items-center ">
                  
                    {/* <div className="py-2 px-4 font-extrabold uppercase text-3xl">
                        Ayskill
                    </div> */}
                    <Link to={'/'}>
                        <Logo />
                    </Link>
                 
                </div>
                { user ?
                <div className="flex items-center">
                        <Notifications />
                        <Language /> 
                        <div className="relative flex items-center px-2 ">
                           
                            <button onClick={toggling} className='relative'>

                                <ImageProfile  />
                                <DropDown onClickAction={onClickDropDownItem} type={user && user.type} options={options} setIsOpen={setIsOpen} isOpen={isOpen}  />
                            </button>
                   
                         
                        </div>  
                       

                </div>
                :
                <div className='flex items-center justify-center'>
                    <div className='px-2'>
                        <Link to={'/login'}>
                            <button className=" p-2 bg-white shadow-md drop-shadow-md  w-24 rounded-full">
                                Login
                            </button>
                        </Link>
                       
                    </div>
                    <div className='px-2'>
                         <button onClick={togglingLogin} className="bg-secondary shadow-md drop-shadow-md w-24 rounded-full p-2 text-white">
                            Sign Up
                        </button>
                        <DropDown options={optionsLogin} onClickAction={chooseLoginOption}  isOpen={isOpenOptionsLogin} type="" setIsOpen={setIsOpenOptionsLogin} /> 
                    </div>
                    
                </div>
                   

                }
            
            </div>
        </>
        
    )
}

export default Header
