import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './i18n';
import { Provider } from 'react-redux';
import store from './redux/store';
import { ThemeProvider } from './ThemeContext';
import BaseApp from './BaseApp';

ReactDOM.render(
    <Provider store={store}>
      {/* <ThemeProvider value={"light"}> */}
        <BaseApp />
      {/* </ThemeProvider> */}
    </Provider>
   ,
  document.getElementById('root')
);
