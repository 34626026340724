import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from '../../components/Select'
import CsLineIcons from '../../cs-line-icons/CsLineIcons'
import useForm from '../../hooks/useForm'
import { getUser, updateUser } from '../../redux/actions/authActions'
import { countryList } from '../../utils/countries'
import toFormData from '../../utils/toFormData'
import { generateArrayOfYears } from '../../utils/years'
import { validate } from '../../validation/AccountFormValidationsRules'

const listMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]


function Account() {
    
    const dispatch = useDispatch()

    const [day, setDay] = useState("")
    const [month, setMonth] = useState("")
    const [year, setYear] = useState("")

    
    const {user,error} = useSelector(state => state.user)
    const {loading} = useSelector(state => state.updateUser)

    const [countries, setCountries] = useState([]);
 
    const handleUpdateProfile = () => {
        // setValues(values=> ({...values,birthday:day+'-'+month+'-'+year}))
        console.log(values)
       
        values.birthday = year+'-'+(parseInt(month)+1)+'-'+day
        console.log(values.birthday)
        const formData = toFormData(values)
        dispatch(updateUser(formData))
        // dispatch(getUser())
    }

    const {
        values,
        errors,
        setValues,
        handleChange,
        handleSubmit,
        handleImageChange,
        } = useForm(handleUpdateProfile, validate)


    useEffect(() => {
        user !==undefined && setValues(({
            firstname: user?.firstname,
            lastname:user?.lastname ,
            country:user?.country,
            city:user?.city,
            gender:user?.gender,
            // birthday:user?.birthday,
            avatar:user?.avatar
        }));

        setDay(new Date(user?.birthday).getDate())
        setMonth(new Date(user?.birthday).getMonth())
        setYear(new Date(user?.birthday).getFullYear())

    
    }, [user])

    useEffect(() => {
        setCountries(countryList)
    }, [])
    
    return (
        <form onSubmit={handleSubmit} className='form'>
            <div className='py-1'>
                  <label className='dark:text-gray-50 text-gray-600' htmlFor="firstname">First Name</label>
                  <div className='border my-1'>
                      <input type="text" name='firstname' value={values.firstname || ""} onChange={handleChange} id='firstname' className='p-2 outline-none bg-transparent w-full' placeholder='First Name' />
                  </div>
                  
            </div>
            <div className='py-1'>
                  <label className='dark:text-gray-50 text-gray-600' htmlFor="lastname">Last Name</label>
                  <div className='border my-1'>
                      <input type="text" name='lastname' value={values.lastname || "" } onChange={handleChange} id='lastname' className='p-2 outline-none bg-transparent w-full' placeholder='Last Name' />
                  </div>
            </div>      

            <div className='py-1'>
                  <Select name={"gender"} label={"Gender"} options={["Male","Female"]} onChange={handleChange} value={values.gender || "" } /> 
            </div>
            <div className='py-1'>
                  <label className='dark:text-gray-50 text-gray-600' htmlFor="city">City</label>
                  <div className='border my-1'>
                      <input type="text" name='city' value={values.city || ''} onChange={handleChange} id='city' className='p-2 outline-none bg-transparent w-full' placeholder='city' />
                  </div>
            </div>

            <div className='py-1'>
                  <Select name={'country'} label={"Country"}  options={countries} onChange={handleChange} value={values.country || ''} />  
            </div>
            <div className='py-1'>
                  <label className='dark:text-gray-50 text-gray-600' htmlFor="Birthday">Birthday</label>
                    
                    <div className='flex justify-evenly'>
                        <div className='flex items-center justify-center border rounded-md w-1/3 '>
                            <select value={day} onChange={(e)=>setDay(e.target.value)} className='p-2 w-full outline-none appearance-none bg-transparent' name="day" id="day">
                                {Array.apply(null, {length: 31}).map((item,id)=> <option value={id+1}>{id+1}</option> ) }
                    
                            </select>
                            <CsLineIcons icon='chevron-bottom' className='mx-2' />

                        </div>
                        <div className='flex items-center justify-center border mx-2 rounded-md w-1/3 '>
                            <select value={month} onChange={(e)=>setMonth(e.target.value)} className='p-2 w-full appearance-none outline-none bg-transparent' name="day" id="day">
                                {Array.apply(null, {length: 10}).map((m,id)=> <option key={id} value={id} >{listMonths[id]}</option>)}

                            </select>
                            <CsLineIcons icon='chevron-bottom' className='mx-2' />

                        </div>
                        <div className='flex items-center border rounded-md  w-1/3 '>
                            <select value={year} onChange={(e)=>setYear(e.target.value)} className='p-2 w-full outline-none appearance-none bg-transparent' name="day" id="day">
                                {
                                    generateArrayOfYears(10,90).map(year => <option key={year} value={year} >{year}</option>)
                                }
                            </select>
                            
                            <CsLineIcons icon='chevron-bottom' className='mx-2' />
                           
                        </div>
                       
                       
                        
                    </div>

                  {/* <div className="border flex items-center rounded-md my-1">
                      <input value={values.birthday || ''} onChange={handleChange} className="p-2 w-full bg-transparent outline-none" type="date" placeholder="Event Title " />
                      {isSafari() ? 
                          <div className=' p-2'>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                              </svg>
                          </div>:
                          ""
                      }
                  </div> */}
            </div>
            <div className='py-1'>
                  <label className='dark:text-gray-50 text-gray-600' htmlFor="avatar">Avatar</label>
                  <div className='border rounded-md  my-1'>
                        <label className="text-blue-600 cursor-pointer">
                            <input type="file"  name='avatar' id='avatar'  
                                className=' hidden' onChange={handleImageChange} 
                                    />
                                    <div className='p-2 flex items-center'>
                                        <CsLineIcons icon='upload' />
                                        <div className='px-3'>
                                            upload Your avatar
                                        </div>
                                    </div>
                                    
                        </label>
                          
                  </div>
            </div>
         
            <div className='py-3' >
                <button type='submit' className={`p-2 rounded-md bg-primary ${loading ? 'bg-opacity-60':''} text-white`} disable={loading}>{loading? 'loading...':'Submit'} </button>
            </div>
        </form>

    )
    }

export default Account
