import React from 'react'

function ProgressBar({bgColor,barColor,label,used,total,height}) {

    return (
        <div className={` p-2 `}>
            {/* label */}
            <div className="flex justify-between py-2">
                <div>
                    {label}
                </div>
                <div >
                    {used}/{total}
                </div>
            </div>
             <div className={`${bgColor} rounded-md`}>
                <div className={`rounded-md ${barColor}`} style={{width:`${used/total *100}%`,height:height}} >     
                </div>
             </div>
            
        </div>
    )
}

ProgressBar.defaultProps ={
    height:"10px",
    barColor:"blue",
    bgColor:"#f0f2f5",
}

export default ProgressBar

