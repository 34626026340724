import React from 'react'
import Header from '../components/Header'
import Axios from '../redux/Axios'

function Payment() {
   
    const planid = localStorage.getItem("planId")

    const upgradePlan = () => {
        Axios.put(`plan/${planid}/updateplan`).then(res=>{
            console.log(res.data);
            
        }).catch(err=>{
            console.log(err);
        })
    }
    return (
        <div className="min-h-screen">
            <div className="h-16">
                <Header />
            </div>
            <div className="max-width mx-auto p-4 ">
                
                <div className="p-2 bg-white rounded-md ">
                    <h1 className="text-xl py-3 font-bold">
                        Detail of your Order
                    </h1>
                    <div className="px-4">
                        <div >
                            Plan : {planid} 
                        </div>
                        <div >
                            Max Hours : 10 
                        </div>
                        <div >
                            Max Students : 30
                        </div>

                        <div className="pt-2 text-right font-bold">
                            Total price : 300 DH
                        </div>

                    </div>
                    
                    
                    <div className="py-2 text-right px-3">
                         <button onClick={upgradePlan} className="p-2 bg-primary text-white rounded-md">
                            Je passe au paiment    
                        </button> 
                    </div>
                   
                </div>

                
            </div>
        </div>
    )
}

export default Payment
