import { differenceInCalendarDays } from 'date-fns';
import React, { useState,useRef} from 'react'
import { useEffect } from 'react';
import Calendar from 'react-calendar';
import '../assets/css/Calendar.css';
import CsLineIcons from '../cs-line-icons/CsLineIcons';
import useOnClickOutside from '../hooks/useOnClickOutside';
import Modal from './Modal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { statCourses } from '../redux/actions/courseActions';

const isSameDay = a => b => {
    return differenceInCalendarDays(a, b) === 0;
  }


const TileContent = ({date,selectedDates})=>{
    
    const toChange = selectedDates.filter(isSameDay(date)).length > 0;
    return toChange ? <div className='w-2 h-2 mx-auto bg-red-400 rounded-full'></div> : <></>;
}

function CalendarComp() {

    const [value, onChange] = useState(new Date());
    const [isOpen, setIsOpen] = useState(false)
    const dispatch = useDispatch()
   

    const {data} =  useSelector(state=>state.statCourses)
    
    useEffect(() => {
        dispatch(statCourses())
    }, [dispatch])

    const [selectedDates, setSelectedDates] = useState([])
    const [contentEvents, setContentEvents] = useState([])
    const refEventsPopup = useRef(null)

    useOnClickOutside(refEventsPopup,()=> setIsOpen(false))
    

    
    const onClickDay = (d)=>{
        let _events = []
        data?.events.map(event=>{
           
            if (differenceInCalendarDays(new Date(d),new Date(event.date_event))===0){
                _events.push(event)
                setIsOpen(true)
            }
           setContentEvents(_events)
        })
        
    }

    useEffect(() => {
         data?.events?.map(event=> {
             setSelectedDates(selectedDates=>[...selectedDates,new Date(event.date_event)])
         })

    }, [data])
 
      
      const tileContent = ({ activeStartDate, date, view }) => {
        return view==="month"? <TileContent date={date} selectedDates={selectedDates} />:null;
      };

    return (
        <div className="relative bg-white p-2 shadow-sm  rounded-md ">
              <Calendar
                    defaultView="month"
                    showNavigation={false}
                    onClickDay={onClickDay}
                    onChange={onChange}
                    tileContent={tileContent}
                  
                />
                <Modal title={"Your Events"}  setModalOpen={setIsOpen} modalOpen={isOpen}>
                        {contentEvents.map(ev =>
                                    <div key={ev.id}>
                                        <div className='flex py-1 items-center' key={ev.id}>
                                            <div className='mr-2'>
                                                <CsLineIcons icon='calendar' size={20} /> 
                                            </div> 
                                            <div><b>{ev.date_event}  {ev.heure_event} </b> </div>
                                        
                                        </div>
                                        <div className='pl-8 text-sm text-gray-800'>
                                            {ev.title}
                                        </div>
                                    </div>
                                    
                        )}
                </Modal>
               
                
        </div>
    )
}

export default CalendarComp
