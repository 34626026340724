import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { login } from '../../redux/actions/authActions'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useForm from '../../hooks/useForm'
import validate from '../../validation/LoginFormValidationRules'
import Header from '../../components/Header'

function Login() {

    const loginUser = ()=>{
        dispatch(login(values))
    }

    const {
        handleChange,
        errors,
        values,
        handleSubmit
    } = useForm(loginUser,validate)

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const {loading,error,user}= useSelector(state => state.auth)

    useEffect(() => {
      if( user ){
          navigate("/")
      }
    }, [user,navigate])


    // const resendEmail = () =>{

    //     Axios.post('auth/resend-email-activation',{'username':values.username}).then(res=>{
    //         console.log(res.data)
    //     }).catch(err=>{
    //         console.log(err)
    //     })
    // }

    const {t} = useTranslation()

    return (
    //     <div >
             
    //         <div className="min-h-screen  flex items-center justify-center bg-primary p-4 md:p-16">
             
    //          <div className="p-3 w-96 ">
             
    //             <div  className=" bg-white p-4 rounded-md">
    //                 <h1 className="text-xl  font-semibold capitalize">{t('login')}</h1>
    //                 <form onSubmit={handleSubmit}>
    //                     <div className="py-2">
    //                         <label  htmlFor="email">{t('username')} {t('or')} {t('email')}</label>
    //                         <div className={`border ${errors.username ? "border-red-600":""} border-gray-300 rounded-md`}>
    //                             <input name='username' value={values.username || ''} onChange={handleChange} type="text" id="email" placeholder="your_name@gmail.com" className="p-2 outline-none  w-full bg-transparent " />
    //                         </div>
    //                         <span className="text-red-600">  {errors.username}</span>
                            
    //                     </div>
    //                     <div className="py-2" >
    //                         <label  htmlFor="password">{t('password')}</label>
    //                         <div className={`border ${errors.password ? "border-red-600":""} border-gray-300 rounded-md`}>
    //                             <input name='password' value={values.password || ''} onChange={handleChange} type="password" id="email" placeholder="******" className="p-2 outline-none  w-full bg-transparent " />
    //                         </div>
    //                         <span className="text-red-600">  {errors.password}</span>
                            

    //                     </div>
    //                     <div className="py-2 flex justify-end">
    //                         <Link to={'/reset-password'} className="text-red-700" >{t('forget your password ?')}</Link> 
    //                     </div>
    //                     <div className="py-2 flex justify-center">
    //                         <button type='submit'  className={`p-2 bg-primary rounded-md px-8 text-white ${loading ? "opacity-40":""} `} disabled={loading}>
    //                             {t('login')} 
    //                         </button>
    //                     </div>
    //                 </form>
   
    //                 <div className="py-2">
    //                     {t('if you don t have an account yet')}
    //                     <Link to="/register/teacher" className="text-blue-700" > {t("teacher")}  </Link> or
    //                     <Link to="/register/student" className="text-blue-700" > {t("student")} </Link> 
    //                 </div> 

    //             </div>

                
    //         </div>
    //     </div>
    // </div>

    <>
    <Header />
    <div style={{backgroundColor:"#FFF0CE"}} className="relative min-h-screen flex justify-center items-center  p-4 md:p-16">
        <div className="p-3 md:flex items-center justify-center">
            <div className='md:flex-wrap w-1/2 hidden md:block mx-auto p-8'>
                <h1 className='text-7xl font-bold text-secondary leading-normal'>
                   Ayskill
                </h1>
                
               <p className='pt-4'>
                    Ayskill helps you learn and <br /> share information with the people in an interactive way.
               </p>


            

            </div>
        
            <div  className="flex-none mx-auto max-w-md shadow-md bg-white  rounded-md p-8  ">
                    <div className='text-xl font-bold pb-2'>
                        Login
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="py-2">
                            {/* <label className='opacity-60 pl-3 ' htmlFor="email">{t('username')} {t('or')} {t('email')}</label> */}
                            <div className={`border mt-2 ${errors.username ? "border-red-600":""} border-gray-300 rounded-full`}>
                                <input name='username' value={values.username || ''} onChange={handleChange} type="username" id="email" placeholder="Email or username" className="p-3 pl-4 outline-none  w-full bg-transparent " />
                            </div>
                            <span className="text-red-600">  {errors.username}</span>
                            
                        </div>
                        <div className="py-2" >
                            {/* <label className='opacity-60 pl-3 '  htmlFor="password">{t('password')}</label> */}
                            <div className={`border mt-2 ${errors.password ? "border-red-600":""} border-gray-300 rounded-full`}>
                                <input name='password' value={values.password || ''} onChange={handleChange} type="password" id="email" placeholder="Password" className="p-3 pl-4 outline-none  w-full bg-transparent " />
                            </div>
                            <span className="text-red-600">  {errors.password}</span>
                            

                        </div>
                        <div className="py-2 flex justify-end">
                            <Link to={'/reset-password'} className="text-primary" >{t('forget your password ?')}</Link> 
                        </div>
                        <div className="py-2 flex justify-center">
                            <button type='submit'  className={`p-3 bg-primary rounded-full px-8 text-white ${loading ? "opacity-40":""} `} disabled={loading}>
                                {t('login')} 
                            </button>
                        </div>
                    </form>

                    <div className="py-2">
                         {t('if you don t have an account yet')}
                         <Link to="/register/teacher" className="text-blue-700" > {t("teacher")}  </Link> or
                         <Link to="/register/student" className="text-blue-700" > {t("student")} </Link> 
                     </div> 



            </div>

          
        </div>
        
       
    </div>
   
</>
    )
}

export default Login
