import React from 'react'
import { useTranslation } from 'react-i18next'
import CsLineIcons from '../cs-line-icons/CsLineIcons'

function SideBarItem({icon,label}) {
    const {t} = useTranslation()
    return (
            <div className="p-2 px-2 my-1 rounded-md hover:bg-opacity-20 hover:bg-gray-300 hover:text-secondary transition transform hover:translate-x-1 motion-reduce:transition-none motion-reduce:transform-none ">
                <div className="flex overflow-hidden items-center">
                    <div className="py-3 px-3 ">
                        <CsLineIcons icon={icon} size={32} className="" />
                    </div>
                    <div className='text-xl pl-4 capitalize text-white'>
                        {t(label)} 
                    </div>
                
                </div>
            </div>
  
    )
}

export default SideBarItem
