import { combineReducers } from "redux";
import { createAnnounceReducer, deleteAnnounceReducer, listCourseAnnounceReducer } from "./announcesReducer";
import { disableUserReducer, getUserReducer, loginReducer, registerReducer, resetPasswordConfirmReducer, resetPasswordReducer, statusServerReducer, updatePasswordReducer, updateUserReducer } from "./authReducers";
import { createCourseReducer, detailCourseByCatReducer, detailCourseReducer, enrollCourseReducer, listCourseReducer, statCoursesReducer } from "./courseReducers";
import { createEventReducer, deleteEventReducer, listCourseEventReducer, listEventReducer } from "./eventReducers";
import { createFileReducer, listCourseFilesReducer } from "./fileReducers";
import { listNotificationsReducer } from "./notificationsReducer";
import { listPlanReducer } from "./planReducers";


const reducers = combineReducers({
  status : statusServerReducer,

  auth:loginReducer,
  register:registerReducer,
  user:getUserReducer,
  updateUser:updateUserReducer,
  disableUser:disableUserReducer,
  updatePassword:updatePasswordReducer,
  resetPassword:resetPasswordReducer,
  resetPasswordConfirm:resetPasswordConfirmReducer,

  coursesList:listCourseReducer,
  courseData:detailCourseByCatReducer,
  statCourses:statCoursesReducer,
  
  course:detailCourseReducer,
  listEvents:listEventReducer,
  createCourse:createCourseReducer,
  enrollCourse:enrollCourseReducer,

  // events
  eventsCourse:listCourseEventReducer,
  createEvent:createEventReducer,
  deleteEvent:deleteEventReducer,

  // notifications
  notifications:listNotificationsReducer,

    
  listPlan:listPlanReducer,

  announcesCourse:listCourseAnnounceReducer,
  createAnnounce:createAnnounceReducer,
  deleteAnnounce:deleteAnnounceReducer,
  
  createFile:createFileReducer,
  filesCourse:listCourseFilesReducer,
  

});
export default reducers;