import { ActionTypes } from "../constants/action-types";
import { ActionsTypesCourse } from "../constants/courseConstants";

const initialState = {
    loading: false,
    data: {},
    error:""
  }
  
export const listCourseReducer = (state=initialState,{type,payload}) => {
    
    switch (type) {
        case  ActionTypes.REQUEST_LIST_COURSE:
            return {
                loading:true,
                data:{}
            };
        case  ActionTypes.SUCCESS_LIST_COURSE:
            return {
                loading:false,
                data:payload
            };
        
        case ActionTypes.FAIL_LIST_COURSE:
            return {
                loading:false,
                error:payload
            };
        default:
            return state;
    } 
}
// add course reducer
export const createCourseReducer = (state ={loading:false,error:"",course:{},success:false},{type,payload}) => {
    switch (type) {
        case ActionTypes.REQUEST_CREATE_COURSE:
            return {
                ...state,
                loading:true
            }
        case ActionTypes.SUCCESS_CREATE_COURSE:
            return {
                ...state,
                loading:false,
                course:payload,
                success:true
            }
        case ActionTypes.FAIL_CREATE_COURSE:
            return {
                loading:false,
                error:payload,
                course:{},
                success:false
            }
    
        default:
            return state;
    }
}

// Enroll to course reducer
export const enrollCourseReducer = (state ={loading:false,error:"",course:{},success:false},{type,payload}) => {
    switch (type) {
        case ActionTypes.REQUEST_ENROLL_COURSE:
            return {
                ...state,
                loading:true
            }
        case ActionTypes.SUCCESS_ENROLL_COURSE:
            return {
                ...state,
                loading:false,
                course:payload,
                success:true
            }
        case ActionTypes.FAIL_ENROLL_COURSE:
            return {
                loading:false,
                error:payload,
                course :{},
                success:false
            }
    
        default:
            return state;
    }
}
  
export const detailCourseReducer = (state={
        course :{},
        loading:false,
        error:""
    },{type,payload}) => {
    switch (type) {

        case  ActionTypes.REQUEST_DETAIL_COURSE:
            return {
                loading:true,
                course:{}
            };
        case  ActionTypes.SUCCESS_DETAIL_COURSE:
            return {
                ...state,
                loading:false,
                course:payload
            };
        
        default:
            return state;
    } 
}


export const detailCourseByCatReducer = (state={
    data :[],
    loading:false,
    error:""
},{type,payload}) => {
switch (type) {

    case  ActionTypes.REQUEST_DETAIL_CAT_COURSE:
        return {
            loading:true,
            data:[]
        };
    case  ActionTypes.SUCCESS_DETAIL_CAT_COURSE:
        return {
            loading:false,
            data:payload
        };
    case  ActionTypes.FAIL_DETAIL_CAT_COURSE:
        return {
            loading:false,
            error:payload
        };
    
    default:
        return state;
} 
}


export const statCoursesReducer = (state={
            data :[],
            loading:false,
            error:""
        },{type,payload}) => {
            switch (type) {
                case  ActionsTypesCourse.REQUEST_STATS_COURSE:
                    return {
                        loading:true,
                        data:[]
                    };
                case  ActionsTypesCourse.SUCCESS_STATS_COURSE:
                    return {
                        loading:false,
                        data:payload
                    };
                case  ActionsTypesCourse.FAIL_STATS_COURSE:
                    return {
                        loading:false,
                        error:payload
                    };
    
                default:
                    return state;
} 
}




