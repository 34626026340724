import userEvent from '@testing-library/user-event'
import React,{useEffect, useRef, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector,useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import CardState from '../components/CardState'
import Role from '../components/Role'
import { listAnnouncesByCourse } from '../redux/actions/announceActions'
import { detailCourse } from '../redux/actions/courseActions'
import { listEventsByCourse } from '../redux/actions/eventActions'
import { listFilesByCourse } from '../redux/actions/fileActions'

function CourseDetail() {
    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef(null);
    const {id} = useParams()
    const dispatch = useDispatch()

    const {course} = useSelector(state => state.course)
    const {user} = useSelector(state => state.auth)

    const {events,loading,error} = useSelector(state => state.eventsCourse)
    const {announces} = useSelector(state => state.announcesCourse)
    const {files} = useSelector(state => state.filesCourse)

   
    useEffect(() => {
       dispatch(detailCourse(id))
       dispatch(listEventsByCourse(id))
       dispatch(listAnnouncesByCourse(id))
       dispatch(listFilesByCourse(id))
    }, [dispatch,id])



  function copyToClipboard(e) {
        textAreaRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        setCopySuccess('Copied!');
        setTimeout(() => {
            setCopySuccess("")
        }, 1000);
    };

    const {t}= useTranslation()

    return (
        <>
            <div  className=" p-2 z-10">
                {/* <h1 className="text-xl  font-meduim py-2">Detail Course</h1> */}

                <div className=" w-full py-3 ">
                    <div className="img h-40 rounded-md">
                    
                    </div>
                </div>
                
                <div className={`md:grid-cols-2 grid  gap-4`}>
                    <div className="bg-white   p-3 shadow-md rounded-md">
                        <div className="text-xl py-2">
                            {course.title}
                        </div>
                        <div className='pb-1' >
                            {course.description}
                        </div>
                        <div>
                            {course.createdAt}
                        </div>
                    </div> 
                    <div className={`${user?.type=="teacher" ?'bg-white p-3 shadow-md rounded-md':'hidden'}`}>
                    
                        <div className="text-xl py-2">
                            {t('share this code with your students')}
                          
                        </div>
                    
                        <div className="flex justify-center items-center ">
                            
                            <form className="w-full bg-gray-100 rounded-md">
                                <textarea
                                    className="w-full px-3 py-1 outline-none bg-transparent border-none"
                                    rows={1}
                                    ref={textAreaRef}
                                    value={course.id}
                                    onChange={()=>{}}
                                />
                            </form>
                                
                            <div className="flex justify-between">
                                    
                                <div onClick={copyToClipboard} className="px-2">
                                    
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
                                    </svg>
                                    {copySuccess !=="" ? 
                                    <div className="absolute p-1 bg-gray-200 rounded-md">
                                        {copySuccess}
                                    </div>
                                    :""}
                                </div>
                               
                            </div>
                        
                        </div>
                    
                    </div>

                    <div className={`${user?.type=="student" ?'bg-white p-3 shadow-md rounded-md':'hidden'}`}>
                        <div>
                            <div className="text-xl py-2">
                                Created By 
                            </div>
                            <div>
                                <h2 className='text-xl font-bold py-2'>
                                    {course.created_by}
                                </h2>
                            </div>
                            
                        </div>
                    
                    </div>
                   
                </div> 
                
                <h1 className="text-xl font-bold py-4">{t('stats course')}</h1>   

                <div className={`grid  grid-cols-2 ${user?.type ==="teacher"?"md:grid-cols-4":"md:grid-cols-3"}   gap-4`}>
                    <CardState title={t('events')} link={`/course/${id}/events`} icon={'calendar'} number={events && events?.events?.length}  />
                    <CardState title={t('files')} link={`/course/${id}/files`} icon={'file'} number={files?.length}   />
                    <CardState title={t('announces')} link={`/course/${id}/announces`} icon={'notification'} number={announces?.length}   />
                    <Role type={"teacher"}>
                        <CardState title={t('students')} link={`/course/${id}/students`} icon={'user'} number={course.students?.length}  />
                    </Role>
                </div>       

            </div>
        </>
    )
}

export default CourseDetail
