import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import CsLineIcons from '../cs-line-icons/CsLineIcons';
import { getUser } from '../redux/actions/authActions';
import Header from './Header';
import SideBarProfile from './SideBarAccount';

function LayoutAccount() {
   
    const dispatch = useDispatch()
    
    const {user} = useSelector(state => state.user)
   
    useEffect(() => {
        dispatch(getUser())
    }, [dispatch])

    return (
        <div className="min-h-screen">
            <div className="h-16">
                <Header background={'bg-primary text-white'}  />
            </div>
           
            <div className='max-width mx-auto px-4 py-8'>
                <Link to={'/'}>
                    <div className='pb-4 flex items-center'>
                        <CsLineIcons icon='arrow-left'/>
                        <div className='px-3'> back home </div>
                    </div>
                </Link>
              
               <div className='grid md:grid-cols-3 gap-3'>
                   <div className='w-full'>
                       <SideBarProfile firstname={user?.firstname} lastname={user?.lastname} avatar={user?.avatar} /> 
                   </div>
                   <div className='md:col-span-2'>
                        <div className='p-3 bg-white rounded-md '>
                            <Outlet /> 
                        </div>
                   
                   </div>

               </div>
           </div>
        </div>
    )
}
export default LayoutAccount;
