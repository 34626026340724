import { toast } from "react-toastify"
import Axios from "../Axios"
import {ActionsTypesAuth} from "../constants/authConstants"

export const statusServer = () => async dispatch => {
    try {
        dispatch({type:ActionsTypesAuth.REQUEST_SERVER_CONNECTION})
        const {data} = await Axios.get("status");
        dispatch({type:ActionsTypesAuth.SUCCESS_SERVER_CONNECTION,payload:data.status})
    } catch (error) {
        dispatch({type:ActionsTypesAuth.FAIL_SERVER_CONNECTION,payload:error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail})
    }
}

export const login =  (user) => async (dispatch)=>{
    
    dispatch({ type: ActionsTypesAuth.REQUEST_LOGIN_USER,payload:[]})

    try {
        const {data} = await Axios.post('auth/login2/',user)
        localStorage.setItem('userInfo', JSON.stringify(data.data))
        dispatch({ type:ActionsTypesAuth.SUCCESS_LOGIN_USER,payload:data.data})
        dispatch(getUser())
    } catch (error) {
        const message = error.response && error.response.data.message? error.response.data.message: error.messag
        dispatch({ type:ActionsTypesAuth.FAIL_LOGIN_USER,payload:message})
        toast.error(message)
    }
}

export const register = (user)=>async (dispatch)=> {

    try {
        dispatch({ type:ActionsTypesAuth.REQUEST_REGISTER_USER,payload:[]})
        const {data} = await Axios.post('auth/register/',user)
        // console.log(data)
        dispatch({ type:ActionsTypesAuth.SUCCESS_REGISTER_USER,payload:data.data})
        toast.success("Your account has been created, please check your email to confirm.");
        // document.location.href = '/login'
    } catch (error) {
        dispatch({ 
            type:ActionsTypesAuth.FAIL_REGISTER_USER,payload:error.response && error.response.data
            ? error.response.data
            : error.message})
        toast.error("Your account has not been create, Please try again");
        
    }

    
}



export const logout =  () => async (dispatch)=>{
    
    try {
        Axios.post('auth/logout/')
        localStorage.removeItem("userInfo")
        dispatch({ type:ActionsTypesAuth.LOGOUT,payload:[]})
        document.location.href = '/login'
    } catch (error) {   
        console.log(error)
    }

    // localStorage.removeItem("type")
    
  
}

export const getUser =  () => async (dispatch)=>{    
   
    try {
        dispatch({ type:ActionsTypesAuth.REQUEST_GET_USER})
        const {data} = await Axios.get('user/profile')
        dispatch({ type:ActionsTypesAuth.SUCCESS_GET_USER,payload:data.data})
    } catch (error) {
        dispatch({ 
            type:ActionsTypesAuth.FAIL_GET_USER,payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message})
    }
}

export const updateUser = (user) => async (dispatch)=> {
    dispatch({ type:ActionsTypesAuth.REQUEST_UPDATE_USER,payload:{}})
    try {
        const config = {     
            headers: { 'content-type': 'multipart/form-data' }
        }
        const {data} = await Axios.post('user/updateprofile',user,config)
        
        dispatch({ type:ActionsTypesAuth.SUCCESS_UPDATE_USER,payload:data.data})

        dispatch(getUser())
        toast.success("account updated")

    } catch (error) {
        
        dispatch({ 
            type:ActionsTypesAuth.FAIL_UPDATE_USER,payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message})
        toast.warning("account not updated")
        
    }
}

export const updatePassword = (form)=> async (dispatch)=> {
    try {
        dispatch({type:ActionsTypesAuth.REQUEST_UPDATE_PASSWORD,payload:{}})
        const {data} =await Axios.post('/auth/changePassword/',form)
        dispatch({type:ActionsTypesAuth.SUCCESS_UPDATE_PASSWORD,payload:data})
        toast.success("password has been changed ")
        dispatch(logout())
    } catch (error) {
        dispatch({ 
            type:ActionsTypesAuth.FAIL_UPDATE_PASSWORD,payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message})
        
    }
} 

//  confirm pasword

export const resetPassword = (form) => async (dispatch) => {
   try {
        dispatch({type:ActionsTypesAuth.REQUEST_RESET_PASSWORD,payload:{}})
        const {data} = await Axios.post('auth/password_reset/',form);
        dispatch({type:ActionsTypesAuth.SUCCESS_RESET_PASSWORD,payload:data})
        
        toast.success("Click on the link you got on your email ")
        document.location.href='/login'
   } catch (error) {
        dispatch({ 
            type:ActionsTypesAuth.FAIL_RESET_PASSWORD,payload:error.response && error.response.data.email
            ? error.response.data.email[0]
            : error.message})
        console.log(error.response.data.email[0])
        toast.error(error.response && error.response.data.email
            ? error.response.data.email[0]
            : error.message)
   }
}


export const resetPasswordConfirm = (token,password) => async (dispatch) => {
    try {
         dispatch({type:ActionsTypesAuth.REQUEST_RESET_PASSWORD_CONFIRM,payload:{}})
         const {data} = await Axios.post('auth/password_reset/confirm/',{token,password})
         dispatch({type:ActionsTypesAuth.SUCCESS_RESET_PASSWORD_CONFIRM,payload:data})
         toast.success("Your password has been changed")
         dispatch(logout())
         document.location.href = '/login'
         
    } catch (error) {
         dispatch({ 
             type:ActionsTypesAuth.FAIL_RESET_PASSWORD_CONFIRM,payload:error.response && error.response.data.password
             ? error.response.data.password[0]
             : error.message})
         toast.error("Error")
    }
 }



export const disableAccount = ()=> async (dispatch)=> {
    try {
        dispatch({type:ActionsTypesAuth.REQUEST_DISABLE_USER,payload:{}})
        const {data} =await Axios.put('/user/deactivate')
        
        dispatch({type:ActionsTypesAuth.SUCCESS_DISABLE_USER,payload:data})
        dispatch(logout())
        toast.success("Account disabled ")
    } catch (error) {
        dispatch({ 
            type:ActionsTypesAuth.FAIL_DISABLE_USER,payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message})
        toast.error("Error is disabling your account")
        
    }
} 
